const AUTH_TOKEN_KEY = "auth"
const SESSION_ID_KEY = "sessionId"

export function getSessionId() {
  return localStorage.getItem(SESSION_ID_KEY) ?? ""
}

export function setSessionId(sessionId: string) {
  localStorage.setItem(SESSION_ID_KEY, sessionId)
}

export function removeSessionId() {
  localStorage.removeItem(SESSION_ID_KEY)
}

export function getBearerToken() {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  return token ? `Bearer ${token}` : ""
}

export function hasBearerToken() {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  return token !== null
}

export function setBearerToken(token: string) {
  localStorage.setItem(AUTH_TOKEN_KEY, token)
  window.dispatchEvent(new Event("storage"))
}

export function removeBearerToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY)
  window.dispatchEvent(new Event("storage"))
}
