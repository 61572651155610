import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { Link } from "react-router-dom"

export type DrawerActionItemProps = {
  text: string
  icon?: React.ReactNode
  onClick?: () => void
  to: string
}

export const DrawerActionItem = ({ text, icon, to, onClick }: DrawerActionItemProps) => {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={to} onClick={() => onClick?.()}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  )
}
