import { BookmarkBorder, PushPin } from "@mui/icons-material"
import { IconButton, IconButtonProps } from "@mui/material"
import { FC, useCallback, useState } from "react"

type PinIconProps = {
  onPinToggle: (isPinned: boolean) => Promise<any>
  isDefaultPinned: boolean
} & IconButtonProps

export const PinIcon: FC<PinIconProps> = ({ onPinToggle, isDefaultPinned, ...iconButtonProps }) => {
  const [isPinned, setIsPinned] = useState(isDefaultPinned)

  const handlePinClick = useCallback(async () => {
    await onPinToggle(!isPinned)
    setIsPinned(!isPinned)
  }, [isPinned, onPinToggle])

  return (
    <IconButton {...iconButtonProps} onClick={handlePinClick}>
      {isPinned ? <PushPin color="primary" /> : <BookmarkBorder />}
    </IconButton>
  )
}
