import { Context, EntityType, LinkedEntityUnion, Mutation } from "@ascully24/alfred"
import { ClearRounded } from "@mui/icons-material"
import { IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import { LINK_ENTITY_TO_CONTEXT, UNLINK_ENTITY_FROM_CONTEXT } from "alfred/graphql/mutations"
import { GET_CONTEXT } from "alfred/graphql/queries"
import AllNotes from "alfred/notes/AllNotes"
import { useMutationWithToast } from "graphql/apollo-utils"
import { useMemo } from "react"
import { Link } from "react-router-dom"

export const LinkedEntitiesDisplay = ({ context }: { context: Context }) => {
  const linkedEntities = useMemo(() => context.linkedEntities ?? [], [context.linkedEntities])
  const [unlinkEntity] = useMutationWithToast<Mutation>(UNLINK_ENTITY_FROM_CONTEXT, undefined, {
    successMessage: null,
  })

  const unlinkEntitySubmit = (id: string, entityType: EntityType): void => {
    unlinkEntity({
      variables: { contextId: context.id, entityId: id, entityType },
      refetchQueries: [{ query: GET_CONTEXT, variables: { contextId: context.id } }],
    })
  }

  return (
    <List>
      {linkedEntities.map((linkedEntity) => {
        const entityName = parseEntityName(linkedEntity.entity)
        const id = linkedEntity.id
        const entityType = linkedEntity.type
        const path = parseNavigationPath(linkedEntity.entity)

        return (
          <ListItem
            key={linkedEntity.id}
            secondaryAction={
              <IconButton
                color="error"
                edge="end"
                aria-label="delete"
                onClick={async () => await unlinkEntitySubmit(id, entityType)}
              >
                <ClearRounded />
              </IconButton>
            }
          >
            <ListItemButton component={Link} to={path}>
              <ListItemText primary={entityName} />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

function parseNavigationPath(entity: LinkedEntityUnion) {
  if (entity.__typename === "Note") {
    return `/notes/${entity.id}`
  }

  return ""
}

export const AddEntitiesForm = ({ context }: { context: Context }) => {
  const [linkEntity] = useMutationWithToast<Mutation>(LINK_ENTITY_TO_CONTEXT, undefined, {
    successMessage: "Entity linked",
  })

  const linkEntitySubmit = (entityId: string, entityType: EntityType): void => {
    linkEntity({
      variables: { contextId: context.id, entityId, entityType },
      refetchQueries: [{ query: GET_CONTEXT, variables: { contextId: context.id } }],
    })
  }

  return <AllNotes onNoteClick={async (note) => await linkEntitySubmit(note.id, "Note")} hideActions={true} />
}

const parseEntityName = (entity: LinkedEntityUnion) => {
  if (entity.__typename === "Note") {
    return entity.name
  }

  return "Empty Entity Name"
}
