import React from "react"
import { Chip } from "@mui/material"

export const statusColors: Record<
  string,
  "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
> = {
  Cancelled: "warning",
  "In Progress": "primary",
  Cancelling: "secondary",
  Completed: "success",
  Errored: "error",
}

export const AgentRunStatusChip = ({ status }: { status: keyof typeof statusColors }) => {
  return <Chip label={status} color={statusColors[status]} />
}
