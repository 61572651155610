import React from "react"
import { CircularProgress } from "@mui/material"
import { Query } from "@ascully24/alfred"
import { GET_AGENT_QUERY } from "alfred/graphql/queries"
import { useParams } from "react-router-dom"
import { AgentProps, CreateAgentForm } from "./CreateAgentForm"
import { useQueryWithLoading } from "graphql/apollo-utils"

export const EditAgentForm: React.FC<AgentProps> = () => {
  const { id } = useParams<{ id: string }>()
  const { loading, error, data } = useQueryWithLoading<Query>(GET_AGENT_QUERY, { variables: { id } })

  if (loading) {
    return <CircularProgress />
  }

  const agent = data?.agents?.get

  if (error || !agent) {
    return <div>Error...</div>
  }

  return <CreateAgentForm defaultAgent={agent} />
}
