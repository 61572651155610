import { Abc, Check, Lightbulb, SendRounded, Settings } from "@mui/icons-material"
import { Box, IconButton, Stack, Toolbar } from "@mui/material"
import { CollapsibleActionBar } from "action-bar/BottomActionBar"
import { useMonacoSuggestionEditor } from "autocomplete/MonacoSuggestionEditor"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCustomTheme } from "user/settings/Theme"
import { useScreenSizeValue } from "utils/ScreenSizes"

export const BASE_CONTEXT = `
This is a markdown string.

Complete the users sentence / paragraph / codeblock based on the input.
`

export const SendMessageField = ({
  submitMessage,
  label = "Message",
  defaultText = "",
  children,
  displayConfiguration = false,
  chatId,
}: {
  submitMessage: (text: string) => Promise<void | boolean>
  label?: string
  defaultText?: string
  children?: React.ReactNode
  displayConfiguration?: boolean
  chatId?: string
}) => {
  const [text, setText] = useState(defaultText)
  const [sendingMessage, setSendingMessage] = useState(false)
  const editorHeight = useScreenSizeValue({ xs: "150px", sm: "200px" })
  const navigation = useNavigate()

  const { theme } = useCustomTheme()

  const {
    MonacoSuggestionEditor,
    triggerSuggestion,
    triggerAcceptSuggestions,
    triggerAcceptWordSuggestion,
    hasSuggestions,
  } = useMonacoSuggestionEditor()

  useEffect(() => {
    setText(defaultText)
  }, [defaultText])

  const handleSendMessage = async () => {
    setSendingMessage(true)
    try {
      const success = await submitMessage(text)
      setSendingMessage(false)
      if (success || typeof success === "undefined") {
        setText("")
      }
    } catch (e) {
      setSendingMessage(false)
    }
  }

  return (
    <CollapsibleActionBar>
      <Toolbar>
        <Stack
          sx={{
            width: {
              xs: "100%",
              md: "80%",
            },
            mx: "auto",

            // Faded border
            border: "1px solid",
            borderColor: theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
            backgroundColor: theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)",
          }}
        >
          {children && children}
          <Stack direction="row" justifyContent="space-between">
            <Box id="message-actions">
              <IconButton
                onClick={() => {
                  triggerSuggestion()
                }}
                size="small"
                title="Trigger Suggestions"
              >
                <Lightbulb fontSize="small" />
              </IconButton>
              {hasSuggestions && (
                <>
                  <IconButton
                    onClick={() => {
                      triggerAcceptSuggestions()
                    }}
                    size="small"
                    color="success"
                    title="Accept Suggestions"
                  >
                    <Check fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      triggerAcceptWordSuggestion()
                    }}
                    size="small"
                    color="success"
                    title="Accept Word Suggestions"
                  >
                    <Abc fontSize="small" />
                  </IconButton>
                </>
              )}
            </Box>

            <Box>
              <IconButton onClick={() => handleSendMessage()}>
                <SendRounded fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              sx={{
                mt: "auto",
              }}
            >
              {chatId && (
                <IconButton
                  onClick={() => navigation(`/chats/${chatId}/settings`)}
                  size="small"
                  title="Chat Settings"
                  // Align to bottom
                  sx={{
                    mt: "auto",
                  }}
                >
                  <Settings color="primary" fontSize="small" />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <MonacoSuggestionEditor
                height={editorHeight}
                defaultValue={defaultText}
                value={text}
                onChange={(newValue = "") => {
                  setText(newValue)
                }}
                options={{
                  readOnly: sendingMessage,
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Toolbar>
    </CollapsibleActionBar>
  )
}
