import { Alert, Stack } from "@mui/material"

export const RegistrationEmailSent = () => {
  return (
    <div className="mx-auto">
      <Stack>
        <Alert>
          A verification link has been sent to your email. You will be able to login once you have verified
          your email.
        </Alert>
      </Stack>
    </div>
  )
}
