import { Divider, List } from "@mui/material"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"

export function DrawerActions({
  topDrawerActions,
  bottomDrawerActions,
}: {
  topDrawerActions: React.ReactNode
  bottomDrawerActions: React.ReactNode
}) {
  return (
    <Box>
      <Toolbar />
      <List>{topDrawerActions}</List>
      <Divider />
      <List
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      >
        {bottomDrawerActions}
      </List>
      <Toolbar />
    </Box>
  )
}
