import { Chat } from "@ascully24/alfred"
import { AccessTime } from "@mui/icons-material"
import { Box, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { ChatTags } from "alfred/ChatTags"
import { Link } from "react-router-dom"
import { calculateTimeAgo } from "utils/time"

export function ListItemChat({ chat, onClick }: { chat: Chat; onClick?: (chat: Chat) => Promise<any> }) {
  const {
    id: chatId,
    settings: { name },
    dateCreated,
  } = chat

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <ListItemButton component={Link} to={`/chats/${chatId}`} onClick={async () => await onClick?.(chat)}>
        <ListItemText
          primary={
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              gap={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
              justifyContent="space-between"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              sx={{
                flexWrap: "wrap",
                mb: {
                  xs: 2,
                  sm: 1,
                },
              }}
            >
              <Box
                sx={{
                  minWidth: 0,
                  flexGrow: 1,
                }}
              >
                {name}
              </Box>
            </Stack>
          }
          secondaryTypographyProps={{
            component: "div",
          }}
          secondary={
            <>
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                gap={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                sx={{
                  color: "text.secondary",
                }}
              >
                <Stack direction="row" gap={3}>
                  <Box>
                    <Typography variant="body2">
                      <AccessTime /> {calculateTimeAgo(new Date(dateCreated))}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </>
          }
        />
      </ListItemButton>
      <ChatTags chat={chat} />
    </Box>
  )
}
