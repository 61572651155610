import { Divider, Stack, Typography } from "@mui/material"
import { SettingsTextField, useSettings } from "user/settings/utils"

export const GeneralSettings = () => {
  const { error, loading, settings, handleChangeEvent, UpdateSettingsButton } = useSettings()

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! {error.message}</div>

  return (
    <Stack
      sx={{
        mb: 5,
        mx: { xs: 3, md: 0 },
      }}
      spacing={5}
    >
      <Stack gap={2}>
        <UpdateSettingsButton />
        <Divider>
          <Typography variant="h5">Theme</Typography>
        </Divider>
        <Divider>
          <Typography variant="h5">Global Chat Settings</Typography>
        </Divider>
        <SettingsTextField
          fullWidth
          multiline
          label="System Message"
          rows={5}
          name="systemMessage"
          value={settings.systemMessage}
          onChange={handleChangeEvent}
        />
        <Typography variant="body2">
          Configure the system message for you chat. This will be used by all chats. In OpenAI, this is the
          system message. In other LLMs, it will be prepended to the beginning of the chat.
        </Typography>
      </Stack>
    </Stack>
  )
}
