import { Chat, Query } from "@ascully24/alfred"
import { Box, debounce, List, ListItem } from "@mui/material"
import { GET_CHATS } from "alfred/graphql/queries"
import { ListDisplay } from "alfred/ListDisplay"
import { QueryWithLoadingParameters, useQueryWithFetchMore } from "graphql/apollo-utils"
import { useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { ScrollableBox } from "user/settings/Theme"
import { LoadingSpinner } from "../utils/LoadingSpinner"
import { AllChatActions, Filters } from "./AllChatActions"
import { ChatPin } from "./ChatPin"
import { ListItemChat } from "./ListItemChat"
import { DeleteChatAction } from "./ListItemChatActions"
import { useScrollDownRef } from "application-state/hooks"

const limit = 10
export const AllChats = ({
  includeSecondaryActions = true,
  onChatClick,
  onCreateChatClick,
  onContinuousChatClick,
}: {
  includeSecondaryActions?: boolean
  onCreateChatClick?: () => Promise<any>
  onChatClick?: (chat: Query["chat"]["list"][0]) => Promise<any>
  onContinuousChatClick?: () => Promise<any>
}) => {
  const [filters, setFilters] = useState<Filters>({ tags: [], name: "" })
  const scrollRef = useScrollDownRef()

  const handleFiltersChange = debounce((value: Filters) => {
    setFilters(value)
  }, 500)

  const queryParams: QueryWithLoadingParameters = [
    GET_CHATS,
    {
      variables: { tags: filters.tags, name: filters.name, offset: 0, limit },
    },
  ]

  const {
    called,
    loading,
    data: { chat } = {},
    fetchMoreItems,
    hasMore,
  } = useQueryWithFetchMore<Chat>(
    {
      limit,
      parseListItems: (data) => data?.chat?.list,
      parseUpdatedItems: (prev, moreItems) => ({
        chat: {
          list: [...prev.chat.list, ...moreItems],
        },
      }),
    },
    queryParams
  )
  const chats = chat?.list || []

  return (
    <>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <AllChatActions
          onFilterChange={handleFiltersChange}
          onCreateChatClick={onCreateChatClick}
          onContinuousChatClick={onContinuousChatClick}
        />
      </Box>
      <ScrollableBox
        id="scrollableDiv"
        ref={scrollRef}
        sx={{
          flexGrow: 1,
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfiniteScroll
          style={{
            // InfiniteScroll has a bug where it will cut off some of the children. This is a workaround
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
          }}
          scrollableTarget="scrollableDiv"
          dataLength={chats.length}
          next={fetchMoreItems}
          hasMore={hasMore}
          loader={<LoadingSpinner />}
        >
          <ListDisplay called={called} loading={loading} list={chats} emptyMessage="No Chats Found">
            <List>
              {chats.map((chat) => {
                const { id } = chat
                return (
                  <ListItem
                    key={id}
                    secondaryAction={
                      includeSecondaryActions ? (
                        <>
                          <DeleteChatAction chat={chat} />
                          <ChatPin chat={chat} />
                        </>
                      ) : undefined
                    }
                  >
                    <ListItemChat key={id} chat={chat} onClick={onChatClick} />
                  </ListItem>
                )
              })}
            </List>
          </ListDisplay>
        </InfiniteScroll>
      </ScrollableBox>
    </>
  )
}
