import { Box, Tab, Tabs } from "@mui/material"
import React, { useMemo, useState } from "react"

export const TabWrapper = ({ tabLabels, children }: { tabLabels: string[]; children: React.ReactNode }) => {
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (_event: any, newValue: any) => {
    setTabValue(newValue)
  }

  const tabPanels = useMemo(
    () => {
      return React.Children.toArray(children).map((child, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          {child}
        </TabPanel>
      ))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabValue, children]
  )
  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleChange}>
          {tabLabels.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
      </Box>
      {tabPanels}
    </Box>
  )
}

export function TabPanel(props: any) {
  const { children, value, index } = props

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
