import { Source } from "@ascully24/alfred"
import { Clear } from "@mui/icons-material"
import { Box, Grid, MenuItem, Select, TextField, debounce } from "@mui/material"
import { FilterSettingsToggle } from "alfred/filter/FilterSettingsToggle"
import { TagFilterField, tagRequestParam } from "alfred/filter/TagFilterField"
import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

export type Filters = {
  source: "All" | Source
  tags: string[]
  text: string
}

export function AllMessagesFilters({ onChange }: { onChange: (filters: Filters) => void }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const updateFilter = useMemo(() => {
    return (filterChanges: Partial<Filters>, searchParams: URLSearchParams) => {
      const newSearchParams = new URLSearchParams(searchParams.toString())

      Object.entries(filterChanges).forEach(([key, value]) => {
        if (key === "tags") {
          return
        }

        if (value === undefined) {
          return
        }

        if (value instanceof Array) {
          newSearchParams.delete(key)
          value.forEach((v) => newSearchParams.set(key, v))
          return
        }

        newSearchParams.set(key, value)
      })

      setSearchParams(newSearchParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filters = useMemo(() => {
    const newFilters: Filters = {
      source: (searchParams.get("source") as Filters["source"]) ?? "All",
      text: searchParams.get("text") ?? "",
      tags: searchParams.getAll(tagRequestParam),
    }

    return newFilters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const { tags, source } = filters

  const [text, setText] = useState(searchParams.get("text") ?? "")

  const debounceSetText = useMemo(() => {
    const debounceFun = debounce((value: string) => {
      updateFilter({ text: value }, searchParams)
    }, 500)
    return (value: string) => {
      setText(value)
      debounceFun(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    onChange(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, source, text])

  return (
    <FilterSettingsToggle>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between" // horizontally align the children
        spacing={3}
        sx={{
          width: "100%",
        }}
      >
        <Grid item xs={12} md={4}>
          <TextField
            id="message-text-filter"
            label="Contains Text"
            value={text}
            onChange={(e) => {
              const newText = e.target.value
              debounceSetText(newText)
            }}
            sx={{
              width: "100%",
            }}
            InputProps={{
              endAdornment: filters.text && (
                <Clear
                  onClick={() => {
                    updateFilter({ text: "" }, searchParams)
                    setText("")
                  }}
                  sx={{ cursor: "pointer" }}
                />
              ),
            }}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            value={source}
            onChange={async (e) => {
              const newSource = e.target.value as Filters["source"]
              updateFilter({ source: newSource }, searchParams)
            }}
            sx={{
              flexGrow: 1,
              width: "100%",
            }}
          >
            {["All", "user", "bot"].map((source) => (
              <MenuItem id={`model-select-${source}`} key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
            }}
          >
            <TagFilterField onChange={(newTags) => updateFilter({ tags: newTags }, searchParams)} />
          </Box>
        </Grid>
      </Grid>
    </FilterSettingsToggle>
  )
}
