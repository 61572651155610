import { Agent } from "@ascully24/alfred"
import { Add, PlayArrow } from "@mui/icons-material"
import {
  Box,
  Container,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material"
import { AgentPin } from "alfred/AgentPin"
import { GET_ALL_AGENTS } from "alfred/graphql/queries"
import { QueryWithLoadingParameters, useQueryWithFetchMore } from "graphql/apollo-utils"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link, useNavigate } from "react-router-dom"
import { ScrollableBox } from "user/settings/Theme"
import { LoadingSpinner } from "utils/LoadingSpinner"

const limit = 20

export const AllAgents = ({
  navigateToEditOnClick = true,
  onCreateAgentClick,
  onCreateAgentRunClick,
}: {
  navigateToEditOnClick?: boolean
  onCreateAgentClick?: () => Promise<any>
  onCreateAgentRunClick?: (agent: Agent) => Promise<any>
}) => {
  const navigate = useNavigate()

  const queryParams: QueryWithLoadingParameters = [GET_ALL_AGENTS, { variables: { offset: 0, limit } }]

  const { loading, called, data, fetchMoreItems, hasMore } = useQueryWithFetchMore(
    {
      limit,
      parseListItems: (data) => data?.agents?.list,
      parseUpdatedItems: (prev, moreItems) => ({
        agents: {
          list: [...prev.agents.list, ...moreItems],
        },
      }),
    },
    queryParams
  )

  const agents = data?.agents?.list ?? []

  if (loading) {
    return <></>
  }

  const createAgent = (
    <Fab
      onClick={async () => {
        await onCreateAgentClick?.()
        navigate("/agents/create")
      }}
      color="primary"
    >
      <Add />
    </Fab>
  )

  if (called && agents.length === 0) {
    return (
      <Box>
        {/* <AgentFilters onChange={handleFiltersChange} /> */}
        {createAgent}
        <Typography sx={{ p: 2 }} textAlign="center" variant="h6">
          No Agents Found
        </Typography>
      </Box>
    )
  }

  return (
    <Container>
      {createAgent}
      {/* <AgentFilters onChange={handleFiltersChange} /> */}
      <ScrollableBox
        id="scrollableDiv"
        sx={{
          flexGrow: 1,
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfiniteScroll
          style={{
            // InfiniteScroll has a bug where it will cut off some of the children. This is a workaround
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
          }}
          scrollableTarget="scrollableDiv"
          dataLength={agents.length}
          next={fetchMoreItems}
          hasMore={hasMore}
          loader={
            <Box sx={{ p: 2 }} textAlign="center">
              <LoadingSpinner />
            </Box>
          }
        >
          <List>
            {agents.map((agent) => (
              <ListItem key={agent.id}>
                <ListItemButton
                  component={Link}
                  to={navigateToEditOnClick ? `/agents/${agent.id}` : `/agents/${agent.id}/runs/create`}
                  onClick={async () => !navigateToEditOnClick && (await onCreateAgentRunClick?.(agent))}
                >
                  <ListItemText primary={agent.name} secondary={agent.description} />
                </ListItemButton>
                <ListItemSecondaryAction>
                  <AgentPin agent={agent} />
                  <IconButton
                    aria-label="start agent"
                    color="success"
                    onClick={async () => {
                      await onCreateAgentRunClick?.(agent)
                      navigate(`/agents/${agent.id}/runs/create`)
                    }}
                  >
                    <PlayArrow />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </InfiniteScroll>
      </ScrollableBox>
    </Container>
  )
}
