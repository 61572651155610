import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { AppBar, Box, Fab, IconButton, SxProps, Toolbar } from "@mui/material"
import { useDrawerWidth } from "action-bar/utils"
import { StickToBottomCenter } from "alfred/filter/FilterSettingsToggle"
import React, { useState } from "react"
import useLocalStorage from "use-local-storage"

export const BottomActionBar = ({
  children,
  onChange,
}: {
  children: React.ReactNode
  onChange?: (isCollapsed: boolean) => void
}) => {
  const drawerWidth = useDrawerWidth()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed)
    onChange?.(!isCollapsed)
  }

  return (
    <Box>
      <Toolbar />
      <Toolbar />
      {!isCollapsed ? (
        <AppBar
          position="fixed"
          color="inherit"
          elevation={10}
          sx={{
            top: "auto",
            bottom: 0,
            zIndex: 3,
            width: { md: `calc(100% - ${drawerWidth})` },
          }}
        >
          {children}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="collapse"
            onClick={handleCollapseToggle}
            sx={{ marginRight: 2 }}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </AppBar>
      ) : (
        <StickToBottomCenter>
          <Fab color="primary" aria-label="expand" onClick={handleCollapseToggle}>
            <ArrowDropUpIcon />
          </Fab>
        </StickToBottomCenter>
      )}
    </Box>
  )
}

const ignoreCookieId = "ignore-bottom-action-bar"

export const CollapsibleActionBar = ({
  localStorageId = ignoreCookieId,
  children,
  inverse = false,
  defaultIsCollapsed = true,
  fixed = false,
}: {
  localStorageId?: string
  children: React.ReactNode
  inverse?: boolean
  defaultIsCollapsed?: boolean
  fixed?: boolean
}) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage<boolean>(localStorageId, defaultIsCollapsed, {
    parser: (value) => value === "true",
    serializer: (value) => value?.toString() ?? "false",
  })

  const handleCollapseToggle = () => {
    const newCollapsedState = !isCollapsed
    setIsCollapsed(newCollapsedState)
    if (localStorageId !== ignoreCookieId) {
      setIsCollapsed(newCollapsedState)
    }
  }

  const collapseIcon = inverse ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
  const expandIcon = inverse ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />

  const boxSx: SxProps<any> = fixed
    ? { position: "fixed", bottom: 0, zIndex: 3 }
    : {
        position: "relative",
      }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "background.paper",
          width: "100%",
          ...boxSx,
        }}
      >
        {!isCollapsed ? (
          <Box
            display="flex"
            flexDirection={inverse ? "column-reverse" : "column"}
            color="inherit"
            sx={{
              top: "auto",
              bottom: 0,
              zIndex: 3,
              width: "100%",
            }}
          >
            {children}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton edge="end" color="inherit" aria-label="collapse" onClick={handleCollapseToggle}>
                {collapseIcon}
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Fab color="primary" aria-label="expand" onClick={handleCollapseToggle}>
            {expandIcon}
          </Fab>
        )}
      </Box>
    </>
  )
}
