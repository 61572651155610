import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"

export const ACCESS_DENIED_EXCEPTION = "accessDeniedException"

export function parseErrorCode(error?: ApolloError) {
  return error?.graphQLErrors?.[0]?.extensions?.code
}

export function parseErrorMessage(error?: ApolloError | readonly GraphQLError[]) {
  if (error instanceof Array) {
    return error[0].message
  }

  return error?.graphQLErrors?.[0]?.message
}
