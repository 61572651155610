import { Context, ContextFilter, Mutation } from "@ascully24/alfred"
import { Delete } from "@mui/icons-material"
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  debounce,
} from "@mui/material"
import { DELETE_CONTEXT } from "alfred/graphql/mutations"
import { LIST_CONTEXTS } from "alfred/graphql/queries"
import { QueryWithLoadingParameters, useMutationWithToast, useQueryWithFetchMore } from "graphql/apollo-utils"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link } from "react-router-dom"
import { LoadingSpinner } from "utils/LoadingSpinner"
import { AllContextActions, Filters } from "./AllContextActions"
import { useState } from "react"
import { ScrollableBox } from "user/settings/Theme"
import { ListDisplay } from "../ListDisplay"
import { ContextTags } from "alfred/context/ContextTags"

const limit = 20

export const AllContexts = ({ onCreateContextClick }: { onCreateContextClick?: () => Promise<any> }) => {
  const [filters, setFilters] = useState<Filters>({ tags: [], name: "" })

  const handleFiltersChange = debounce((value: Filters) => {
    setFilters(value)
  }, 500)

  const contextFilter: ContextFilter = {
    name: filters.name,
    queryParameters: {
      tags: filters.tags,
      filter: {
        limit,
        offset: 0,
      },
    },
  }
  const queryParams: QueryWithLoadingParameters = [LIST_CONTEXTS, { variables: { filter: contextFilter } }]

  const { loading, called, data, fetchMoreItems, hasMore } = useQueryWithFetchMore(
    {
      limit,
      parseListItems: (data) => data?.context?.list,
      parseUpdatedItems: (prev, moreItems) => ({
        context: {
          list: [...prev.context.list, ...moreItems],
        },
      }),
    },
    queryParams
  )

  const contexts = data?.context?.list ?? []

  return (
    <Container>
      <ScrollableBox
        id="scrollableDiv"
        sx={{
          flexGrow: 1,
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfiniteScroll
          style={{
            // InfiniteScroll has a bug where it will cut off some of the children. This is a workaround
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
          }}
          scrollableTarget="scrollableDiv"
          dataLength={contexts.length}
          next={fetchMoreItems}
          hasMore={hasMore}
          loader={<LoadingSpinner />}
        >
          <AllContextActions
            onCreateContextClick={onCreateContextClick}
            onFilterChange={handleFiltersChange}
          />
          <ListDisplay called={called} loading={loading} list={contexts} emptyMessage="No Contexts Found">
            <List>
              {contexts.map((context) => (
                <ListItem key={context.id} secondaryAction={<DeleteContextAction context={context} />}>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <ListItemButton component={Link} to={`/contexts/${context.id}`}>
                      <ListItemText primary={context.name} />
                    </ListItemButton>
                    <ContextTags context={context} />
                  </Box>
                </ListItem>
              ))}
            </List>
          </ListDisplay>
        </InfiniteScroll>
      </ScrollableBox>
    </Container>
  )
}

const DeleteContextAction = ({ context }: { context: Context }) => {
  const [deleteContext] = useMutationWithToast<Mutation>(DELETE_CONTEXT, undefined, {
    successMessage: "Context deleted",
  })
  return (
    <IconButton
      color="error"
      onClick={() => {
        deleteContext({
          variables: {
            contextId: context.id,
          },
          refetchQueries: [{ query: LIST_CONTEXTS }],
        })
      }}
    >
      <Delete />
    </IconButton>
  )
}
