import { Query, Tool } from "@ascully24/alfred"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { GET_AVAILABLE_TOOLS_QUERY } from "../graphql/queries"
import { useQueryWithLoading } from "graphql/apollo-utils"

interface AgentToolsProps {
  defaultSelectedTools?: string[]
  onChange?: (tools: Tool[]) => void
}

export const AgentTools: React.FC<AgentToolsProps> = ({ defaultSelectedTools = [], onChange }) => {
  const toolsResult = useQueryWithLoading<Query>(GET_AVAILABLE_TOOLS_QUERY)
  const availableTools = toolsResult?.data?.tools?.list ?? []

  const [selectedTools, setSelectedTools] = useState<Tool[]>(mapToTool(defaultSelectedTools, availableTools))

  useEffect(() => {
    setSelectedTools(mapToTool(defaultSelectedTools, availableTools))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultSelectedTools), JSON.stringify(availableTools)])

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      multiple
      disableCloseOnSelect
      limitTags={3}
      size="small"
      value={selectedTools}
      onChange={(_event, newValue) => {
        setSelectedTools([...newValue])
        onChange?.([...newValue])
      }}
      options={availableTools}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return <Chip label={option.name} {...getTagProps({ index })} />
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <>{params.InputProps.startAdornment}</>,
          }}
          label="Tools"
          placeholder="Add"
        />
      )}
    />
  )
}

function mapToTool(ids: string[], availableTools: Tool[]) {
  return ids.map((id) => availableTools.find((tool) => tool.id === id)).filter((tool) => tool) as Tool[]
}
