import { Configuration } from "@ascully24/alfred"

export function getConfigurationDisplayName(configuration: Configuration): string {
  switch (configuration) {
    case "ChatGPT_3":
      return "ChatGPT-3"
    case "ChatGPT_4":
      return "ChatGPT-4"
    case "ChatGPT_4_Turbo":
      return "ChatGPT-4 Turbo"
    default:
      return configuration
  }
}
