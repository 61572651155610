import { Box, Button, IconButton, Stack } from "@mui/material"
import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ReactMarkdown, { Options } from "react-markdown"
import { Prism as SyntaxHighlighter, SyntaxHighlighterProps } from "react-syntax-highlighter"
import { a11yDark } from "react-syntax-highlighter/dist/cjs/styles/prism"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import { toast } from "utils/Toast"
import { getCodeString } from "rehype-rewrite"
import { CopyAll } from "@mui/icons-material"

// Add a new component for the collapsible code block
const CollapsibleCodeBlock: React.FC<{ language?: string; code?: string } & SyntaxHighlighterProps> = ({
  language,
  code,
  ...props
}) => {
  const INITIAL_COLLAPSE_THRESHOLD = 15
  const COLLAPSIBLE_LIMIT = 10
  const PREVIEW_LINE_COUNT = 3

  // Define how many lines to show in the preview
  const codeLines = code?.split("\n") || []
  const codePreview = codeLines.slice(0, PREVIEW_LINE_COUNT).join("\n")
  const isCollapsible = codeLines.length > COLLAPSIBLE_LIMIT

  const [isCollapsed, setIsCollapsed] = useState(codeLines.length > INITIAL_COLLAPSE_THRESHOLD)
  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  return (
    <>
      <SyntaxHighlighter {...(props as any)} style={a11yDark} language={language} PreTag="div">
        {isCollapsed ? codePreview : code}
      </SyntaxHighlighter>

      {isCollapsible && (
        <Button
          sx={{
            fontSize: "0.7rem",
            float: "right",
          }}
          onClick={toggleCollapse}
          size="small"
        >
          {isCollapsed ? "Show more..." : "Show less"}
        </Button>
      )}
    </>
  )
}

export function getReactMarkdownProps(
  getPreCodeString: (children?: any) => string = (children) => children?.props?.children ?? ""
): Options & {
  className: string
} {
  return {
    className: "post-markdown",
    rehypePlugins: [rehypeRaw],
    remarkPlugins: [remarkGfm],
    components: {
      pre({ node, children, ...props }) {
        const code = getPreCodeString(children)

        return (
          <Stack>
            <Box>
              <CopyToClipboard text={code}>
                <IconButton
                  sx={{
                    fontSize: "0.7rem",
                    float: "right",
                    width: "fit-content",
                  }}
                  aria-label="copy"
                  title="Copy to clipboard"
                  variant="outlined"
                  onClick={() => toast.info("Copied to clipboard")}
                  size="small"
                  {...(props as any)}
                >
                  <CopyAll />
                </IconButton>
              </CopyToClipboard>
            </Box>
            <pre {...props}>{children}</pre>
          </Stack>
        )
      },
      code({ node, className = "blog-code", children, ...props }) {
        const match = /language-(\w+)/.exec(className || "")
        const language = match ? match[1] : undefined
        const code = parseCodeString(children, node)

        return match ? (
          <CollapsibleCodeBlock language={language} code={code} {...(props as any)} />
        ) : (
          <code className={className} {...props}>
            {code}
          </code>
        )
      },
      ul({ node, ...props }) {
        return <ul style={{ listStyleType: "disc" }} {...props} />
      },
      ol({ node, ...props }) {
        return <ol style={{ listStyleType: "decimal" }} {...props} />
      },
    },
  }
}

const reactMarkdownOptions = getReactMarkdownProps()

function parseCodeString(
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined,
  node: any
) {
  if (typeof children === "string" && /^\$\$(.*)\$\$/.test(children)) {
    return String(children).replace(/\n$/, "")
  }

  if (node?.children) {
    return getCodeString(node.children as any)
  }

  console.error("Could not parse code string in Markdown Viewer")
  return ""
}

export function MarkdownViewer({ markdownText }: { markdownText: string }) {
  return (
    <Box id="markdown-viewer">
      <ReactMarkdown {...reactMarkdownOptions}>{markdownText}</ReactMarkdown>
    </Box>
  )
}
