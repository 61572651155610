// src/hooks/useSharedData.ts
import { useEffect, useState } from "react"
import { openDB } from "idb"
import { Maybe } from "@ascully24/alfred"

// Only one record will be stored in the database
const id = 1

type SharedData = {
  id: number
  title?: string
  text?: string
  url?: string
}

async function openDatabase() {
  return openDB("sharedDataDB", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("sharedData")) {
        db.createObjectStore("sharedData")
      }
    },
  })
}

async function fetchSharedData(): Promise<SharedData> {
  const db = await openDatabase()
  return await db.get("sharedData", id)
}

async function deleteSharedData() {
  const db = await openDatabase()
  await db.delete("sharedData", id)
}

export async function saveSharedData(title?: string, text?: string, url?: string) {
  await deleteSharedData()
  const db = await openDatabase()
  await db.put("sharedData", { id, title, text, url }, id)
}

export function useSharedData() {
  const [data, setData] = useState<Maybe<SharedData>>(null)

  useEffect(() => {
    ;(async () => {
      const sharedData = await fetchSharedData()
      setData(sharedData)
    })()
  }, [])

  return { data }
}
