import { Button, Container, Fade, Stack, TextField } from "@mui/material"
import { backendUri } from "environment"
import { apolloClient } from "graphql/client"
import { useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { fetchWithAuth, headers } from "user/Fetch"
import { allUserDetailsQuery, useMyDetails } from "user/useMyDetails"
import { setSessionId } from "user/utils"
import { toast } from "utils/Toast"

const validator = (formData: UserLogin): string | null => {
  const { password, email } = formData
  if (!password) {
    return "Please provide an password."
  }

  if (!email) {
    return "Please provide an invitee name."
  }

  return null
}

type UserLogin = {
  email: string
  password: string
}

export const UserLoginForm = () => {
  const { user } = useMyDetails()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryParam = queryParams.get("redirect")
  const redirectValue = queryParam ? decodeURIComponent(queryParam) : "/"

  const [loginDetails, setLoginDetails] = useState<UserLogin>({
    email: "",
    password: "",
  })

  const onSubmit = async () => {
    const { email, password } = loginDetails

    toast.loading("Logging in...", { toastId: "logging-in" })

    if (!email || !password) {
      alert("Gotta fill the form in bro")
      return
    }

    const validation = validator(loginDetails)
    if (validation) {
      toast.dismiss("logging-in")
      toast.error(validation)
      return
    }
    try {
      const response = await fetchWithAuth(`${backendUri}/login`, {
        method: "POST",
        headers,
        body: JSON.stringify(loginDetails),
      })

      if (!response.ok) {
        const { message } = (await response?.json()) ?? {
          message: "Invalid password or name. Please try again.",
        }

        toast.dismiss("logging-in")
        toast.error(message)
        return
      }

      const sessionId = response.headers.get("x-Session-id")

      if (!sessionId) {
        toast.dismiss("logging-in")
        toast.error("Failure creating session. Please try again.")
        return
      }

      setSessionId(sessionId)

      await apolloClient.refetchQueries({ include: [allUserDetailsQuery] })
      toast.dismiss("logging-in")
      navigate(redirectValue, { replace: true })
    } catch (e: any) {
      toast.dismiss("logging-in")
      toast.error("Invalid password or name. Please try again.")
    }
  }

  if (user) {
    return <Navigate to={redirectValue} replace />
  }

  return (
    <Container>
      <form
        noValidate
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            onSubmit()
          }
        }}
        onSubmit={() => onSubmit()}
        autoComplete="off"
      >
        <Fade in={true}>
          <Stack spacing={2}>
            <TextField
              name="email"
              label="Email"
              type="email"
              onChange={(e) =>
                setLoginDetails({
                  ...loginDetails,
                  email: e.target.value,
                })
              }
            />
            <TextField
              name="password"
              label="Password"
              type="password"
              onChange={(e) => {
                setLoginDetails({
                  ...loginDetails,
                  password: e.target.value,
                })
              }}
            />
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button variant="contained" onClick={onSubmit}>
                Login
              </Button>
              <Button onClick={() => navigate("/register")}>Register</Button>
            </Stack>
          </Stack>
        </Fade>
      </form>
    </Container>
  )
}
