import { Box, Button, Modal, Typography } from "@mui/material"
import { useState } from "react"
import { useScreenSize } from "utils/ScreenSizes"

type Props = {
  title?: string
  body?: string
  oncContinue?: () => void
  disableCancel?: boolean
  disableConfirm?: boolean
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

type UseModal = {
  showModal: (props?: Props) => Promise<boolean>
  closeModalSuccess: () => any
  closeModalCancel: () => any
  Modal: any
}

export const useModal = (props: Props = {}): UseModal => {
  const width = useModalWidth()
  const [currentProps, setCurrentProps] = useState<Props>(props)
  const [show, setShow] = useState(false)

  const {
    title = "",
    body = "",
    oncContinue = () => {},
    disableCancel = false,
    disableConfirm = false,
  } = currentProps

  const [promiseState, setPromiseState] = useState<{
    resolve: (value: boolean) => void
  }>()

  const handleClose = () => setShow(false)

  const showModal = async (props?: Props) => {
    if (props) {
      setCurrentProps((prev) => ({ ...prev, ...props }))
    }

    setShow(true)
    return new Promise<boolean>((resolve) => {
      setPromiseState({ resolve })
      return promiseState
    })
  }

  const handleConfirm = () => {
    oncContinue()
    handleClose()
    promiseState?.resolve(true)
  }

  const handleCancel = () => {
    handleClose()
    promiseState?.resolve(false)
  }

  return {
    showModal,
    closeModalSuccess: handleConfirm,
    closeModalCancel: handleCancel,
    Modal: ({ children }: { children?: any }) => (
      <>
        <Modal
          open={show}
          onClose={handleCancel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            onKeyUpCapture={(e) => {
              if (e.key === "Enter" && !disableConfirm) {
                handleConfirm()
              }

              if (e.key === "Escape" && !disableCancel) {
                handleCancel()
              }
            }}
            sx={{
              ...style,
              width: width,
              mx: "auto",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {body}
            </Typography>
            {children}
            {!disableCancel && <Button onClick={handleCancel}>Cancel</Button>}
            {!disableConfirm && <Button onClick={handleConfirm}>Confirm</Button>}
          </Box>
        </Modal>
      </>
    ),
  }
}

function useModalWidth() {
  const isXs = useScreenSize("xs")
  const isSm = useScreenSize("sm")

  if (isXs) {
    return "90%"
  } else if (isSm) {
    return "70%"
  } else {
    return "60%"
  }
}

export default useModalWidth
