import {
  Abc,
  AppRegistrationRounded,
  ChatBubble,
  Home,
  Lightbulb,
  Login,
  Logout,
  Menu as MenuIcon,
  NoteAlt as NoteIcon,
  RecordVoiceOver,
  Settings,
  SupportAgent,
  Tag as TagIcon,
} from "@mui/icons-material"
import {
  AppBar,
  Container,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
} from "@mui/material"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import { DrawerActions } from "action-bar/DrawerActions"
import { useDrawerWidth } from "action-bar/utils"
import { useApplicationState } from "application-state/hooks"
import { backendUri } from "environment"
import { apolloClient } from "graphql/client"
import { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { fetchWithAuth, headers } from "user/Fetch"
import { DarkThemeSwitch } from "user/settings/Theme"
import { useMyDetails } from "user/useMyDetails"
import { removeBearerToken, removeSessionId } from "user/utils"
import { DrawerActionItem } from "./DrawerActionItem"
import { useScreenSizeValue } from "utils/ScreenSizes"

const titles: Record<string, string> = {
  "/chats": "Chats",
  "/messages": "Messages",
  "/tags": "Tags",
  "/register": "Register",
  "/login": "Login",
  "/settings": "Settings",
  "/settings/general": "General Settings",
  "/settings/voice": "Voice Settings",
  "/settings/services": "Service Settings",
  "/settings/llms": "Large Language Models",
}

export const Offset = styled("div")(({ theme }) => {
  // Height of theme.mixins.toolbar only (nothing else)
  const toolbarHeight = theme.mixins.toolbar.minHeight
  return {
    height: toolbarHeight,
  }
})

export const ActionBar = () => {
  const { user, refetch: refetchUserDetails } = useMyDetails()
  const [mobileOpen, setMobileOpen] = useState(false)
  const closeMobile = () => setMobileOpen(false)
  const navigation = useNavigate()

  const anchor = useScreenSizeValue({
    xs: "right",
    md: "left",
  })

  const {
    applicationState: { appBar: { title: appBarTitle } = {} },
  } = useApplicationState()

  const { pathname } = useLocation()

  const title = appBarTitle ?? titles[pathname] ?? ""

  updateDocumentTitle(title)

  const drawerWidth = useDrawerWidth()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  let topDrawActions = [
    <DrawerActionItem key="Home" text="Home" icon={<Home />} to="/" onClick={closeMobile} />,
  ]
  if (user) {
    topDrawActions = [
      ...topDrawActions,
      <DrawerActionItem key="Chats" text="Chats" icon={<ChatBubble />} to="/chats" onClick={closeMobile} />,
      <DrawerActionItem key="Notes" text="Notes" icon={<NoteIcon />} to="/notes" onClick={closeMobile} />,
      <DrawerActionItem
        key="Voice Assistant"
        text="Voice Assistant"
        icon={<RecordVoiceOver />}
        to="/voice-assistant"
        onClick={closeMobile}
      />,
      <DrawerActionItem key="Messages" text="Messages" icon={<Abc />} to="/messages" onClick={closeMobile} />,
      <DrawerActionItem
        key="Agents"
        text="Agents"
        icon={<SupportAgent />}
        to="/agents"
        onClick={closeMobile}
      />,
      <DrawerActionItem
        key="Contexts"
        text="Contexts"
        icon={<Lightbulb />}
        to="/contexts"
        onClick={closeMobile}
      />,
      <DrawerActionItem key="Tags" text="Tags" icon={<TagIcon />} to="/tags" onClick={closeMobile} />,
    ]
  }

  const baseBottomDrawActions = [
    <ListItem key="Theme">
      <DarkThemeSwitch />
    </ListItem>,
  ]
  const bottomDrawActions = user
    ? [
        ...baseBottomDrawActions,
        <DrawerActionItem
          key="Settings"
          text="Settings"
          icon={<Settings />}
          to="/settings"
          onClick={closeMobile}
        />,
        <ListItem key="Logout" disablePadding>
          <ListItemButton
            onClick={async () => {
              try {
                await apolloClient.clearStore()
              } catch (e) {
                console.error("Failed to clear store", e)
              }

              const response = await fetchWithAuth(`${backendUri}/logout`, {
                method: "DELETE",
                headers,
              })

              if (!response.ok) {
                console.error("Failed to logout", response)
              }

              removeSessionId()
              removeBearerToken()
              await refetchUserDetails()
              closeMobile()
              navigation("/login")
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>,
      ]
    : [
        ...baseBottomDrawActions,
        <DrawerActionItem key="Login" text="Login" icon={<Login />} to="/login" onClick={closeMobile} />,
        <DrawerActionItem
          key="Register"
          text="Register"
          icon={<AppRegistrationRounded />}
          to="/register"
          onClick={closeMobile}
        />,
      ]

  return (
    <Box id="action-bar">
      <Box>
        <AppBar
          position="fixed"
          color="primary"
          sx={{
            top: {
              xs: "auto",
              sm: 0,
            },
            bottom: {
              xs: 0,
              sm: "auto",
            },
          }}
        >
          <Container maxWidth="lg" disableGutters>
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{
                  position: "absolute", // Use absolute positioning
                  right: { xs: 0, md: "auto" }, // Right aligned on 'xs', default on 'md'
                  left: { xs: "auto", md: 0 }, // Default on 'xs', left aligned on 'md'
                  mr: "auto",
                }}
              >
                <MenuIcon />
              </IconButton>
              <Stack id="quick-actions" direction="row" gap={5}>
                <ActionBarItem text="Home" icon={<Home />} to="/" />
                <ActionBarItem text="Notes" icon={<NoteIcon />} to="/notes" />
                <ActionBarItem text="Context" icon={<Lightbulb />} to="/contexts" />
                <ActionBarItem text="Chats" icon={<ChatBubble />} to="/chats" />
              </Stack>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <Box component="nav" sx={{ width: drawerWidth, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          anchor={anchor}
          id="temporary-drawer"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          <DrawerActions topDrawerActions={topDrawActions} bottomDrawerActions={bottomDrawActions} />
        </Drawer>
      </Box>
    </Box>
  )
}

function updateDocumentTitle(title: string) {
  if (title !== document.title) {
    if (title === "") {
      document.title = "WAYF"
    } else {
      document.title = title
    }
  }
}

const ActionBarItem: React.FC<{ text: string; icon: React.ReactNode; to: string }> = ({ text, icon, to }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={to} sx={{ padding: 0 }}>
        <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>{icon}</ListItemIcon>
        <ListItemText
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            margin: 0,
          }}
          primary={text}
        />
      </ListItemButton>
    </ListItem>
  )
}
