import { Check, Close } from "@mui/icons-material"
import { Box, Chip, Input, Stack } from "@mui/material"
import { UserInputEvent } from "utils/chips/types"
import { useState } from "react"

export type EditChipCallback<T> = (oldText: string, newText: string, oldValue?: T) => Promise<void>
export type RemoveChipCallback<T> = (text: string, value: T) => Promise<void>

export function ManagedChip<T>({
  text,
  removeChip,
  editChip,
  value,
}: {
  text: string
  value: T
  removeChip: RemoveChipCallback<T>
  editChip?: EditChipCallback<T>
}) {
  const [oldTag, setOldTag] = useState<string>(text)
  const [editTagValue, setEditTagValue] = useState<string>(text)
  const [isEditingTag, setIsEditingTag] = useState<boolean>(false)

  const onDelete = async (tag: string) => {
    await removeChip(tag, value)
  }

  const onEdit = (tag: string) => {
    setIsEditingTag(true)
  }

  const onCancelEdit = (e: UserInputEvent) => {
    e.stopPropagation()
    setEditTagValue(oldTag)
    setIsEditingTag(false)
  }

  const onSubmitEdit = async (e: UserInputEvent) => {
    e.stopPropagation()
    await editChip?.(oldTag, editTagValue)
    setOldTag(editTagValue)
    setIsEditingTag(false)
  }

  return (
    <Box>
      {isEditingTag ? (
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          gap={1}
          alignItems="center"
        >
          <Input
            value={editTagValue}
            onClick={(e) => e.stopPropagation()}
            onKeyUpCapture={(e) => {
              if (e.key === "Enter") {
                onSubmitEdit(e)
              }

              if (e.key === "Escape") {
                onSubmitEdit(e)
              }
            }}
            onChange={(e) => setEditTagValue(e.target.value)}
            placeholder="Enter tag"
            autoFocus={true}
            sx={{
              fontSize: "0.8rem",
              paddingX: "0.5rem",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            gap={{
              xs: 4,
              sm: 2,
            }}
          >
            <Check
              onClick={onSubmitEdit}
              sx={{
                cursor: "pointer",
                color: "green",
                // Larger size for mobile
                fontSize: { xs: "1.8rem", sm: "1.5rem" },
              }}
            />
            <Close
              onClick={onCancelEdit}
              sx={{
                cursor: "pointer",
                color: "red",
                fontSize: { xs: "1.8rem", sm: "1.5rem" },
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <Chip
          onDelete={() => onDelete(text)}
          onClick={(e) => {
            e.stopPropagation()
            if (editChip) {
              onEdit(text)
            }
          }}
          label={text}
          size="small"
          sx={{
            fontSize: "0.8rem",
          }}
        />
      )}
    </Box>
  )
}
