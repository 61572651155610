import { Button, Fade, Stack, TextField } from "@mui/material"
import { backendUri } from "environment"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { fetchWithAuth, headers } from "user/Fetch"
import { toast } from "utils/Toast"

const validator = (formData: UserRegistration): string | null => {
  const { password, email, confirmPassword } = formData

  if (!password) {
    return "Please provide an password."
  }

  if (confirmPassword !== password) {
    return "Passwords do not match."
  }

  if (!email) {
    return "Please provide an invitee name."
  }

  return null
}

type UserRegistration = {
  email: string
  password: string
  confirmPassword: string
}

const register = async (registerDetails: UserRegistration): Promise<boolean> => {
  const response = await fetchWithAuth(`${backendUri}/register`, {
    method: "POST",
    headers,
    body: JSON.stringify(registerDetails),
  })

  if (!response.ok) {
    const body = await response.json()
    toast.error(body.message)
  }

  return true
}

export const UserRegistrationForm = () => {
  const navigate = useNavigate()
  const [registrationForm, setRegistrationForm] = useState<UserRegistration>({
    email: "",
    password: "",
    confirmPassword: "",
  })

  const onSubmit = async () => {
    const validation = validator(registrationForm)

    if (validation) {
      toast.error(validation)
      return
    }

    const success = await register({ ...registrationForm })

    if (!success) {
      toast.error("Something went wrong. Contact us and we will try and sort it out for you.")
      return
    }
    navigate(`/register/email-sent`, { replace: true })
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onKeyDownCapture={(e) => {
        if (e.key === "Enter") {
          onSubmit()
        }
      }}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Fade in={true}>
        <Stack spacing={2}>
          <TextField
            name="email"
            label="Email"
            type="email"
            onChange={(e) => {
              setRegistrationForm({
                ...registrationForm,
                email: e.target.value,
              })
            }}
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            onChange={(e) => {
              setRegistrationForm({
                ...registrationForm,
                password: e.target.value,
              })
            }}
          />
          <TextField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            onChange={(e) => {
              setRegistrationForm({
                ...registrationForm,
                confirmPassword: e.target.value,
              })
            }}
          />
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="contained" onClick={onSubmit}>
              Complete Registration
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </form>
  )
}
