import { toast as toastify, ToastContent, ToastOptions, ToastPromiseParams } from "react-toastify"

type WayfToastParams = {
  pending?: string
  success?: string
  error?: string
}

const TOAST_DEFAULT_SETTINGS: ToastOptions = {
  position: toastify.POSITION.TOP_CENTER,
  autoClose: 3000,
  toastId: "invitee-link-copied",
  hideProgressBar: true,
}

const DEFAULT_PROMISE_PARAMS: ToastPromiseParams = {
  pending: "Loading...",
}

const mergeDefaultOptions = (options: ToastOptions = TOAST_DEFAULT_SETTINGS) => ({
  ...TOAST_DEFAULT_SETTINGS,
  ...options,
})

const mergePromiseParams = (params: ToastPromiseParams = DEFAULT_PROMISE_PARAMS) => ({
  ...DEFAULT_PROMISE_PARAMS,
  ...params,
})

export const toast = {
  success: (message: ToastContent<unknown>, options?: ToastOptions) =>
    toastify.success(message, mergeDefaultOptions(options)),

  error: (message: string, options?: ToastOptions) => toastify.error(message, mergeDefaultOptions(options)),

  info: <TData = unknown>(message: ToastContent<TData>, options?: ToastOptions) =>
    toastify.info(message, mergeDefaultOptions(options)),

  warning: (message: string, options?: ToastOptions) =>
    toastify.warning(message, mergeDefaultOptions(options)),

  loading: (message: string, options?: ToastOptions) =>
    toastify.loading(message, mergeDefaultOptions(options)),

  promise: (promise: Promise<any>, options?: ToastOptions, params?: WayfToastParams) =>
    toastify.promise(promise, mergePromiseParams(params), mergeDefaultOptions(options)),

  dismiss: (id?: string) => toastify.dismiss(id),
}
