import { useSubscription } from "@apollo/client"
import { Mutation, Subscription } from "@ascully24/alfred"
import { Alert, Box, Button, Container, Stack } from "@mui/material"
import { CollapsibleActionBar } from "action-bar/BottomActionBar"
import {
  CREATE_GET_CONTINUOUS_CHAT,
  CREATE_VOICE_ASSISTANT_CHAT,
  SEND_MESSAGE,
} from "alfred/graphql/mutations"
import { ALL_MESSAGES_NAME, GET_CHAT } from "alfred/graphql/queries"
import { RECEIVE_VOICE_SUBSCRIPTION } from "alfred/graphql/subscription"
import { SendMessageField } from "alfred/messages/send/SendMessageField"
import { usePropelledText } from "application-state/hooks"
import { useMutationWithTry } from "graphql/apollo-utils"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useMyDetails } from "user/useMyDetails"
import { LoadingSpinner } from "utils/LoadingSpinner"
import { useChat, useChatSettingsUpdate } from "./hooks"
import { ChatConfiguration } from "./messages/send/ChatConfiguration"
import { useAudioPlayer } from "./useAudioPlayer"

export const VoiceAssistantChat = () => {
  const { user, loading: detailsLoading, refetch } = useMyDetails()
  const voiceAssistantChatId = user?.voiceAssistantChatId
  const {
    loading: chatLoading,
    MessagesComponent,
    chat,
  } = useChat({ chatId: voiceAssistantChatId ?? undefined })

  const { updateChatSettingsDebounce } = useChatSettingsUpdate(voiceAssistantChatId ?? undefined)
  const [createVoiceAssistantChat] = useMutationWithTry<Mutation>(CREATE_VOICE_ASSISTANT_CHAT)

  if (detailsLoading || chatLoading) return <LoadingSpinner />

  if (!voiceAssistantChatId)
    return (
      <Container>
        <Alert severity="error">Voice assistant chat not found. Start one up here.</Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            await createVoiceAssistantChat()
            await refetch()
          }}
        >
          Start Voice Assistant Chat
        </Button>
      </Container>
    )

  return (
    <>
      {MessagesComponent}
      <CollapsibleActionBar defaultIsCollapsed={true} localStorageId="voice-assistant-chat-settings">
        <ChatConfiguration defaultChatSettings={chat?.settings} onChange={updateChatSettingsDebounce} />
      </CollapsibleActionBar>
    </>
  )
}

export const ContinuousChat = () => {
  const [mutation] = useMutationWithTry<Mutation>(CREATE_GET_CONTINUOUS_CHAT)

  const [chatId, setChatId] = useState<string | undefined>(undefined)
  useEffect(() => {
    const createChat = async () => {
      const { data } = await mutation()
      setChatId(data?.chat?.createOrGetContinuousChatId)
    }

    createChat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!chatId) return <LoadingSpinner />

  return <Chat chatId={chatId} />
}

export const ChatInstance = () => {
  const { id: chatId } = useParams()
  return <Chat chatId={chatId} />
}

export const Chat = ({ chatId }: { chatId: string | undefined }) => {
  const defaultText = usePropelledText()

  const { setVoiceUrls, PlayButton, PauseButton, ResetButton } = useAudioPlayer()

  useSubscription<Subscription>(RECEIVE_VOICE_SUBSCRIPTION, {
    variables: { chatId },
    onData: (subscription) => {
      const voiceUpdates = subscription.data.data?.voiceUpdates
      if (!voiceUpdates) return
      const url = voiceUpdates.url
      setVoiceUrls((prevUrls) => {
        if (prevUrls.some((prevUrl) => prevUrl === url)) {
          return prevUrls
        }

        return [...prevUrls, url]
      })
    },
  })

  const { refetchChat, MessagesComponent, chat, loading } = useChat({ chatId })

  const [sendMessage] = useMutationWithTry<Mutation>(SEND_MESSAGE)

  const submitMessage = useMemo(
    () => async (text: string) => {
      const response = await sendMessage({
        variables: { chatId, text },
        refetchQueries: [ALL_MESSAGES_NAME, { query: GET_CHAT, variables: { id: chatId } }],
      })

      await refetchChat()
      return response?.data?.chat?.sendMessage
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatId]
  )

  return (
    <>
      {MessagesComponent}
      {chat?.settings.isVoiceEnabled && (
        <Box
          sx={{
            display: loading ? "none" : "block",
          }}
        >
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center", mb: 2 }}>
            <PlayButton />
            <PauseButton />
            <ResetButton />
          </Stack>
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          display: loading ? "none" : "block",
        }}
      >
        <SendMessageField chatId={chatId} defaultText={defaultText} submitMessage={submitMessage} />
        {/* <ChatConfiguration defaultChatSettings={chat?.settings} onChange={updateChatSettingsDebounce} /> */}
        {/* </SendMessageField> */}
      </Box>
    </>
  )
}
