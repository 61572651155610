import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"

export {}
declare global {
  interface Date {
    toReadableDate(): string
  }
}
// Error ignored as we are allowed to override prototype if we desire.
// eslint-disable-next-line no-extend-native
Date.prototype.toReadableDate = function () {
  return this.toLocaleTimeString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
}

TimeAgo.addDefaultLocale(en)

const timeAgo = new TimeAgo("en-GB")

export function today(): Date {
  return new Date()
}

export function calculateTimeAgo(dateAdded: Date) {
  const now = today().getTime()
  try {
    return timeAgo.format(now - (now - new Date(dateAdded).getTime()))
  } catch (e) {
    return dateAdded.toLocaleString()
  }
}
