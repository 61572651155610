import { createContext, useCallback, useState } from "react"

export interface ApplicationState {
  propelledMessage: {
    text: string
    canConsume?: boolean | null
  } | null
  appBar: {
    title?: string
  }
  userActions: {
    scrollingDown?: boolean
  }
}

const initialState: ApplicationState = {
  propelledMessage: null,
  appBar: {},
  userActions: {},
}

interface ApplicationStateContextProps {
  applicationState: ApplicationState
  updateApplication: (applicationState: Partial<ApplicationState>) => Promise<any>
}

export const ApplicationStateContext = createContext<ApplicationStateContextProps>({
  applicationState: initialState,
  updateApplication: async () => {},
})

export const ApplicationStateProvider = ({ children }: { children: any }) => {
  const [applicationState, setApplicationState] = useState<ApplicationState>(initialState)

  const updateApplication = useCallback(async (newState: Partial<ApplicationState>) => {
    await setApplicationState((prevState) => ({ ...prevState, ...newState }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ApplicationStateContext.Provider value={{ applicationState, updateApplication }}>
      {children}
    </ApplicationStateContext.Provider>
  )
}
