import { Box, Typography } from "@mui/material"
import { CreateChatField } from "alfred/messages/send/CreateChatField"
import { usePropelledText } from "application-state/hooks"

export const CreateChatForm = () => {
  const defaultText = usePropelledText()

  return (
    <Box pt={2}>
      <Typography variant="h6">Create a new chat</Typography>
      <CreateChatField defaultText={defaultText} />
    </Box>
  )
}
