import { useSubscription } from "@apollo/client"
import { ChatSettingsInput, Message, Mutation, Subscription } from "@ascully24/alfred"
import { Alert, Box, Divider, Stack } from "@mui/material"
import { UPDATE_CHAT_SETTINGS } from "alfred/graphql/mutations"
import { GET_CHAT, GET_CHATS_NAME } from "alfred/graphql/queries"
import { RECEIVE_MESSAGE_SUBSCRIPTION } from "alfred/graphql/subscription"
import { MessagesWithActions } from "alfred/messages/MessageList"
import { useScrollDownRef } from "application-state/hooks"
import debounce from "debounce"
import { QueryWithLoadingParameters, useMutationWithToast, useQueryWithFetchMore } from "graphql/apollo-utils"
import { apolloClient } from "graphql/client"
import { useMemo } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { ScrollableBox } from "user/settings/Theme"
import { LoadingSpinner } from "utils/LoadingSpinner"

const limit = 10

export const useChat = ({ chatId }: { chatId: string | undefined }) => {
  const scrollRef = useScrollDownRef()

  const queryParams: QueryWithLoadingParameters = [
    GET_CHAT,
    {
      variables: { id: chatId, offset: 0, limit },
    },
  ]

  const {
    data: { chat: { get: chat } } = { chat: { get: null } },
    loading,
    fetchMoreItems,
    hasMore,
    refetch: refetchChat,
    updateQuery,
  } = useQueryWithFetchMore<Message>(
    {
      limit,
      parseListItems: (data) => data?.chat?.get?.messages,
      parseUpdatedItems: (prev, moreItems) => {
        return {
          chat: {
            get: {
              messages: [...(prev?.chat?.get?.messages ?? []), ...moreItems],
            },
          },
        }
      },
    },
    queryParams
  )

  useSubscription<Subscription>(RECEIVE_MESSAGE_SUBSCRIPTION, {
    shouldResubscribe: true,
    variables: { chatId },
    onData: (subscription) => {
      const chatUpdates = subscription.data.data?.chatUpdates
      if (!chatUpdates) return
      const { updatedMessage, chatName } = chatUpdates

      updateQuery((prev) => {
        let newMessages = prev?.chat?.get?.messages ?? []
        if (updatedMessage) {
          const { messageId: latestMessageId, text } = updatedMessage
          newMessages =
            prev?.chat?.get?.messages?.map((message) => {
              if (message.id === latestMessageId) {
                return {
                  ...message,
                  text,
                }
              }
              return message
            }) ?? []
        }

        if (chatName && prev?.chat?.get?.settings?.name !== chatName) {
          apolloClient.refetchQueries({ include: [GET_CHATS_NAME] })
        }

        return Object.assign({}, prev, {
          chat: {
            get: {
              ...(prev?.chat?.get ?? {}),
              name: chatName ?? prev?.chat?.get?.settings?.name,
              messages: newMessages,
            },
          },
        })
      })
    },
  })

  const messages = [...(chat?.messages ?? [])].reverse()

  const MessagesComponent = (
    <>
      <Box sx={{ display: loading ? "block" : "none" }}>
        <LoadingSpinner />
      </Box>
      <Alert sx={{ display: !loading && !chat ? "block" : "none" }} severity="error">
        Chat not found{" "}
      </Alert>
      <ScrollableBox
        id="scrollableDiv"
        ref={scrollRef}
        sx={{
          display: !loading && chat ? "flex" : "none",
          overflowY: "auto",
          flexDirection: "column-reverse",
          px: {
            xs: 3,
            s: 2,
            m: 0,
          },
        }}
      >
        <InfiniteScroll
          style={{ overflow: "visible", display: "flex", flexDirection: "column-reverse" }}
          dataLength={messages.length}
          next={fetchMoreItems}
          hasMore={hasMore}
          inverse={true}
          scrollThreshold={0.1}
          loader={<LoadingSpinner />}
          scrollableTarget="scrollableDiv"
        >
          <Stack gap={2}>
            {messages.map((message) => {
              return (
                <Box key={message.id}>
                  <MessagesWithActions message={message} />
                  <Divider />
                </Box>
              )
            })}
          </Stack>
        </InfiniteScroll>
      </ScrollableBox>
    </>
  )

  return {
    refetchChat,
    MessagesComponent,
    loading,
    chat,
  }
}

export const useChatSettingsUpdate = (chatId: string | undefined) => {
  const [updateChatSettings] = useMutationWithToast<Mutation>(UPDATE_CHAT_SETTINGS)

  const updateChatSettingsDebounce = useMemo(
    () =>
      debounce(async (settings: ChatSettingsInput) => {
        await updateChatSettings({
          variables: {
            chatId,
            settings,
          },
          refetchQueries: [GET_CHATS_NAME, { query: GET_CHAT, variables: { id: chatId } }],
        })
      }, 750),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatId]
  )

  return {
    updateChatSettingsDebounce,
    updateChatSettings,
  }
}
