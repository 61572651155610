import { Context } from "@ascully24/alfred"
import { Box, Button, Stack, TextField } from "@mui/material"
import React, { useState } from "react"

export function ContextEditor({
  onSubmit,
  defaultContext,
  children,
}: {
  onSubmit: (name: string) => Promise<any>
  defaultContext?: Context
  children?: React.ReactNode
}) {
  const [contextForm, setContextForm] = useState({
    name: defaultContext?.name ?? "Untitled Context",
  })

  return (
    <Stack
      gap={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "88vh",
        p: 2,
      }}
    >
      <Box sx={{ ml: "auto" }}>
        <Button
          variant="contained"
          onClick={async (event) => {
            event.preventDefault()
            await onSubmit(contextForm.name)
          }}
        >
          Save
        </Button>
      </Box>
      <TextField
        label="Name"
        value={contextForm.name}
        size="small"
        onChange={(e) => setContextForm({ ...contextForm, name: e.target.value })}
        fullWidth
      />
      {children}
    </Stack>
  )
}
