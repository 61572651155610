import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
  useQuery,
} from "@apollo/client"
import { Maybe, Query } from "@ascully24/alfred"
import debounce from "debounce"
import { GraphQLError } from "graphql"
import { merge } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"
import { useMyDetails } from "user/useMyDetails"
import { toast } from "utils/Toast"

type UseMutationWithToast = {
  <TData = any, TVariables = OperationVariables>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: MutationHookOptions<TData, TVariables>,
    toastConfig?: {
      delay?: number
      successMessage?: Maybe<string>
      errorMessage?: Maybe<string>
      loadingMessage?: Maybe<string>
    }
  ): MutationTuple<TData, TVariables>
}

function useNotLoggedInRedirect() {
  const { refetch } = useMyDetails()

  const isLoggedIn = (error: GraphQLError) => {
    if (error.extensions?.code === "notLoggedIn") {
      toast.warning("Session expired. Please log in again.")
      refetch()
      return false
    }

    return true
  }

  return { isLoggedIn }
}

export const useMutationWithToast: UseMutationWithToast = (mutation, options, toastConfig = {}) => {
  const {
    delay = 750,
    successMessage = "Updated!",
    loadingMessage = "Loading...",
    errorMessage,
  } = toastConfig
  const [loading, setLoading] = useState(false)
  const [mutate, result] = useMutationWithTry(mutation, options)
  const { isLoggedIn } = useNotLoggedInRedirect()
  const toastDebounce = useMemo(
    () =>
      debounce(() => {
        if (loading && loadingMessage !== null) {
          toast.loading(loadingMessage, { toastId: "loading" })
          return
        }
        toast.dismiss("loading")
      }, delay),
    [loading, delay, loadingMessage]
  )

  const mutateWithToast: typeof mutate = async (...args) => {
    setLoading(true)
    const result = await mutate(...args)
    setLoading(false)

    if (successMessage === null) {
      return result
    }
    if (result.errors && errorMessage !== null) {
      if (isLoggedIn(result.errors[0])) {
        toast.error(result.errors[0].message)
      }
      return result
    }

    toast.success(successMessage)
    return result
  }

  useEffect(() => {
    toastDebounce()
    return () => {
      toast.dismiss("loading")
      toastDebounce.clear()
    }
  }, [toastDebounce, loading])

  return [mutateWithToast, result]
}

export const useMutationWithTry: typeof useMutation = (mutation, options) => {
  const [mutate, result] = useMutation(mutation, options)
  const { isLoggedIn } = useNotLoggedInRedirect()

  const mutateWithTry: typeof mutate = async (...args) => {
    try {
      return await mutate(...args)
    } catch (error: any) {
      if (error instanceof ApolloError) {
        isLoggedIn(error.graphQLErrors[0])
      } else {
        toast.error(error.message)
      }
    }
    return {
      data: null,
    }
  }
  return [mutateWithTry, result]
}

type UseQueryWithLoadingOptions = typeof useQuery & {
  notFoundMessage?: string
}

export const useQueryWithLoading: UseQueryWithLoadingOptions = (query, options) => {
  const [loading, setLoading] = useState(true)

  const { isLoggedIn } = useNotLoggedInRedirect()

  const toastDebounce = useMemo(
    () =>
      debounce(() => {
        if (loading) {
          toast.loading(`Loading...`, { toastId: "loading" })
          return
        }
        toast.dismiss("loading")
      }, 1000),
    [loading]
  )

  const result = useQuery(query, {
    ...options,

    onCompleted: (data) => {
      setLoading(false)
      toastDebounce.clear()
      toast.dismiss("loading")

      options?.onCompleted?.(data)
    },
    onError: (error) => {
      setLoading(false)
      if (!isLoggedIn(error.graphQLErrors[0])) {
        return
      }
      toastDebounce.clear()
      toast.dismiss("loading")
      toast.error(`Failed to load data`)

      options?.onError?.(error)
    },
  })

  useEffect(() => {
    toastDebounce()
    return () => {
      toast.dismiss("loading")
      toastDebounce.clear()
    }
  }, [toastDebounce, loading])

  return {
    ...result,
  }
}

export function useQueryWithFetchMore<L>(
  { limit, parseListItems, parseUpdatedItems, getVariableOffset }: UseFetchMoreType<L>,
  useQueryParams: QueryWithLoadingParameters
) {
  const [hasMore, setHasMore] = useState(true)
  const fetchMoreCallCount = useRef(1)

  // Prevents infinite loop
  const originalOnComplete = useQueryParams[1]?.onCompleted
  const newQueryParams = useMemo(() => {
    const newParams: typeof useQueryParams = [...useQueryParams]
    newParams[1] = {
      ...useQueryParams[1],
      onCompleted: (data) => {
        originalOnComplete?.(data)
        const items = parseListItems(data) ?? []
        if (items.length < limit * fetchMoreCallCount.current) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
      },
    }
    return newParams
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, useQueryParams])

  const { fetchMore, ...useQueryResult } = useQueryWithLoading<Query>(...newQueryParams)

  const items = parseListItems(useQueryResult.data) ?? []

  const fetchMoreItems = async () => {
    fetchMoreCallCount.current++

    const variables = getVariableOffset?.(useQueryResult.variables) ?? {
      ...useQueryResult.variables,
      offset: items.length,
    }
    const response = await fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!hasMore) {
          return prev
        }

        if (!fetchMoreResult) {
          setHasMore(false)
          return prev
        }

        const moreItems = parseListItems(fetchMoreResult) ?? []
        if (moreItems.length < limit) {
          setHasMore(false)
        }
        const updatedItems = parseUpdatedItems(prev, moreItems)
        return merge({}, fetchMoreResult, updatedItems)
      },
    })

    const fetchedRuns = parseListItems(response.data) ?? []

    // If fetched data length is less than the limit, no more data is available
    if (fetchedRuns.length < limit) {
      setHasMore(false)
    }

    return fetchedRuns
  }

  return {
    hasMore,
    setHasMore,
    fetchMoreItems,
    ...useQueryResult,
  }
}

export type QueryWithLoadingParameters = Parameters<typeof useQueryWithLoading<Query>>

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends Maybe<infer U>
    ? Maybe<RecursivePartial<U>> // Add this line to handle the Maybe type
    : T[P] extends object | undefined
    ? RecursivePartial<T[P]>
    : T[P]
}

type UseFetchMoreType<L> = {
  limit: number
  parseListItems(data?: Query | null): L[] | undefined | null
  parseUpdatedItems: (prev: Query, fetchedItems: L[]) => RecursivePartial<Query>
  getVariableOffset?: (variables: any) => any
}
