import { Mutation } from "@ascully24/alfred"
import { Cancel as CancelIcon } from "@mui/icons-material"
import { Button, IconButton } from "@mui/material"
import { CANCEL_AGENT_RUN } from "alfred/graphql/mutations"
import { useMutationWithToast } from "graphql/apollo-utils"

export function CancelAgentRun({
  id,
  onCompleted,
  asButton = true,
}: {
  id: string
  onCompleted: (data: Mutation) => any
  asButton?: boolean
}) {
  const [cancelRun] = useMutationWithToast<Mutation>(CANCEL_AGENT_RUN, undefined, {
    successMessage: "Cancellation requested",
  })

  const onClick = () => {
    const confirmDelete = window.confirm("Are you sure you want to cancel this run?")

    if (!confirmDelete) {
      return
    }

    cancelRun({
      variables: {
        id,
      },
      onCompleted,
    })
  }

  if (asButton) {
    return (
      <>
        <Button onClick={onClick} color="warning" variant="contained">
          Cancel
        </Button>
      </>
    )
  }

  return (
    <>
      <IconButton onClick={onClick} color="warning" edge="end" aria-label="cancel">
        <CancelIcon />
      </IconButton>
    </>
  )
}
