import { DocumentNode, gql, useQuery } from "@apollo/client"
import { Query } from "@ascully24/alfred"

export const CurrentUserQueryName = "MyDetails"

export const allUserDetailsQuery = gql`
  query ${CurrentUserQueryName} {
    user {
      id
      voiceAssistantChatId
    }
  }
`
export const useMyDetails = (query: DocumentNode = allUserDetailsQuery) => {
  const { loading, error, data: { user } = {}, refetch } = useQuery<Query>(query)

  return {
    refetch,
    loading,
    error,
    user,
  }
}
