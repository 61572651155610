import { VisibilityOff } from "@mui/icons-material"
import { Box, Chip, IconButton, Tooltip } from "@mui/material"
import { Stack } from "@mui/system"
import { useState } from "react"
import { EditChipCallback, RemoveChipCallback, ManagedChip } from "utils/chips/ManagedChip"
import {
  AddChipCallback,
  AddItemAutoComplete,
  GetOptionLabelCallback,
  UnknownOptionCallback,
} from "./AddItemAutoComplete"

export type GetChipTextCallback<T> = (item: T) => string

export function AutoCompleteChipDisplay<T>({
  values = [],
  autoCompleteValues,
  removeChip,
  editChip,
  addChip,
  getChipText,
  onUnknownOption,
  getOptionLabel,
  inputPlaceholder = "Enter",
  addPlaceholder = "Add",
  asColumn = false,
}: {
  values: T[]
  getOptionLabel: GetOptionLabelCallback<T>
  editChip?: EditChipCallback<T>
  removeChip: RemoveChipCallback<T>
  addChip: AddChipCallback<T>
  onUnknownOption: UnknownOptionCallback
  getChipText: GetChipTextCallback<T>
  autoCompleteValues: T[]
  inputPlaceholder?: string
  addPlaceholder?: string
  asColumn?: boolean
}) {
  const [showAllChips, setShowAllChips] = useState(false)

  const renderChip = (item: T, index: number) => {
    const text = getChipText(item)
    return (
      <ManagedChip
        key={`${text}-${index}`}
        value={item}
        text={text}
        removeChip={removeChip}
        editChip={editChip}
      />
    )
  }

  const chipDisplay = showAllChips
    ? values.map((tag, index) => renderChip(tag, index))
    : values.slice(0, 3).map((tag, index) => renderChip(tag, index))

  const remainingTagsCount = values.length - chipDisplay.length

  return (
    <Stack
      direction={{
        xs: "column-reverse",
        sm: asColumn ? "column-reverse" : "row",
      }}
      gap={1}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          flexWrap: "wrap",
          p: 0,
          m: 0,
        }}
      >
        {chipDisplay}
        {remainingTagsCount > 0 && !showAllChips && (
          <Box>
            <Tooltip
              title={values.slice(3).map((tag, index) => {
                const text = getChipText(tag)
                return <div key={text + index}>{text}</div>
              })}
            >
              <Chip
                label={`+${remainingTagsCount}`}
                size="small"
                sx={{
                  fontSize: "0.8rem",
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setShowAllChips(true)
                }}
              />
            </Tooltip>
          </Box>
        )}
        {showAllChips && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              setShowAllChips(false)
            }}
            sx={{
              padding: 0,
              color: "text.secondary",
            }}
          >
            <VisibilityOff />
          </IconButton>
        )}
      </Stack>
      <Box
        sx={{
          flexShrink: 0,
        }}
      >
        <AddItemAutoComplete
          asColumn={asColumn}
          autoCompleteValues={autoCompleteValues}
          inputPlaceholder={inputPlaceholder}
          addPlaceholder={addPlaceholder}
          getOptionLabel={getOptionLabel}
          onAdd={addChip}
          onUnknownOption={onUnknownOption}
        />
      </Box>
    </Stack>
  )
}
