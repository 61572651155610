// queries.tsx
import { gql } from "@apollo/client"

export const SUGGEST_QUERY = gql`
  query Suggest($input: AutocompleteInput!) {
    autocomplete {
      suggest(input: $input) {
        suggestions
      }
    }
  }
`
