import { Note } from "@ascully24/alfred"
import { Box, Container, Grid, Typography, debounce } from "@mui/material"
import { GET_ALL_NOTES } from "alfred/graphql/queries"
import { AllNotesActions, NoteFilters } from "alfred/notes/AllNotesActions"
import { QueryWithLoadingParameters, useQueryWithFetchMore } from "graphql/apollo-utils"
import { useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { LoadingSpinner } from "utils/LoadingSpinner"
import NoteDisplay from "./NoteDisplay"
import { ScrollableBox } from "user/settings/Theme"

const limit = 30

function AllNotes({
  onNoteClick,
  hideActions,
}: {
  onNoteClick?: (note: Note) => Promise<any>
  hideActions?: boolean
}) {
  const [filters, setFilters] = useState<NoteFilters>({ tags: [], name: "", content: "" })

  const handleFiltersChange = debounce((value: NoteFilters) => {
    setFilters(value)
  }, 500)

  const queryParams: QueryWithLoadingParameters = [
    GET_ALL_NOTES,
    {
      variables: {
        filter: {
          name: filters.name,
          content: filters.content,
          queryParameters: { tags: filters.tags, filter: { offset: 0, limit } },
        },
      },
    },
  ]

  const {
    data: { note: { list: notes } } = { note: { list: [] } },
    loading,
    fetchMoreItems,
    hasMore,
    error,
  } = useQueryWithFetchMore(
    {
      limit,
      parseListItems: (data) => data?.note?.list,
      parseUpdatedItems: (prev, moreItems) => ({
        note: {
          list: [...(prev?.note?.list ?? []), ...moreItems],
        },
      }),
      getVariableOffset: (variables) => {
        const rootFilter = variables?.filter ?? {}
        const queryParameters = rootFilter?.queryParameters ?? {}
        return {
          ...variables,
          filter: {
            ...rootFilter,
            queryParameters: {
              ...queryParameters,
              filter: {
                ...queryParameters?.filter,
                offset: queryParameters.filter.offset + limit,
              },
            },
          },
        }
      },
    },
    queryParams
  )

  return (
    <Box
      id="all-notes-list"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "90vh",
      }}
    >
      <Container>
        <AllNotesActions onFilterChange={handleFiltersChange} />
      </Container>

      <ScrollableBox
        id="scrollableDiv"
        sx={{
          flexGrow: 1,
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfiniteScroll
          dataLength={notes.length}
          next={fetchMoreItems}
          scrollThreshold={0.1}
          hasMore={hasMore}
          loader={<LoadingSpinner />}
          scrollableTarget="scrollableDiv"
        >
          <Grid
            container
            spacing={{
              xs: 1,
              sm: 2,
              md: 3,
            }}
            sx={{
              p: {
                xs: 1,
                s: 2,
                m: 3,
              },
            }}
          >
            <NoteListDisplay
              notes={notes}
              loading={loading}
              error={error}
              onClick={onNoteClick}
              hideActions={hideActions}
            />
          </Grid>
        </InfiniteScroll>
      </ScrollableBox>
    </Box>
  )
}

const NoteListDisplay = ({
  notes,
  loading,
  error,
  onClick,
  hideActions,
}: {
  notes: Note[]
  loading: boolean
  error: Error | undefined
  onClick?: (note: Note) => Promise<any>
  hideActions?: boolean
}) => {
  if (loading && notes.length === 0) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <Typography
        sx={{
          p: 2,
        }}
        textAlign="center"
        variant="h6"
      >
        Error: {error.message}
      </Typography>
    )
  }

  if (notes.length === 0) {
    return (
      <Typography
        sx={{
          p: 2,
        }}
        textAlign="center"
        variant="h6"
      >
        No Notes Found
      </Typography>
    )
  }

  return (
    <>
      {notes.map((note) => (
        <Grid item xs={12} sm={6} md={3} key={note.id}>
          <NoteDisplay note={note} onClick={onClick} hideActions={hideActions} />
        </Grid>
      ))}
    </>
  )
}

export default AllNotes
