import { Box } from "@mui/material"
import { useApplicationState } from "application-state/hooks"
import { AllChats } from "alfred/AllChats"
import { AllAgents } from "alfred/agents/AllAgents"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { TabWrapper } from "./TabWrapper"

export const PropelDestinationList = () => {
  const navigate = useNavigate()
  const didMountRef = React.useRef(false)
  const { applicationState, updateApplication } = useApplicationState()
  const { propelledMessage } = applicationState

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!propelledMessage && !didMountRef.current) {
      navigate(-1)
    }
    didMountRef.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propelledMessage])

  if (!propelledMessage) {
    return <></>
  }

  const updateCanConsume = async () => {
    updateApplication({
      propelledMessage: {
        ...propelledMessage,
        canConsume: true,
      },
    })
  }

  return (
    <Box sx={{ width: "100%" }}>
      <TabWrapper tabLabels={["Chats", "Agents"]}>
        <AllChats
          includeSecondaryActions={false}
          onCreateChatClick={updateCanConsume}
          onChatClick={updateCanConsume}
          onContinuousChatClick={updateCanConsume}
        />
        <AllAgents
          navigateToEditOnClick={false}
          onCreateAgentClick={updateCanConsume}
          onCreateAgentRunClick={updateCanConsume}
        />
      </TabWrapper>
    </Box>
  )
}
