import { Note } from "@ascully24/alfred"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { Box, Card, CardActionArea, CardContent, IconButton, Typography } from "@mui/material"
import { DELETE_NOTE } from "alfred/graphql/mutations"
import { GET_ALL_NOTES } from "alfred/graphql/queries"
import { NoteTags } from "alfred/notes/NoteTags"
import { useMutationWithToast } from "graphql/apollo-utils"
import { useNavigate } from "react-router-dom"
import { MarkdownViewer } from "utils/MarkdownViewer"
import { useModal } from "utils/Modal"

function NoteDisplay({
  note,
  onClick,
  hideActions,
}: {
  note: Note
  onClick?: (note: Note) => Promise<any>
  hideActions?: boolean
}) {
  const navigate = useNavigate()
  const content = note.content.substring(0, 100)
  const displayContent = content.length === 100 ? `${content}...` : content
  const [deleteNote] = useMutationWithToast(DELETE_NOTE, undefined, {
    successMessage: "Note deleted",
  })
  const { showModal, Modal: DeleteModal } = useModal({
    title: "Are you sure?",
  })
  return (
    <Card elevation={5}>
      <DeleteModal />
      <CardActionArea
        component="div"
        onClick={() => {
          if (onClick) {
            onClick(note)
          } else {
            navigate(`/notes/${note.id}`)
          }
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2">
            {note.name}
          </Typography>
          <Box sx={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            <MarkdownViewer markdownText={displayContent} />
          </Box>
        </CardContent>
      </CardActionArea>
      {!hideActions && (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              size="small"
              color="error"
              onClick={async (event) => {
                event.stopPropagation()
                const confirmDelete = await showModal()
                if (!confirmDelete) return
                await deleteNote({
                  variables: {
                    noteId: note.id,
                  },
                  refetchQueries: [GET_ALL_NOTES],
                  onError: () => {},
                })
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              px: 2,
              pb: 2,
            }}
          >
            <NoteTags note={note} />
          </Box>
        </>
      )}
    </Card>
  )
}

export default NoteDisplay
