import { Note } from "@ascully24/alfred"
import { Abc, Check, Lightbulb } from "@mui/icons-material"
import { Box, Button, IconButton, Stack, TextField, debounce } from "@mui/material"
import MDEditor from "@uiw/react-md-editor"
import { useMonacoSuggestionEditor } from "autocomplete/MonacoSuggestionEditor"
import React, { useMemo, useState } from "react"
import { useCustomTheme } from "user/settings/Theme"
import { getReactMarkdownProps } from "utils/MarkdownViewer"
import { useScreenSize } from "utils/ScreenSizes"

const reactMarkdownOptions = getReactMarkdownProps((children) => children?.[1]?.props?.["data-code"] ?? "")

export function NoteEditor({
  onSubmit,
  defaultNote,
  children,
  runAutosave = false,
}: {
  onSubmit: (context: string, title: string) => Promise<any>
  defaultNote?: Note
  children?: React.ReactNode
  runAutosave?: boolean
}) {
  const [content, setContent] = useState(defaultNote?.content ?? "")
  const [name, setName] = useState(defaultNote?.name ?? "")
  const { theme } = useCustomTheme()
  const isSmallScreen = useScreenSize("sm")
  const {
    MonacoSuggestionEditor,
    triggerSuggestion,
    triggerAcceptSuggestions,
    triggerAcceptWordSuggestion,
    hasSuggestions,
  } = useMonacoSuggestionEditor()

  const autosave = useMemo(
    () =>
      debounce((content: string, name: string) => {
        if (!runAutosave) return
        onSubmit(content, name)
      }, 500),
    [onSubmit, runAutosave]
  )

  const handleContentChange = (contentParam: string | undefined) => {
    const newContent = contentParam ?? ""
    setContent(newContent)
    autosave(newContent, name)
  }

  return (
    <Stack
      gap={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "88vh",
        p: 2,
      }}
    >
      <Box sx={{ ml: "auto" }}>
        <Button
          variant="contained"
          onClick={async (event) => {
            event.preventDefault()
            await onSubmit(content, name)
          }}
        >
          Save
        </Button>
      </Box>
      <TextField
        label="Name"
        value={name}
        size="small"
        onChange={(e) => {
          const newName = e.target.value
          setName(newName)
          autosave(content, newName)
        }}
        fullWidth
      />
      <Box>{children}</Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
        data-color-mode={theme}
      >
        <MDEditor
          components={{
            textarea: () => {
              return (
                <Stack
                  sx={{
                    height: "100%",
                    width: "100%",
                    // Faded border
                    border: "1px solid",
                    borderColor: theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
                    backgroundColor: theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Box id="message-actions">
                      <IconButton
                        onClick={() => {
                          triggerSuggestion()
                        }}
                        size="small"
                        title="Trigger Suggestions"
                      >
                        <Lightbulb fontSize="small" />
                      </IconButton>
                      {hasSuggestions && (
                        <>
                          <IconButton
                            onClick={() => {
                              triggerAcceptSuggestions()
                            }}
                            size="small"
                            color="success"
                            title="Accept Suggestions"
                          >
                            <Check fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              triggerAcceptWordSuggestion()
                            }}
                            size="small"
                            color="success"
                            title="Accept Word Suggestions"
                          >
                            <Abc fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Box>

                    <Box></Box>
                  </Stack>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <MonacoSuggestionEditor
                      height="100%"
                      defaultValue={content}
                      value={content}
                      onChange={(newValue = "") => {
                        handleContentChange(newValue)
                      }}
                    />
                  </Box>
                </Stack>
              )
            },
          }}
          commands={[]}
          preview={isSmallScreen ? "live" : "edit"}
          value={content}
          // onChange={handleContentChange}
          previewOptions={reactMarkdownOptions}
          height="100%"
          onKeyDown={(e) => {
            if (e.ctrlKey && e.key === "Enter") {
              e.preventDefault()
              onSubmit(content, name)
            }
          }}
        />
      </Box>
    </Stack>
  )
}
