import { Chat, Mutation, Query } from "@ascully24/alfred"
import { ADD_CHAT_TAG, EDIT_CHAT_TAG, REMOVE_CHAT_TAG } from "alfred/graphql/mutations"
import { GET_CHATS, GET_TAGS } from "alfred/graphql/queries"
import { useMutationWithTry, useQueryWithLoading } from "graphql/apollo-utils"
import { AutoCompleteChipDisplay } from "utils/chips/ChipDisplay"

export const ChatTags = ({ chat }: { chat: Chat }) => {
  const chatId = chat.id
  const { data: availableTagsData } = useQueryWithLoading<Query>(GET_TAGS)

  const [editTag] = useMutationWithTry<Mutation>(EDIT_CHAT_TAG)
  const [removeTag] = useMutationWithTry<Mutation>(REMOVE_CHAT_TAG)
  const [addTag] = useMutationWithTry<Mutation>(ADD_CHAT_TAG)

  const addTagCallback = async (tag: string) => {
    const { data } = await addTag({
      variables: { chatId, tag },
      refetchQueries: [GET_CHATS, GET_TAGS],
    })

    return !!data?.chat?.tag?.add?.id
  }

  return (
    <AutoCompleteChipDisplay
      getOptionLabel={(option) => option}
      values={chat.settings.tags}
      editChip={async (oldTag, newTag) => {
        await editTag({
          variables: { chatId, oldTag, newTag },
          refetchQueries: [GET_CHATS, GET_TAGS],
        })
      }}
      removeChip={async (tag) => {
        await removeTag({
          variables: { chatId, tag },
          refetchQueries: [GET_CHATS, GET_TAGS],
        })
      }}
      addChip={addTagCallback}
      onUnknownOption={addTagCallback}
      autoCompleteValues={availableTagsData?.tag?.list ?? []}
      getChipText={(tag) => tag}
    />
  )
}
