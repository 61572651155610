import { Mutation, Query } from "@ascully24/alfred"
import { CREATE_NOTE, UPDATE_NOTE } from "alfred/graphql/mutations"
import { GET_ALL_NOTES_NAME, GET_NOTE } from "alfred/graphql/queries"
import { useMutationWithToast, useQueryWithLoading } from "graphql/apollo-utils"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { NoteEditor } from "./NoteEditor"
import { NoteTags } from "alfred/notes/NoteTags"
import { LoadingSpinner } from "utils/LoadingSpinner"

export function CreateNoteForm() {
  const navigate = useNavigate()
  const [createNote] = useMutationWithToast<Mutation>(CREATE_NOTE, undefined, {
    successMessage: "Note created",
  })

  const handleSubmit = async (content: string, name: string) => {
    const { data } = await createNote({ variables: { content, name }, refetchQueries: [GET_ALL_NOTES_NAME] })

    if (data?.note.create?.id) {
      navigate(`/notes/${data.note.create.id}`, { replace: true })
    }
  }

  return <NoteEditor onSubmit={handleSubmit} />
}

export function EditNoteForm() {
  const id = useParams().id

  const { data, loading, error } = useQueryWithLoading<Query>(GET_NOTE, {
    variables: { id },
  })

  const [updateNote] = useMutationWithToast<Mutation>(UPDATE_NOTE, undefined, {
    successMessage: null,
  })
  const handleSubmit = async (content: string, name: string) => {
    await updateNote({
      variables: { noteId: id, content, name },
      refetchQueries: [GET_ALL_NOTES_NAME, { query: GET_NOTE, variables: { id } }],
    })
  }

  if (loading) return <LoadingSpinner />
  if (error || !data?.note.get) return <div>Error</div>

  const note = data?.note.get

  return (
    <NoteEditor defaultNote={note} onSubmit={handleSubmit} runAutosave>
      <NoteTags asColumn={false} note={note} />
    </NoteEditor>
  )
}
