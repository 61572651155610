import { getBearerToken, setBearerToken, getSessionId } from "./utils"

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

export function fetchWithAuth(input: RequestInfo, init: RequestInit = {}): Promise<Response> {
  const initHeaders = init.headers ?? {}
  const headers = { ...initHeaders, authorization: getBearerToken() }
  return fetchWithSetAuth(input, {
    ...init,
    headers,
  })
}

export async function fetchWithSetAuth(input: RequestInfo, init: RequestInit = {}): Promise<Response> {
  const initHeaders = init.headers ?? {}
  const headers = { ...initHeaders, authorization: getBearerToken(), "x-session-id": getSessionId() }
  const response = await fetch(input, {
    ...init,
    headers,
  })
  const token = response.headers.get("x-authorization")
  if (token) {
    setBearerToken(token)
  }
  return response
}

export function webSocketConnectionParams() {
  return {
    authorization: getBearerToken(),
    "x-session-id": getSessionId(),
  }
}
