import { Chat, Mutation } from "@ascully24/alfred"
import { SET_CHAT_PINNED } from "alfred/graphql/mutations"
import { GET_CHATS } from "alfred/graphql/queries"
import { useMutationWithTry } from "graphql/apollo-utils"
import { PinIcon } from "utils/pinning/PinIcon"

export const ChatPin = ({ chat }: { chat: Chat }) => {
  const chatId = chat.id
  const [setChatPinned] = useMutationWithTry<Mutation>(SET_CHAT_PINNED)

  return (
    <PinIcon
      isDefaultPinned={!!chat.metaData?.pinned?.date}
      onPinToggle={async (pinned) => {
        await setChatPinned({
          variables: { chatId, pinned },
          refetchQueries: [GET_CHATS],
        })
      }}
    />
  )
}
