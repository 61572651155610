import { gql } from "@apollo/client"
import { AgentRunFragment } from "alfred/graphql/fragments"

export const RECEIVE_MESSAGE_SUBSCRIPTION = gql`
  subscription ($chatId: ID!) {
    chatUpdates(chatId: $chatId) {
      chatId
      chatName
      updatedMessage {
        messageId
        text
      }
    }
  }
`

export const RECEIVE_VOICE_SUBSCRIPTION = gql`
  subscription ($chatId: ID!) {
    voiceUpdates(chatId: $chatId) {
      chatId
      url
    }
  }
`

export const ALL_PROMPTS_SUBSCRIPTION = gql`
  subscription {
    promptUpdates {
      promptId
      name
      description
    }
  }
`

export const AGENT_RUN_SUBSCRIPTION = gql`
  subscription OnAgentRun($id: ID!) {
    agentRun(id: $id) {
      ...AgentRun
    }
  }

  ${AgentRunFragment}
`

export const ALL_AGENT_RUN_SUBSCRIPTION = gql`
  subscription AllAgentRunsSubscription($agentId: ID!) {
    allAgentRuns(agentId: $agentId) {
      ...AgentRun
    }
  }

  ${AgentRunFragment}
`
