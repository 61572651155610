import { Pause, PlayArrow, RestartAlt } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { backendUri } from "environment"
import { useCallback, useEffect, useRef, useState } from "react"
import { fetchWithAuth } from "user/Fetch"

export function useAudioPlayer(clearOnFinish = false) {
  const [voiceUrls, setVoiceUrls] = useState<string[]>([])
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [currentUrlIndex, setCurrentUrlIndex] = useState<number>(0)

  const [currentPlayState, setCurrentPlayState] = useState<PlayState>("stopped") // New state variable

  const play = useCallback(() => {
    if (currentPlayState !== "playing" && audioRef.current) {
      audioRef.current.play().catch((error) => console.log(error))
      setCurrentPlayState("playing")
    }
  }, [currentPlayState])

  const pause = useCallback(() => {
    if (currentPlayState === "playing" && audioRef.current) {
      audioRef.current.pause()
      setCurrentPlayState("paused")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayState])

  const reset = useCallback(() => {
    setCurrentUrlIndex(0)
    setCurrentPlayState("stopped")
    if (audioRef.current) {
      audioRef.current.currentTime = 0
    }
  }, [])

  const playAudio = useCallback(
    async (playIndex: number, urls: string[], playState: PlayState) => {
      if (urls.length <= 0 || playState === "playing") {
        return
      }

      try {
        setCurrentPlayState("playing")

        const path = urls[playIndex]
        const response = await fetchWithAuth(`${backendUri}/${path}`)
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)

        if (!audioRef.current) {
          audioRef.current = new Audio(url)
        } else {
          audioRef.current.src = url
        }

        audioRef.current.onended = () => {
          setCurrentUrlIndex((prevIndex) => prevIndex + 1)

          if (playIndex === urls.length - 1) {
            if (clearOnFinish) {
              setVoiceUrls([])
              setCurrentUrlIndex(0)
              setCurrentPlayState("stopped")
              return
            }
            setCurrentPlayState("finished")
            return
          }

          setCurrentPlayState("stopped")
        }

        audioRef.current.play().catch((error) => console.log(error))
      } catch (e) {
        console.log(e)
      }
    },
    [clearOnFinish]
  )

  useEffect(() => {
    if (
      voiceUrls.length > 0 &&
      currentPlayState !== "playing" &&
      currentPlayState !== "finished" &&
      currentPlayState !== "paused"
    ) {
      playAudio(currentUrlIndex, voiceUrls, currentPlayState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceUrls, currentUrlIndex, currentPlayState])

  // Update playState to 'stopped' when voiceUrls are updated and there are more audio files to play
  useEffect(() => {
    if (voiceUrls.length > 0 && currentUrlIndex < voiceUrls.length && currentPlayState === "finished") {
      setCurrentPlayState("stopped")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceUrls.join(), currentUrlIndex, currentPlayState])

  // Unload audio file when component unmounts
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.src = ""
      }
    }
  }, [])

  return {
    voiceUrls,
    setVoiceUrls,
    currentUrlIndex,
    setCurrentUrlIndex,
    playAudio,
    playState: currentPlayState,
    setPlayState: setCurrentPlayState,
    play,
    pause,
    reset,
    clear: () => {
      setCurrentUrlIndex(0)
      setCurrentPlayState("stopped")
      setVoiceUrls([])
    },

    PlayButton: ({ onClick = () => play() }: { onClick?: () => void }) => {
      return (
        <IconButton onClick={onClick}>
          <PlayArrow />
        </IconButton>
      )
    },
    PauseButton: ({ onClick = () => pause() }: { onClick?: () => void }) => {
      return (
        <IconButton onClick={onClick}>
          <Pause />
        </IconButton>
      )
    },
    ResetButton: ({ onClick = () => reset() }: { onClick?: () => void }) => {
      return (
        <IconButton onClick={onClick}>
          <RestartAlt />
        </IconButton>
      )
    },
  }
}

export interface Options {
  root?: Element | null
  rootMargin?: string
  threshold?: number
}
type PlayState = "playing" | "stopped" | "finished" | "paused"
