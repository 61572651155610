import { Box } from "@mui/material"
import { AgentRunsList } from "alfred/agents/AgentRunsList"
import { useParams } from "react-router-dom"

export const ViewMessageAgentRunsPage = () => {
  const { id } = useParams<{ id: string }>()

  if (!id) return <Box>Provide a message id</Box>

  return (
    <Box>
      <AgentRunsList entityId={id} entityType="Message" />
    </Box>
  )
}
