import { Divider, Stack, Typography } from "@mui/material"
import { PasswordField, SettingsTextField, useSettings } from "user/settings/utils"

export const ServiceSettings = () => {
  const { error, loading, settings, handleChangeEvent, UpdateSettingsButton } = useSettings()

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! {error.message}</div>

  return (
    <Stack
      sx={{
        mb: 5,
        mx: { xs: 3, md: 0 },
      }}
      spacing={5}
    >
      <UpdateSettingsButton />
      <Stack gap={2}>
        <Divider>
          <Typography variant="h5">Serp API</Typography>
        </Divider>
        <Typography variant="body2">
          SerpAPI is a data scraping tool which simulates the behaviour of web browsers to extract the Google
          search results for your application.
          <br />
          <br />
          This will be used by Agents using the Serp API Tool.
        </Typography>
        <PasswordField
          label="SerpAPI API Key"
          name="serpAPI"
          value={settings.serpAPI}
          onChange={handleChangeEvent}
        />
      </Stack>
      <Stack gap={2}>
        <Divider>
          <Typography variant="h5">Github</Typography>
        </Divider>
        <Typography variant="body2">
          You can link your Github account so the agent can analyze and modify your repos.
        </Typography>
        <PasswordField
          label="Github Auth Token"
          name="githubAuthToken"
          value={settings.githubAuthToken}
          onChange={handleChangeEvent}
        />
      </Stack>
      <Stack gap={2}>
        <Divider>
          <Typography variant="h5">Google Search API</Typography>
        </Divider>
        <Typography variant="body2">
          The generic Google Google Search API. It doesn't return as much detail as the SerpAPI tool, but it
          ain't bad.
          <br />
          <br />
          This will be used by the by Agents using the Google Search Tool.
        </Typography>
        <SettingsTextField
          autoComplete="off"
          label="Google Search Engine ID"
          name="googleSearchEngineId"
          value={settings.googleSearchEngineId}
          onChange={handleChangeEvent}
        />
        <PasswordField
          label="Google Search API Key"
          name="googleSearchApiKey"
          value={settings.googleSearchApiKey}
          onChange={handleChangeEvent}
        />
      </Stack>
    </Stack>
  )
}
