import { Message as GQLMessage, Mutation } from "@ascully24/alfred"
import { EDIT_MESSAGE, REGENERATE_RESPONSE } from "alfred/graphql/mutations"
import { GET_ALL_MESSAGES, GET_CHAT } from "alfred/graphql/queries"
import { Message } from "alfred/messages/Message"
import { useMutationWithTry } from "graphql/apollo-utils"

export const MessagesWithActions = ({ message }: { message: GQLMessage }) => {
  const [editMessage] = useMutationWithTry<Mutation>(EDIT_MESSAGE)
  const [regenerateResponse] = useMutationWithTry<Mutation>(REGENERATE_RESPONSE)

  const { id: messageId, source, createdAt, chatId } = message
  const editMessageCallback =
    source === "user"
      ? async (newMessage: string) => {
          await editMessage({
            variables: { messageId, text: newMessage },
            refetchQueries: [{ query: GET_CHAT, variables: { id: chatId } }, GET_ALL_MESSAGES],
          })
        }
      : undefined
  const regenerateResponseCallback =
    source === "bot"
      ? async () => {
          await regenerateResponse({
            variables: { messageId },
            refetchQueries: [{ query: GET_CHAT, variables: { id: chatId } }, GET_ALL_MESSAGES],
          })
        }
      : undefined

  return (
    <Message
      message={message}
      timestamp={new Date(createdAt)}
      displayName={source}
      editMessageCallback={editMessageCallback}
      regenerateResponseCallback={regenerateResponseCallback}
    />
  )
}
