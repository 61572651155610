import { Mutation, Query, UpdateSettings, VoiceOptions } from "@ascully24/alfred"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material"
import { StickToTopOnScroll } from "alfred/filter/FilterSettingsToggle"
import { UPDATE_USER_SETTINGS } from "alfred/graphql/mutations"
import { GET_USER_SETTINGS } from "alfred/graphql/queries"
import { useMutationWithToast, useQueryWithLoading } from "graphql/apollo-utils"
import React, { useEffect, useState } from "react"

type SettingsState = {
  openAI: string
  serpAPI: string
  systemMessage: string
  githubAuthToken: string
  googleSearchApiKey: string
  googleSearchEngineId: string
  googleTtsApiKey: string
  preferredVoiceService: VoiceOptions
  googleVoiceId: string
  googleSpeakingRate: number
  googlePitch: number
  googleSpeechToTextClientId: string
  googleSpeechToTextClientEmail: string
  googleSpeechToTextProjectId: string
  googleSpeechToTextPrivateKey: string
  voiceAssistantWakeWords: string[]
  voiceAssistantStopWords: string[]
}

type SettingsStateKey = keyof SettingsState

export const useSettings = () => {
  const { data, loading, error, refetch } = useQueryWithLoading<Query>(GET_USER_SETTINGS)
  const [settingsChanged, setSettingsChanged] = useState(false)
  const [updateSettings] = useMutationWithToast<Mutation>(UPDATE_USER_SETTINGS)

  const [settings, setSettings] = useState<SettingsState>({
    openAI: "",
    serpAPI: "",
    systemMessage: "",
    githubAuthToken: "",
    googleSearchApiKey: "",
    googleSearchEngineId: "",
    googleTtsApiKey: "",
    preferredVoiceService: "google_free_tts",
    googleVoiceId: "",
    googleSpeakingRate: 1,
    googlePitch: 0,
    googleSpeechToTextClientId: "",
    googleSpeechToTextClientEmail: "",
    googleSpeechToTextProjectId: "",
    googleSpeechToTextPrivateKey: "",
    voiceAssistantWakeWords: [],
    voiceAssistantStopWords: [],
  })

  function setSettingsToData(data: Query | undefined) {
    if (data) {
      const settings = data.settings.get
      const googleSpeechSettings = settings.services?.google?.speechToText ?? {}
      setSettings({
        openAI: settings.llms?.openAI?.apiKey ?? "",
        serpAPI: settings.services?.serpAPI?.apiKey ?? "",
        systemMessage: settings.globalChatSettings?.systemMessage ?? "",
        githubAuthToken: settings.services?.github?.authToken ?? "",
        googleSearchApiKey: settings.services?.google?.search?.apiKey ?? "",
        googleSearchEngineId: settings.services?.google?.search?.engineId ?? "",
        googleTtsApiKey: settings.services?.google?.tts?.apiKey ?? "",
        preferredVoiceService: settings.voice?.preferredVoiceService ?? "google_free_tts",
        googleVoiceId: settings.services?.google?.tts?.voiceId ?? "",
        googleSpeakingRate: settings.services?.google?.tts?.speakingRate ?? 1,
        googlePitch: settings.services?.google?.tts?.pitch ?? 0,
        googleSpeechToTextClientId: googleSpeechSettings.clientId ?? "",
        googleSpeechToTextClientEmail: googleSpeechSettings.clientEmail ?? "",
        googleSpeechToTextProjectId: googleSpeechSettings.projectId ?? "",
        googleSpeechToTextPrivateKey: googleSpeechSettings.privateKey ?? "",
        voiceAssistantWakeWords: settings.voiceAssistant?.wakeWords ?? [],
        voiceAssistantStopWords: settings.voiceAssistant?.stopWords ?? [],
      })
    }
  }

  useEffect(() => {
    setSettingsToData(data)
  }, [data])

  const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as SettingsStateKey
    const targetValue = e.target.value

    handleChange(name, targetValue)
  }

  const handleDropdownEvent = (e: SelectChangeEvent) => {
    const name = e.target.name as SettingsStateKey
    const targetValue = e.target.value

    handleChange(name, targetValue)
  }

  const handleChange = (name: SettingsStateKey, value: string | number) => {
    setSettingsChanged(true)

    if (typeof value === "number" && (name === "googleSpeakingRate" || name === "googlePitch")) {
      setSettings({ ...settings, [name]: value })
      return
    }

    if (
      typeof value === "string" &&
      (name === "voiceAssistantWakeWords" || name === "voiceAssistantStopWords")
    ) {
      const currentVASettings = data?.settings.get.voiceAssistant

      const currentList =
        (name === "voiceAssistantWakeWords" ? currentVASettings?.wakeWords : currentVASettings?.stopWords) ??
        []
      setSettings({ ...settings, [name]: [...currentList, value] })
      return
    }

    setSettings({ ...settings, [name]: value })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const input: UpdateSettings = {
      openAI: { apiKey: settings.openAI },
      serpAPI: { apiKey: settings.serpAPI },
      github: { authToken: settings.githubAuthToken },
      google: {
        search: {
          apiKey: settings.googleSearchApiKey,
          engineId: settings.googleSearchEngineId,
        },
        tts: {
          apiKey: settings.googleTtsApiKey,
          voiceId: settings.googleVoiceId,
          speakingRate: settings.googleSpeakingRate,
          pitch: settings.googlePitch,
        },
        speechToText: {
          clientId: settings.googleSpeechToTextClientId,
          clientEmail: settings.googleSpeechToTextClientEmail,
          projectId: settings.googleSpeechToTextProjectId,
          privateKey: settings.googleSpeechToTextPrivateKey,
        },
      },
      globalChatSettings: { systemMessage: settings.systemMessage },
      voice: {
        preferredVoiceService: settings.preferredVoiceService,
      },
      voiceAssistant: {
        wakeWords: settings.voiceAssistantWakeWords,
        stopWords: settings.voiceAssistantStopWords,
      },
    }

    await updateSettings({
      variables: {
        input,
      },
    })
    await refetch()
    setSettingsChanged(false)
  }

  const UpdateSettingsButton = () => (
    <Box>
      {settingsChanged && (
        <StickToTopOnScroll>
          <Stack direction="row" gap={2}>
            <Button onClick={handleSubmit} variant="contained">
              Update Settings
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setSettingsToData(data)
                setSettingsChanged(false)
              }}
            >
              Cancel
            </Button>
          </Stack>
        </StickToTopOnScroll>
      )}
    </Box>
  )

  return {
    settings,
    handleSubmit,
    handleChangeEvent,
    handleDropdownEvent,
    UpdateSettingsButton,
    loading,
    error,
    favouriteGoogleVoiceIds: data?.settings?.get?.services?.google?.tts?.favouriteVoiceIds ?? [],
    availableGoogleVoiceIds: data?.settings.availableGoogleVoiceIds ?? [],
    handleChange,
  }
}

export const PasswordField = ({
  label,
  name,
  value,
  onChange,
  ...props
}: {
  label: string
  name: SettingsStateKey
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
} & TextFieldProps) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <SettingsTextField
      {...props}
      type={showPassword ? "text" : "password"}
      label={label}
      name={name}
      value={value}
      autoComplete="off"
      onChange={onChange}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export const SettingsTextField = ({
  name,
  ...props
}: TextFieldProps & {
  name: SettingsStateKey
}) => {
  return <TextField {...props} name={name} />
}
