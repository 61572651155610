import { Divider, Stack, Typography } from "@mui/material"
import { SettingsTextField, useSettings } from "user/settings/utils"

export const VoiceAssistantSettings = () => {
  const { error, loading, settings, handleChangeEvent, UpdateSettingsButton } = useSettings()
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! {error.message}</div>

  return (
    <Stack
      sx={{
        mb: 5,
        mx: { xs: 3, md: 0 },
      }}
      spacing={5}
    >
      <Stack gap={2}>
        <UpdateSettingsButton />
        <Divider>
          <Typography variant="h5">Voice Assistant</Typography>
        </Divider>
        <Divider>
          <Typography variant="h5">Wake Words</Typography>
        </Divider>
        {settings.voiceAssistantWakeWords.length === 0 && (
          <Typography variant="body2">You have not set any wake words for the voice assistant.</Typography>
        )}
        {settings.voiceAssistantWakeWords.map((word, index) => (
          // View only
          <Typography key={index} variant="body2">
            {word}
          </Typography>
        ))}
        <SettingsTextField
          fullWidth
          label="New wake word"
          name="voiceAssistantWakeWords"
          onChange={handleChangeEvent}
        />
        <Typography variant="body2">These are the words that will wake the voice assistant.</Typography>
      </Stack>
    </Stack>
  )
}
