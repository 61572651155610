import { Add } from "@mui/icons-material"
import { Box, Fab, Stack, TextField } from "@mui/material"
import { FilterSettingsToggle, StickToTopRightOnScroll } from "alfred/filter/FilterSettingsToggle"
import { TagFilterField } from "alfred/filter/TagFilterField"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export type Filters = {
  tags: string[]
  name: string
}

export function AllContextActions({
  onCreateContextClick,
  onFilterChange,
}: {
  onCreateContextClick?: () => Promise<any>
  onFilterChange: (filters: Filters) => void
}) {
  const navigate = useNavigate()
  const [filters, setFilters] = useState<Filters>({ tags: [], name: "" })
  const { tags, name } = filters

  useEffect(() => {
    onFilterChange(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, name])

  return (
    <Box id="all-contexts-actions">
      <StickToTopRightOnScroll>
        <Stack sx={{ width: "100%" }} gap={2} direction="row" justifyContent="space-between">
          <Fab
            onClick={async () => {
              await onCreateContextClick?.()
              navigate("/contexts/create")
            }}
            color="primary"
          >
            <Add />
          </Fab>

          <FilterSettingsToggle>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-evenly"
              alignItems="center" // vertically align the children
              gap={3}
              sx={{
                flexGrow: 1,
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                autoComplete="off"
                label="Name"
                id="name-filter"
                value={filters.name}
                onChange={({ target: { value } }) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      name: value,
                    }
                  })
                }}
              />
              <TagFilterField onChange={(newTags) => setFilters((prev) => ({ ...prev, tags: newTags }))} />
            </Stack>
          </FilterSettingsToggle>
        </Stack>
      </StickToTopRightOnScroll>
    </Box>
  )
}
