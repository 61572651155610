import { useSubscription } from "@apollo/client"
import { Mutation, Query, Subscription } from "@ascully24/alfred"
import { Box, Button, Divider, Paper, Stack, TextField } from "@mui/material"
import { PropelIcon } from "alfred/PropelIcon"
import { CancelAgentRun } from "alfred/agents/CancelAgentRun"
import CreateRunAgentForm from "alfred/agents/CreateAgentRunForm"
import { GET_AGENT_RUN } from "alfred/graphql/queries"
import { AGENT_RUN_SUBSCRIPTION } from "alfred/graphql/subscription"
import { useQueryWithLoading } from "graphql/apollo-utils"
import { useParams } from "react-router-dom"
import { MarkdownViewer } from "utils/MarkdownViewer"
import { useModal } from "utils/Modal"
import { calculateTimeAgo } from "utils/time"
import { AgentRunStatusChip } from "./AgentRunStatusChip"
import { useEffect } from "react"
import { ScrollableBox } from "user/settings/Theme"

export const ViewAgentRun = () => {
  const { id } = useParams()
  const { loading, error, data, updateQuery } = useQueryWithLoading<Query>(GET_AGENT_RUN, {
    variables: { id },
  })

  const {
    showModal,
    Modal: ReRunModal,
    closeModalSuccess,
  } = useModal({
    disableCancel: true,
    disableConfirm: true,
  })

  useSubscription<Subscription>(AGENT_RUN_SUBSCRIPTION, {
    shouldResubscribe: true,
    variables: { id },
    onSubscriptionData: ({ subscriptionData }) => {
      if (!subscriptionData.data) return
      const updatedRunStatus = subscriptionData.data.agentRun
      updateQuery((prev) => {
        return {
          ...prev,
          agentRun: {
            ...prev.agentRun,
            get: updatedRunStatus,
          },
        }
      })
    },
  })

  useEffect(() => {
    return () => {
      closeModalSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!id) return <div>Provide an agent run id</div>
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! ${error.message}</div>

  const runStatus = data?.agentRun.get
  if (!runStatus) return <div>Run Status Not Found</div>

  const defaultPrompt = runStatus.prompt ?? undefined
  const {
    linkedEntity: { id: linkedEntityId, type },
    status,
    prompt,
    createdAt,
    messages,
  } = runStatus

  return (
    <Stack spacing={2}>
      {type === "Agent" && (
        <ReRunModal>
          <CreateRunAgentForm agentId={linkedEntityId} defaultPrompt={defaultPrompt} />
        </ReRunModal>
      )}
      <AgentRunStatusChip status={status} />
      <TextField
        label="Prompt"
        multiline
        maxRows={8}
        value={prompt}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label="Created At"
        value={calculateTimeAgo(createdAt)}
        InputProps={{
          readOnly: true,
        }}
      />

      {["In Progress"].includes(status) && (
        <CancelAgentRun
          id={id}
          onCompleted={function (data: Mutation) {
            updateQuery((prev) => {
              const updatedRun = data?.agentRun.cancel
              if (!updatedRun) {
                return prev
              }

              return {
                ...prev,
                agents: {
                  ...prev.agents,
                  runStatus: updatedRun,
                },
              }
            })
          }}
        />
      )}
      {type === "Agent" && (
        <Button
          onClick={(e) => {
            e.preventDefault()
            showModal()
          }}
          variant="contained"
          color="primary"
        >
          Run Again
        </Button>
      )}
      <Divider>Updates</Divider>
      <Stack
        spacing={2}
        component={ScrollableBox}
        id="scrollableDiv"
        sx={{
          flexGrow: 1,
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {messages.map(({ id, createdAt, message }) => (
          <Paper
            key={id}
            elevation={5}
            sx={{
              p: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <Stack spacing={2}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <MarkdownViewer markdownText={message} />
              </Paper>
              <Box
                // Align right
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {calculateTimeAgo(createdAt)}
                <PropelIcon text={message} />
              </Box>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Stack>
  )
}
