import { MessageTypeFilter, Mutation } from "@ascully24/alfred"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from "@mui/material"
import { RUN_AGENT } from "alfred/graphql/mutations"
import { GET_AGENT_RUN_NAME } from "alfred/graphql/queries"
import { usePropelledText } from "application-state/hooks"
import { useMutationWithToast } from "graphql/apollo-utils"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "utils/Toast"

const CheckboxLabelsArray = ["Final Answer", "Thoughts", "Errors", "Actions", "Tools", "LLM Output"] as const
type CheckboxLabels = (typeof CheckboxLabelsArray)[number]

const MessageTypes: Record<CheckboxLabels, MessageTypeFilter[]> = {
  "Final Answer": ["AgentEnd"],
  Errors: ["ToolError", "LLMError", "ChainError"],
  Actions: ["AgentAction"],
  Tools: ["ToolStart", "ToolError", "ToolEnd"],
  "LLM Output": ["LLMStart", "LLMEnd", "LLMError"],
  Thoughts: ["ChainEnd", "ChainError"],
}

export const CreateAgentRunPage = () => {
  const { agentId } = useParams()

  const defaultPrompt = usePropelledText()

  if (!agentId) return <div>Provide an agent id</div>

  return <CreateRunAgentForm agentId={agentId} defaultPrompt={defaultPrompt} />
}

const CreateRunAgentForm = ({ agentId, defaultPrompt }: { agentId: string; defaultPrompt?: string }) => {
  const {
    prompt,
    setPrompt,
    collapseOpen,
    setCollapseOpen,
    includeMessageLabels,
    handleChange,
    handleSubmit,
    loading,
  } = useCreateAgentRun(agentId, defaultPrompt)

  return (
    <form noValidate onSubmit={(e) => e.preventDefault()} autoComplete="off">
      <Stack spacing={2} sx={{ width: "100%" }}>
        <TextField
          disabled={loading}
          label="Prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          multiline
          rows={8}
        />
        <Box>
          <Box>
            <IconButton onClick={() => setCollapseOpen(!collapseOpen)}>
              {collapseOpen ? <ArrowDropUp /> : <ArrowDropDown />} <Box sx={{ p: 1 }}>Logging</Box>
            </IconButton>
          </Box>
          <Collapse in={collapseOpen}>
            <Stack>
              {CheckboxLabelsArray.map((label) => (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={includeMessageLabels.includes(label)}
                      onChange={handleChange}
                      value={label}
                    />
                  }
                  label={label}
                />
              ))}
            </Stack>
          </Collapse>
        </Box>
        <Button variant="contained" onClick={handleSubmit}>
          Run Agent
        </Button>
      </Stack>
    </form>
  )
}

const useCreateAgentRun = (agentId: string, defaultPrompt: string = "") => {
  const navigate = useNavigate()
  const [runAgent, { loading }] = useMutationWithToast<Mutation>(RUN_AGENT, undefined, {
    successMessage: "Running agent...",
  })
  const [prompt, setPrompt] = useState<string>(defaultPrompt)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [includeMessageLabels, setIncludeMessageLabels] = useState<CheckboxLabels[]>([
    "Final Answer",
    "Errors",
    "Thoughts",
  ])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      const includeMessages = includeMessageLabels.flatMap((label) => MessageTypes[label])
      const response = await runAgent({
        variables: { agentId, prompt, includeMessages },
        refetchQueries: [GET_AGENT_RUN_NAME],
      })
      const agentRunId = response.data?.agentRun.run
      if (!agentRunId) {
        toast.error("Failed to run agent")
        return
      }
      navigate(`/agents/${agentId}/runs/${agentRunId}`, { replace: true })
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as CheckboxLabels
    if (event.target.checked) {
      setIncludeMessageLabels([...includeMessageLabels, value])
    } else {
      setIncludeMessageLabels(includeMessageLabels.filter((item) => item !== value))
    }
  }

  return {
    prompt,
    setPrompt,
    collapseOpen,
    setCollapseOpen,
    includeMessageLabels,
    handleChange,
    handleSubmit,
    loading,
  }
}

export default CreateRunAgentForm
