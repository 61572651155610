import { Stack, Alert, Button } from "@mui/material"
import { backendUri } from "environment"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { fetchWithAuth, headers } from "user/Fetch"

export const EmailTokenConfirmation = () => {
  const token = useParams().token
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [tokenVerificationError, setTokenVerificationError] = useState(null)

  useEffect(() => {
    const verifyToken = async () => {
      const response = await fetchWithAuth(`${backendUri}/register/confirmation/${token}`, {
        method: "POST",
        headers,
      })

      if (!response.ok) {
        await setTokenVerificationError((await response.json()).message ?? "An unknown error has occurred.")
      }
      setLoading(false)
    }

    verifyToken()
  }, [token])

  if (loading) {
    return (
      <div className="mx-auto">
        <Stack>
          <Alert>Verifying token...</Alert>
        </Stack>
      </div>
    )
  }

  if (tokenVerificationError) {
    return (
      <div className="mx-auto">
        <Stack>
          <Alert>{tokenVerificationError}</Alert>
        </Stack>
      </div>
    )
  }

  return (
    <div className="mx-auto">
      <Stack>
        <Alert>You have verified your email and can now login to your account.</Alert>
        <Button
          //   variant="outline-primary"
          className="mx-auto"
          onClick={() => {
            return navigate(`/login`)
          }}
        >
          Login to account
        </Button>
      </Stack>
    </div>
  )
}
