import { useLazyQuery } from "@apollo/client"
import { AutocompleteInput } from "@ascully24/alfred"
import { SUGGEST_QUERY } from "./queries"

export const useSuggestions = () => {
  // useLazyQuery returns a tuple with a query function and an object containing loading, error, and data
  const [getSuggestions, { loading, error, data }] = useLazyQuery(SUGGEST_QUERY)

  // This function will be called with the input to fetch suggestions
  const fetchSuggestions = async (input: AutocompleteInput): Promise<string[]> => {
    const result = await getSuggestions({ variables: { input } })
    return result.data.autocomplete?.suggest?.suggestions || []
  }

  // Extract suggestions from data if available
  const suggestions: string[] = data?.autocomplete?.suggest?.suggestions || []

  return { fetchSuggestions, loading, error, suggestions }
}
