import { Mutation, Query } from "@ascully24/alfred"
import { ContextEditor } from "alfred/context/ContextForm"
import { AddEntitiesForm, LinkedEntitiesDisplay } from "alfred/context/LinkedEntitiesDisplay"
import { CREATE_CONTEXT, UPDATE_CONTEXT } from "alfred/graphql/mutations"
import { GET_CONTEXT, LIST_CONTEXTS } from "alfred/graphql/queries"
import { TabWrapper } from "alfred/messages/TabWrapper"
import { useMutationWithToast, useQueryWithLoading } from "graphql/apollo-utils"
import { useNavigate, useParams } from "react-router-dom"
import { LoadingSpinner } from "utils/LoadingSpinner"

export function CreateContextForm() {
  const navigate = useNavigate()
  const [createNote] = useMutationWithToast<Mutation>(CREATE_CONTEXT, undefined, {
    successMessage: "Context created",
  })

  const handleSubmit = async (name: string) => {
    const { data } = await createNote({ variables: { name }, refetchQueries: [LIST_CONTEXTS] })

    if (data?.context.create.id) {
      navigate(`/contexts/${data.context.create.id}`, { replace: true })
    }
  }

  return <ContextEditor onSubmit={handleSubmit} />
}

export function EditContextForm() {
  const contextId = useParams().id

  const { data, loading, error } = useQueryWithLoading<Query>(GET_CONTEXT, {
    variables: { contextId },
  })

  const [updateContext] = useMutationWithToast<Mutation>(UPDATE_CONTEXT)

  const handleSubmit = async (name: string) => {
    await updateContext({
      variables: { contextId, name },
      refetchQueries: [LIST_CONTEXTS, { query: GET_CONTEXT, variables: { contextId } }],
    })
  }
  const context = data?.context.get

  if (loading) return <LoadingSpinner />
  if (error || !context) return <div>Error</div>

  return (
    <ContextEditor defaultContext={context} onSubmit={handleSubmit}>
      <TabWrapper tabLabels={["Linked Entities", "Add Entities"]}>
        <LinkedEntitiesDisplay context={context} />
        <AddEntitiesForm context={context} />
      </TabWrapper>
    </ContextEditor>
  )
}
