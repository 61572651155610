import { Chat, Mutation } from "@ascully24/alfred"
import { DeleteOutlineOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { DELETE_CHAT } from "alfred/graphql/mutations"
import { GET_CHATS } from "alfred/graphql/queries"
import { useMutationWithToast } from "graphql/apollo-utils"
import { parseErrorMessage } from "graphql/errors"
import { useModal } from "utils/Modal"
import { toast } from "utils/Toast"

export const DeleteChatAction = ({ chat }: { chat: Chat }) => {
  const { id } = chat

  const [deleteChat] = useMutationWithToast<Mutation>(DELETE_CHAT)
  const { showModal, Modal: DeleteModal } = useModal({
    title: "Are you sure?",
    body: "Alfred won't be able to remember anything in this conversation.",
  })

  return (
    <>
      <DeleteModal />
      <IconButton
        color="error"
        onClick={async () => {
          const confirmDelete = await showModal()
          if (!confirmDelete) return
          const { errors = [] } = await deleteChat({
            variables: {
              id: id,
            },
            refetchQueries: [GET_CHATS],
            onError: () => {},
          })

          if (errors.length > 0) {
            toast.error(`Failed to delete chat: ${parseErrorMessage(errors)}`)
          }
        }}
      >
        <DeleteOutlineOutlined />
      </IconButton>
    </>
  )
}
