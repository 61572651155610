import { gql } from "@apollo/client"
import {
  AgentFragment,
  BaseContextFragment,
  ChatFragment,
  ContextFragment,
  MessageFragment,
  NoteFragment,
  SettingsFragment,
  ChatFragmentBase,
  AgentRunBaseFragment,
  AgentRunFragment,
} from "alfred/graphql/fragments"

export const GET_CHAT = gql`
  query ($id: ID!, $tags: [String!], $offset: Int, $limit: Int) {
    chat {
      get(id: $id) {
        ...ChatFragment
        messages(tags: $tags, filter: { offset: $offset, limit: $limit }, sortOrder: DESC) {
          ...MessageFragment
        }
      }
    }
  }
  ${ChatFragment}
  ${MessageFragment}
`

export const GET_CHATS_NAME = "AllChats"
export const GET_CHATS = gql`
  query ${GET_CHATS_NAME}($name: String, $tags: [String!], $offset: Int, $limit: Int) {
    chat{
      list(name: $name, tags: $tags, offset: $offset, limit: $limit) {
        ...ChatFragmentBase
      }
    }
  }

  ${ChatFragmentBase}
`

export const GET_AVAILABLE_CHAT_CONFIGURATIONS = gql`
  query {
    chat {
      availableConfigurations {
        configuration
        tokenLimit
      }
    }
  }
`

export const ALL_MESSAGES_NAME = "AllMessages"
export const GET_ALL_MESSAGES = gql`
  query ${ALL_MESSAGES_NAME}($filter: MessageFilter!, $offset: Int, $limit: Int) {
    message {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...MessageFragment
      }
    }
  }

  ${MessageFragment}
`

export const GET_RELATED_CHATS = gql`
  query RelatedChats($ids: [ID!]) {
    chat {
      list(ids: $ids) {
        ...ChatFragment
      }
    }
  }

  ${ChatFragment}
`

export const GET_TAGS = gql`
  query {
    tag {
      list
    }
  }
`

export const GET_AGENT_QUERY = gql`
  query GetAgent($id: ID!) {
    agents {
      get(id: $id) {
        ...AgentFragment
      }
    }
  }
  ${AgentFragment}
`

export const GET_AVAILABLE_TOOLS_QUERY = gql`
  query GetAvailableTools {
    tools {
      list {
        id
        name
        description
      }
    }
  }
`

export const GET_ALL_AGENTS = gql`
  query GetAgents($name: String, $tags: [String!], $offset: Int, $limit: Int) {
    agents {
      list(name: $name, tags: $tags, offset: $offset, limit: $limit) {
        ...AgentFragment
      }
    }
  }

  ${AgentFragment}
`

export const CREATE_AGENT_MUTATION = gql`
  mutation CreateAgent($input: AgentInput!) {
    agent {
      add(input: $input) {
        ...AgentFragment
      }
    }
  }

  ${AgentFragment}
`

export const GET_USER_SETTINGS = gql`
  query GetSettings {
    settings {
      get {
        ...SettingsFragment
      }
      availableGoogleVoiceIds
    }
  }
  ${SettingsFragment}
`

export const GET_NOTE = gql`
  query GetNote($id: ID!) {
    note {
      get(id: $id) {
        ...NoteFragment
      }
    }
  }
  ${NoteFragment}
`

export const GET_ALL_NOTES_NAME = "GetAllNotes"
export const GET_ALL_NOTES = gql`
  query ${GET_ALL_NOTES_NAME}($filter: NoteFilter) {
    note {
      list(filter: $filter) {
        ...NoteFragment
      }
    }
  }
  ${NoteFragment}
`

export const GET_CONTEXT = gql`
  query GetContext($contextId: ID!) {
    context {
      get(contextId: $contextId) {
        ...ContextFragment
      }
    }
  }
  ${ContextFragment}
`

export const LIST_CONTEXTS = gql`
  query ListContexts($filter: ContextFilter) {
    context {
      list(filter: $filter) {
        ...BaseContextFragment
      }
    }
  }
  ${BaseContextFragment}
`

export const GET_AGENT_RUN_NAME = "GetAgentRun"
export const GET_AGENT_RUN = gql`
  query ${GET_AGENT_RUN_NAME}($id: ID!) {
    agentRun {
      get(id: $id) {
        ...AgentRun
      }
    }
  }
  ${AgentRunFragment}
`

export const LIST_AGENT_RUNS_NAME = "ListAgentRuns"
export const LIST_AGENT_RUNS = gql`
  query ${LIST_AGENT_RUNS_NAME}($entityId: ID!, $entityType: AgentRunEntityType!, $filter: Filter) {
    agentRun {
      list(entityId: $entityId, entityType: $entityType, filter: $filter) {
        ...AgentRunBase
      }
    }
  }
  ${AgentRunBaseFragment}
`
