import { Message, Mutation } from "@ascully24/alfred"
import { IconButtonProps } from "@mui/material"
import { SET_MESSAGE_PINNED } from "alfred/graphql/mutations"
import { GET_ALL_MESSAGES } from "alfred/graphql/queries"
import { useMutationWithTry } from "graphql/apollo-utils"
import { PinIcon } from "utils/pinning/PinIcon"

export const MessagePin = ({ message, ...iconButtonProps }: { message: Message } & IconButtonProps) => {
  const messageId = message.id
  const [setMessagePinned] = useMutationWithTry<Mutation>(SET_MESSAGE_PINNED)

  return (
    <PinIcon
      {...iconButtonProps}
      isDefaultPinned={!!message.metaData?.pinned?.date}
      onPinToggle={async (pinned) => {
        await setMessagePinned({
          variables: { messageId, pinned },
          refetchQueries: [GET_ALL_MESSAGES],
        })
      }}
    />
  )
}
