import { ApolloProvider } from "@apollo/client"
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material"
import { ActionBar } from "action-bar/LeftActionBar"
import { AllChatsPage } from "alfred/AllChatsPage"
import { ChatInstance, ContinuousChat, VoiceAssistantChat } from "alfred/Chat"
import Share from "alfred/Share"
import { AllAgents } from "alfred/agents/AllAgents"
import { CreateAgentForm } from "alfred/agents/CreateAgentForm"
import { CreateAgentRunPage } from "alfred/agents/CreateAgentRunForm"
import { EditAgentForm } from "alfred/agents/EditAgenForm"
import { ViewAgentRun } from "alfred/agents/ViewAgentRun"
import { AllContexts } from "alfred/context/AllContext"
import { CreateContextForm, EditContextForm } from "alfred/context/CreateEditContextForms"
import { AllMessages } from "alfred/messages/AllMessages"
import { PropelDestinationList } from "alfred/messages/PropelDestinationList"
import { ViewMessageAgentRunsPage } from "alfred/messages/ViewMessageAgentRuns"
import { ChatSettingsPage } from "alfred/messages/send/ChatConfiguration"
import { CreateChatForm } from "alfred/messages/send/CreateChatForm"
import AllNotes from "alfred/notes/AllNotes"
import { CreateNoteForm, EditNoteForm } from "alfred/notes/CreateEditNoteForms"
import { AllTags } from "alfred/tag/AllTags"
import { ApplicationStateProvider } from "application-state/ApplicationStateProvider"
import { useIsScrollingDown } from "application-state/hooks"
import "bootstrap/dist/css/bootstrap.min.css"
import { apolloClient } from "graphql/client"
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { EmailTokenConfirmation } from "user/EmailTokenConfirmation"
import { RegistrationEmailSent } from "user/RegistrationEmailSent"
import { GeneralSettings } from "user/settings/GeneralSettings"
import { LLMSettings } from "user/settings/LLMSettings"
import { ServiceSettings } from "user/settings/ServiceSettings"
import { SettingsList } from "user/settings/SettingsList"
import { SpeechToTextSettings } from "user/settings/SpeechToTextSettings"
import { useCustomTheme } from "user/settings/Theme"
import { VoiceAssistantSettings } from "user/settings/VoiceAssistantSettings"
import { VoiceSettings } from "user/settings/VoiceSettings"
import { useMyDetails } from "user/useMyDetails"
import { LoadingSpinner } from "utils/LoadingSpinner"
import { ScreenSizeProps, useScreenSizeValue } from "utils/ScreenSizes"
import "./App.css"
import { UserLoginForm } from "./user/UserLogin"
import { UserRegistrationForm } from "./user/UserRegistration"

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ApplicationStateProvider>
        <PageWrapper />
      </ApplicationStateProvider>
    </ApolloProvider>
  )
}

const PageWrapper = () => {
  const { theme: colourMode } = useCustomTheme()
  const theme = createTheme({
    palette: {
      mode: colourMode,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          #scrollableDiv::-webkit-scrollbar {
            width: 0.4em;
          }
          #scrollableDiv::-webkit-scrollbar-track {
            boxShadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            borderRadius: 4px;
          }
          #scrollableDiv::-webkit-scrollbar-thumb {
            backgroundColor: darkgrey;
            borderRadius: 4px;
          }
        `,
      },
    },
  })

  const isScrollingDown = useIsScrollingDown()

  // const actionBar = (
  //   <Box
  //     sx={{
  //       height: {
  //         xs: isScrollingDown ? "0vh" : "8vh",
  //         md: "8vh",
  //       },
  //       display: {
  //         xs: isScrollingDown === true ? "none" : "block",
  //         md: "block",
  //       },
  //       // TODO: Implement a smoother scroll at some point
  //       // transform: {
  //       //   xs: isScrollingDown ?  "translateY(-100%)": "translateY(0)",
  //       //   md: "translateY(0)",
  //       // },
  //       // transition: "display 0.3s height 0.3s",
  //     }}
  //   >
  //     <ActionBar />
  //   </Box>
  // )
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer limit={3} />
      <Router>
        <Box id="app">
          <ActionBarManager showWhen={{ xs: false, md: true }} />

          <Box
            id="content"
            sx={{
              // Size on Scrolling down
              height: {
                xs: isScrollingDown === undefined ? "auto" : isScrollingDown ? "100vh" : "92vh",
                md: "92vh",
              },
              // Allow for scrolling
              display: "flex",
              flexDirection: "column",
              maxHeight: "100%",

              // Replicate "Container" maxWidth props for lg
              m: "auto",
              width: "100%",
              p: 1,
              maxWidth: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "1280px",
              },
            }}
          >
            <AppRoutes />
          </Box>
          <ActionBarManager showWhen={{ xs: true, md: false }} />
        </Box>
      </Router>
    </ThemeProvider>
  )
}

function AppRoutes() {
  const { pathname } = useLocation()
  return (
    <Routes>
      <Route path="/register" element={<UserRegistrationForm />} />
      <Route path="/register/email-sent" element={<RegistrationEmailSent />} />
      <Route path="/register/confirmation/:token" element={<EmailTokenConfirmation />} />
      <Route path="/login" element={<UserLoginForm />} />
      <Route path="*" element={<div>Not Found - {pathname} </div>} />
      <Route path="/propel" element={<PropelDestinationList />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<ContinuousChat />} />

        <Route path="/tags" element={<AllTags />} />

        <Route path="/messages" element={<AllMessages />} />
        <Route path="/messages/:id/agent-runs" element={<ViewMessageAgentRunsPage />} />

        <Route path="/chats" element={<AllChatsPage />} />
        <Route path="/chats/create" element={<CreateChatForm />} />
        <Route path="/chats/:id" element={<ChatInstance />} />
        <Route path="/chats/:id/settings" element={<ChatSettingsPage />} />

        <Route path="/voice-assistant" element={<VoiceAssistantChat />} />

        <Route path="/notes" element={<AllNotes />} />
        <Route path="/notes/:id" element={<EditNoteForm />} />
        <Route path="/notes/create" element={<CreateNoteForm />} />

        <Route path="/contexts" element={<AllContexts />} />
        <Route path="/contexts/:id" element={<EditContextForm />} />
        <Route path="/contexts/create" element={<CreateContextForm />} />

        <Route path="/agents" element={<AllAgents />} />
        <Route path="/agents/:id" element={<EditAgentForm />} />
        <Route path="/agents/:agentId/runs/:id" element={<ViewAgentRun />} />
        <Route path="/agents/:agentId/runs/create" element={<CreateAgentRunPage />} />
        <Route path="/agents/create" element={<CreateAgentForm />} />

        <Route path="/shared-content" element={<Share />} />

        <Route path="/settings" element={<SettingsList />} />
        <Route path="/settings/general" element={<GeneralSettings />} />
        <Route path="/settings/llms" element={<LLMSettings />} />
        <Route path="/settings/services" element={<ServiceSettings />} />
        <Route path="/settings/voice" element={<VoiceSettings />} />
        <Route path="/settings/speech-to-text" element={<SpeechToTextSettings />} />
        <Route path="/settings/voice-assistant" element={<VoiceAssistantSettings />} />
      </Route>
    </Routes>
  )
}

export const ProtectedRoute = ({ children }: { children?: any }) => {
  const { loading, user, error } = useMyDetails()
  const location = useLocation()
  const redirect = encodeURIComponent(location.pathname)

  if (loading) return <LoadingSpinner />

  if (!user || error) {
    return <Navigate to={`/login?redirect=${redirect}`} replace />
  }

  return children ? children : <Outlet />
}

const ActionBarManager: React.FC<{ showWhen: ScreenSizeProps<boolean> }> = ({ showWhen }) => {
  const show = useScreenSizeValue(showWhen) ?? false
  const isScrollingDown = useIsScrollingDown()

  if (!show) return null

  return (
    <Box
      sx={{
        height: {
          xs: isScrollingDown ? "0vh" : "8vh",
          md: "8vh",
        },
        display: {
          xs: isScrollingDown === true ? "none" : "block",
          md: "block",
        },
        // TODO: Implement a smoother scroll at some point
        // transform: {
        //   xs: isScrollingDown ?  "translateY(-100%)": "translateY(0)",
        //   md: "translateY(0)",
        // },
        // transition: "display 0.3s height 0.3s",
      }}
    >
      <ActionBar />
    </Box>
  )
}

export default App
