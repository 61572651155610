import { Mutation, VoiceOptions } from "@ascully24/alfred"
import { PlayArrow, Star, StarBorder } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { PREVIEW_GOOGLE_TTS, UPDATE_FAVOURITE_VOICE_NAMES } from "alfred/graphql/mutations"
import { GET_USER_SETTINGS } from "alfred/graphql/queries"
import { useAudioPlayer } from "alfred/useAudioPlayer"
import { useMutationWithToast } from "graphql/apollo-utils"
import { PasswordField, useSettings } from "user/settings/utils"

const availablePreferredVoiceServices: VoiceOptions[] = ["google_free_tts", "google_tts"]
const voiceServiceNames: Record<VoiceOptions, string> = {
  google_free_tts: "Google Free TTS",
  google_tts: "Google TTS",
}

export const VoiceSettings = () => {
  const {
    error,
    loading,
    settings,
    handleChangeEvent,
    handleDropdownEvent,
    UpdateSettingsButton,
    handleChange,
    favouriteGoogleVoiceIds,
    availableGoogleVoiceIds,
  } = useSettings()

  const [updateFavouriteVoiceIds] = useMutationWithToast<Mutation>(UPDATE_FAVOURITE_VOICE_NAMES)
  const [previewGoogleTts] = useMutationWithToast<Mutation>(PREVIEW_GOOGLE_TTS)
  const { setVoiceUrls } = useAudioPlayer(true)

  const toggleFavourite = async (name: string) => {
    const newFavourites = favouriteGoogleVoiceIds.includes(name)
      ? favouriteGoogleVoiceIds.filter((favourite) => favourite !== name)
      : [...favouriteGoogleVoiceIds, name]

    await updateFavouriteVoiceIds({
      variables: {
        voiceIds: newFavourites,
      },
      refetchQueries: [{ query: GET_USER_SETTINGS }],
    })
  }

  const sortedOptions = [...availableGoogleVoiceIds].sort((a, b) => {
    if (favouriteGoogleVoiceIds.includes(a) && !favouriteGoogleVoiceIds.includes(b)) return -1
    if (!favouriteGoogleVoiceIds.includes(a) && favouriteGoogleVoiceIds.includes(b)) return 1
    return a.localeCompare(b)
  })

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! {error.message}</div>

  return (
    <Stack
      sx={{
        mb: 5,
        mx: { xs: 3, md: 0 },
      }}
      spacing={5}
    >
      <UpdateSettingsButton />
      <Stack gap={2}>
        <Divider>
          <Typography variant="h5">Preferred Voice API</Typography>
        </Divider>
        <Typography variant="body2">The voice you would like to use.</Typography>

        <Select
          sx={{ width: "100%" }}
          name="preferredVoiceService"
          value={settings.preferredVoiceService}
          onChange={handleDropdownEvent}
        >
          {availablePreferredVoiceServices.map((voiceService) => {
            return (
              <MenuItem
                id={`model-select-voice-service-${voiceService}`}
                key={voiceService}
                value={voiceService}
              >
                {voiceServiceNames[voiceService]}
              </MenuItem>
            )
          })}
        </Select>
      </Stack>
      <Stack gap={2}>
        <Stack gap={2}>
          <Divider>
            <Typography variant="h5">Google Text To Speech</Typography>
          </Divider>
          <Typography variant="body2">This is used by chats to convert text to speech.</Typography>
          <PasswordField
            label="Google Text to Speech API Key"
            name="googleTtsApiKey"
            value={settings.googleTtsApiKey}
            onChange={handleChangeEvent}
          />
        </Stack>
        <Divider>
          <Typography variant="h5">Google Voice Configuration</Typography>
        </Divider>
        <IconButton
          onClick={async (e) => {
            const response = await previewGoogleTts({
              variables: {
                voiceId: settings.googleVoiceId,
                speakingRate: settings.googleSpeakingRate,
                pitch: settings.googlePitch,
              },
            })

            response.data?.settings?.previewGoogleTts &&
              setVoiceUrls([response.data?.settings?.previewGoogleTts])

            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <PlayArrow />
        </IconButton>
        <Typography variant="body2">The voice you would like the Google TTS to use.</Typography>
        <Autocomplete
          value={settings.googleVoiceId}
          onChange={(_e, newValue) => {
            handleChange("googleVoiceId", newValue ?? "")
          }}
          options={sortedOptions}
          renderInput={(params) => <TextField {...params} label="Google Voice" variant="outlined" />}
          renderOption={(props, option) => (
            <li {...props}>
              <Stack direction="row" gap={2}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleFavourite(option)
                  }}
                >
                  {favouriteGoogleVoiceIds.includes(option) ? (
                    <Star color="primary" />
                  ) : (
                    <StarBorder color="primary" />
                  )}
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>{option}</Box>
              </Stack>
            </li>
          )}
        />

        <Stack direction={{ xs: "column", sm: "row" }} gap={3} sx={{ width: "100%" }} alignItems="center">
          <Box
            sx={{
              width: { xs: "100%", sm: "50%" },
              m: 1,
            }}
          >
            <Typography variant="h6">Speed</Typography>
            <Stack spacing={1}>
              <Typography variant="body2">{settings.googleSpeakingRate.toFixed(2)}x</Typography>

              <Slider
                aria-label="Speech Speed"
                value={settings.googleSpeakingRate}
                step={0.05}
                min={0.25}
                max={4}
                onChange={async (_e, value) => {
                  const speechSpeed = value as number
                  handleChange("googleSpeakingRate", speechSpeed)
                }}
                marks={[
                  {
                    value: 0.25,
                    label: "Slow",
                  },
                  {
                    value: 1,
                    label: "Balanced",
                  },
                  {
                    value: 4,
                    label: "Fast",
                  },
                ]}
              />
            </Stack>
          </Box>

          <Box
            sx={{
              width: { xs: "100%", sm: "50%" },
              m: 1,
            }}
          >
            <Typography variant="h6">Pitch</Typography>
            <Stack spacing={1}>
              <Typography variant="body2">{settings.googlePitch}</Typography>
              <Slider
                aria-label="Pitch"
                value={settings.googlePitch}
                min={-20}
                max={20}
                onChange={async (_e, value) => {
                  const speechSpeed = value as number
                  handleChange("googlePitch", speechSpeed)
                }}
                marks={[
                  {
                    value: -20,
                    label: "Low",
                  },
                  {
                    value: 0,
                    label: "Balanced",
                  },
                  {
                    value: 20,
                    label: "High",
                  },
                ]}
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
