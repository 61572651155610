import { Maybe } from "@ascully24/alfred"
import { AddCircle, Check, Close } from "@mui/icons-material"
import { Autocomplete, IconButton, Popper, TextField, Tooltip } from "@mui/material"
import { Stack } from "@mui/system"
import { useState } from "react"
import { UserInputEvent } from "utils/chips/types"
import { toast } from "utils/Toast"

export type AddChipCallback<T> = (option: T) => Promise<boolean>
export type UnknownOptionCallback = (option: string) => Promise<boolean>
export type GetOptionLabelCallback<T> = (option: T) => string

export function AddItemAutoComplete<T>({
  onAdd,
  autoCompleteValues,
  getOptionLabel,
  onUnknownOption,
  inputPlaceholder,
  addPlaceholder,
  asColumn = false,
}: {
  autoCompleteValues: T[]
  inputPlaceholder: string
  addPlaceholder: string
  onAdd: AddChipCallback<T>
  onUnknownOption: UnknownOptionCallback
  getOptionLabel: GetOptionLabelCallback<T>
  asColumn?: boolean
}) {
  const [showInput, setShowInput] = useState(false)
  const [inputValue, setInputValue] = useState<Maybe<T> | string>("")

  const handleAddClick = (event: UserInputEvent) => {
    event.stopPropagation()
    setShowInput(true)
  }

  const handleCancelClick = (event: UserInputEvent) => {
    event.stopPropagation()
    setShowInput(false)
    setInputValue("")
  }

  const handleSubmit = async (event: UserInputEvent, inputValue: Maybe<T> | string) => {
    event.stopPropagation()
    if (!inputValue) {
      toast.error("Empty not allowed")
      return
    }

    const run = async () => {
      if (typeof inputValue === "string") {
        return onUnknownOption?.(inputValue as string)
      }

      return await onAdd?.(inputValue)
    }

    const success = await run()
    if (!success) {
      return
    }
    setInputValue("")
  }

  return (
    <>
      {showInput ? (
        <Stack
          direction={{
            xs: "column",
            sm: asColumn ? "column" : "row",
          }}
          gap={1}
          alignItems="center"
        >
          <Autocomplete
            value={inputValue}
            onChange={(_e, value) => {
              if (!value) return
              setInputValue(value)
            }}
            freeSolo
            onClick={(e) => e.stopPropagation()}
            onKeyUpCapture={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e, inputValue)
              }

              if (e.key === "Escape") {
                handleCancelClick(e)
              }
            }}
            PopperComponent={(props) => (
              <Popper
                {...props}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            )}
            options={autoCompleteValues}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option
              }
              return getOptionLabel(option)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={inputValue}
                size="small"
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
                onClick={(e) => e.stopPropagation()}
                placeholder={inputPlaceholder}
                autoFocus={true}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    width: "100%",
                    height: "0.8rem",
                    fontSize: "0.8rem",
                  },
                }}
                sx={{
                  fontSize: "0.8rem",
                }}
              />
            )}
          />
          <Stack
            direction="row"
            alignItems="center"
            gap={{
              xs: 4,
              sm: 2,
            }}
          >
            <Check
              onClick={(e) => handleSubmit(e, inputValue)}
              sx={{
                cursor: "pointer",
                color: "green",
                fontSize: { xs: "1.8rem", sm: "1.5rem" },
              }}
            />
            <Close
              onClick={handleCancelClick}
              sx={{
                cursor: "pointer",
                color: "red",
                fontSize: { xs: "1.8rem", sm: "1.5rem" },
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <Tooltip title={addPlaceholder}>
          <IconButton
            onAbort={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            size="small"
            onClick={handleAddClick}
            sx={{
              p: 0,
              m: 0,
            }}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}
