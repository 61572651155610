import { Box, Fab, TextField, Tooltip } from "@mui/material"
import { Stack } from "@mui/system"
import { useEffect, useState } from "react"
import { FilterSettingsToggle, StickToTopOnScroll } from "./filter/FilterSettingsToggle"
import { TagFilterField } from "./filter/TagFilterField"
import { Add, AllInclusive } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

export type AllChatActionsProps = {
  onFilterChange: (filters: Filters) => void
  onCreateChatClick?: () => Promise<void>
  onContinuousChatClick?: () => Promise<any>
}

export function AllChatActions({
  onFilterChange,
  onCreateChatClick,
  onContinuousChatClick,
}: AllChatActionsProps) {
  const navigate = useNavigate()
  const [filters, setFilters] = useState<Filters>({ tags: [], name: "" })
  const { tags, name } = filters

  useEffect(() => {
    onFilterChange(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, name])

  return (
    <Box id="all-chat-actions">
      <StickToTopOnScroll>
        <Stack sx={{ width: "100%" }} gap={2} direction="row" justifyContent="space-between">
          <Tooltip title={"Create new chat"} placement="right">
            <Fab
              color="primary"
              onClick={async () => {
                await onCreateChatClick?.()
                navigate("/chats/create")
              }}
            >
              <Add />
            </Fab>
          </Tooltip>
          <FilterSettingsToggle>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-evenly"
              alignItems="center" // vertically align the children
              gap={3}
              sx={{
                flexGrow: 1,
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                autoComplete="off"
                label="Name"
                id="name-filter"
                value={filters.name}
                onChange={({ target: { value } }) => {
                  setFilters((prev) => {
                    return {
                      ...prev,
                      name: value,
                    }
                  })
                }}
              />
              <TagFilterField onChange={(newTags) => setFilters((prev) => ({ ...prev, tags: newTags }))} />
            </Stack>
          </FilterSettingsToggle>
          <Tooltip title={"Infinite Chat"} placement="right">
            <Fab
              color="primary"
              onClick={async () => {
                await onContinuousChatClick?.()
                navigate("/")
              }}
            >
              <AllInclusive />
            </Fab>
          </Tooltip>
        </Stack>
      </StickToTopOnScroll>
    </Box>
  )
}

export type Filters = {
  name: string
  tags: string[]
}
