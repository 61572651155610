import { Agent, AgentInput, Model, Mutation } from "@ascully24/alfred"
import { PlayArrow } from "@mui/icons-material"
import { Button, Container, Fab, FormControl, MenuItem, Select, Stack, TextField } from "@mui/material"
import { AgentRunsList } from "alfred/agents/AgentRunsList"
import { AgentTools } from "alfred/agents/AgentTools"
import { StickToBottomCenter } from "alfred/filter/FilterSettingsToggle"
import { EDIT_AGENT } from "alfred/graphql/mutations"
import { CREATE_AGENT_MUTATION, GET_AGENT_QUERY, GET_ALL_AGENTS } from "alfred/graphql/queries"
import { usePropelledText } from "application-state/hooks"
import { useMutationWithToast } from "graphql/apollo-utils"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { getToolId } from "./getToolId"

export interface AgentProps {
  defaultAgent?: Agent
}

const ModelMenuItems: { model: Model; display: string }[] = [
  { model: "GPT_3", display: "GPT-3" },
  { model: "GPT_4", display: "GPT-4" },
  { model: "GPT_4_TURBO", display: "GPT-4 GPT_4_Turbo" },
]

export function CreateAgentForm({ defaultAgent }: AgentProps) {
  const navigate = useNavigate()
  const propelledMessage = usePropelledText()

  const [createAgent, { loading: createLoading, error: createError }] =
    useMutationWithToast<Mutation>(CREATE_AGENT_MUTATION)
  const [editAgent, { loading: editLoading, error: editError }] = useMutationWithToast(EDIT_AGENT)

  const initialAgentInput: AgentInput = {
    name: defaultAgent?.name ?? "",
    description: defaultAgent?.description ?? "",
    model: defaultAgent?.model ?? "GPT_3",
    promptSuffix: defaultAgent?.promptSuffix ?? propelledMessage ?? "",
    tools: defaultAgent?.tools.map((tool) => tool.id) ?? [],
  }

  const agentId = defaultAgent?.id
  const [agentInput, setAgentInput] = useState<AgentInput>(initialAgentInput)

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (defaultAgent) {
      await editAgent({
        variables: { id: agentId, input: agentInput },
        refetchQueries: [{ query: GET_AGENT_QUERY, variables: { id: agentId } }],
      })
      return
    }

    const response = await createAgent({ variables: { input: agentInput }, refetchQueries: [GET_ALL_AGENTS] })
    response.data?.agent?.add?.id && navigate(`/agents/${response.data.agent.add.id}`)
  }

  const loading = createLoading || editLoading
  const error = createError || editError

  return (
    <Container
      sx={{
        mt: 1,
      }}
    >
      <Stack gap={2}>
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
          }}
          autoComplete="off"
        >
          {agentId && (
            <>
              <StickToBottomCenter>
                <Fab
                  onClick={() => {
                    navigate(`/agents/${agentId}/runs/create`)
                  }}
                  color="success"
                  variant="extended"
                >
                  <PlayArrow />
                </Fab>
              </StickToBottomCenter>
            </>
          )}
          <Stack spacing={2}>
            <TextField
              label="Name"
              variant="outlined"
              autoComplete="off"
              required
              value={agentInput.name}
              onChange={(event) => setAgentInput({ ...agentInput, name: event.target.value })}
            />

            <TextField
              label="Description"
              variant="outlined"
              autoComplete="off"
              value={agentInput.description}
              onChange={(event) => setAgentInput({ ...agentInput, description: event.target.value })}
            />

            <FormControl variant="outlined">
              <Select
                value={agentInput.model}
                onChange={(event) => setAgentInput({ ...agentInput, model: event.target.value as Model })}
              >
                {ModelMenuItems.map(({ model, display }) => (
                  <MenuItem key={model} value={model}>
                    {display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <AgentTools
              defaultSelectedTools={agentInput?.tools ?? []}
              onChange={(newTools) => setAgentInput({ ...agentInput, tools: newTools.map(getToolId) })}
            />

            <TextField
              label="System Message"
              autoComplete="off"
              variant="outlined"
              multiline
              rows={8}
              value={agentInput.promptSuffix}
              onChange={(event) => setAgentInput({ ...agentInput, promptSuffix: event.target.value })}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleSubmit}
            >
              {defaultAgent ? "Update Agent" : "Create Agent"}
            </Button>

            {error && <p>Error: {error.message}</p>}
          </Stack>
        </form>
        {agentId && (
          <div>
            <AgentRunsList entityId={agentId} entityType="Agent" />
          </div>
        )}
      </Stack>
    </Container>
  )
}
