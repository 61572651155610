import { List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import { Link } from "react-router-dom"

export const SettingsList = () => {
  return (
    <List>
      <ListItem>
        <ListItemButton component={Link} to={`/settings/general`} role={undefined}>
          <ListItemText
            primary="General"
            secondary="Tweak some broad settings such as chat system messages."
          />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton component={Link} to={`/settings/llms`} role={undefined}>
          <ListItemText
            primary="Large Language Models"
            secondary="Manage your OpenAI API key and other settings that control how LLMs are used."
          />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton component={Link} to={`/settings/services`} role={undefined}>
          <ListItemText
            primary="Services"
            secondary="Customise various services such as search APIs used by chatbots and agents."
          />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton component={Link} to={`/settings/voice`} role={undefined}>
          <ListItemText primary="Voice" secondary="Configure how your bot will speak to you." />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton component={Link} to={`/settings/speech-to-text`} role={undefined}>
          <ListItemText
            primary="Speech to Text"
            secondary="Voice to text functionality so you can talk to your bot."
          />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton component={Link} to={`/settings/voice-assistant`} role={undefined}>
          <ListItemText primary="Voice Assistant" secondary="Configure your voice assistant settings." />
        </ListItemButton>
      </ListItem>
    </List>
  )
}
