import { DarkMode, LightMode } from "@mui/icons-material"
import { Box, Stack, Switch } from "@mui/material"
import { styled } from "@mui/system"
import useLocalStorage from "use-local-storage"

export const useCustomTheme = () => {
  const [theme, setTheme] = useLocalStorage<Theme>("theme", "light")

  return {
    theme,
    setTheme,
  }
}
type Theme = "light" | "dark"

export const ScrollableBox = styled(Box)(({ theme }) => {
  const isDark = theme.palette.mode === "dark"
  const boxShadow = isDark ? "inset 0 0 6px rgba(0, 0, 0, 0.3)" : undefined
  const backgroundColor = isDark ? "darkgrey" : "lightgrey"

  return {
    "::-webkit-scrollbar-track": {
      boxShadow,
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor,
    },
  }
})

export const DarkThemeSwitch = () => {
  const { theme, setTheme } = useCustomTheme()

  const darkModeEnabled = theme === "dark"
  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const theme = event.target.checked ? "dark" : "light"
    setTheme(theme)
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Switch checked={darkModeEnabled} onChange={handleThemeChange} />
      {theme === "dark" ? (
        <DarkMode sx={{ color: "rgba(255, 255, 255, 0.6)" }} />
      ) : (
        <LightMode sx={{ color: "rgba(0, 0, 0, 0.6)" }} />
      )}
    </Stack>
  )
}
