import { Agent, Mutation } from "@ascully24/alfred"
import { SET_AGENT_PINNED } from "alfred/graphql/mutations"
import { GET_AGENT_QUERY, GET_ALL_AGENTS } from "alfred/graphql/queries"
import { useMutationWithTry } from "graphql/apollo-utils"
import { PinIcon } from "utils/pinning/PinIcon"

export const AgentPin = ({ agent }: { agent: Agent }) => {
  const id = agent.id
  const [setAgentPinned] = useMutationWithTry<Mutation>(SET_AGENT_PINNED)

  return (
    <PinIcon
      isDefaultPinned={!!agent.metaData?.pinned?.date}
      onPinToggle={async (pinned) => {
        await setAgentPinned({
          variables: { id, pinned },
          refetchQueries: [GET_AGENT_QUERY, GET_ALL_AGENTS],
        })
      }}
    />
  )
}
