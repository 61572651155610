import { ChatSettingsInput, Mutation } from "@ascully24/alfred"
import { Box } from "@mui/material"
import { CREATE_CHAT, SEND_MESSAGE } from "alfred/graphql/mutations"
import { GET_ALL_MESSAGES, GET_CHATS } from "alfred/graphql/queries"
import { useMutationWithToast } from "graphql/apollo-utils"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ChatConfiguration, DEFAULT_CHAT_SETTINGS } from "./ChatConfiguration"
import { SendMessageField } from "./SendMessageField"

export const CreateChatField = ({
  label = "Start a new chat",
  defaultText = "",
  onSend,
}: {
  label?: string
  defaultText?: string
  onSend?: () => Promise<any>
}) => {
  const navigate = useNavigate()
  const [sendMessage] = useMutationWithToast<Mutation>(SEND_MESSAGE, undefined, {
    successMessage: null,
  })
  const [createChatMutation] = useMutationWithToast<Mutation>(CREATE_CHAT, undefined, {
    successMessage: null,
    loadingMessage: "Starting up the conversation...",
  })

  const [chatSettings, setChatSettings] = useState<ChatSettingsInput>({
    ...DEFAULT_CHAT_SETTINGS,
  })

  const sendMessageCallback = async (text: string) => {
    const { data: createChatData } = await createChatMutation({
      variables: { settings: chatSettings },
      refetchQueries: [GET_CHATS],
    })

    const chatId = createChatData?.chat?.create?.id
    if (!chatId) {
      return false
    }

    const { data: sendMessageData } = await sendMessage({
      variables: { chatId, text },
      refetchQueries: [GET_ALL_MESSAGES],
    })

    const sendMessageResponse = sendMessageData?.chat?.sendMessage
    if (!sendMessageResponse) {
      return false
    }

    await onSend?.()
    navigate(`/chats/${chatId}`, { replace: true })
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "88vh",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      />
      <SendMessageField
        defaultText={defaultText}
        label={label}
        submitMessage={sendMessageCallback}
        displayConfiguration={true}
      >
        <ChatConfiguration
          onChange={async (inputConfig) => {
            setChatSettings((prev) => ({
              ...prev,
              ...inputConfig,
            }))
          }}
        />
      </SendMessageField>
    </Box>
  )
}
