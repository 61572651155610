import { ChatSettings, ChatSettingsInput, ChatType, Configuration, Context, Query } from "@ascully24/alfred"
import { ColorLens, Loop, PsychologyAlt, RecordVoiceOver } from "@mui/icons-material"
import {
  Alert,
  Box,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  styled,
} from "@mui/material"
import { AgentTools } from "alfred/agents/AgentTools"
import { getToolId } from "alfred/agents/getToolId"
import { getConfigurationDisplayName } from "alfred/configuration"
import { GET_AVAILABLE_CHAT_CONFIGURATIONS, LIST_CONTEXTS } from "alfred/graphql/queries"
import { useChat, useChatSettingsUpdate } from "alfred/hooks"
import { WayfAutoComplete } from "alfred/messages/WayAutoComplete"
import { useQueryWithLoading } from "graphql/apollo-utils"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { LoadingSpinner } from "utils/LoadingSpinner"

export const DEFAULT_CHAT_SETTINGS: ChatSettingsInput = {
  configuration: "ChatGPT_4_Turbo",
  chatType: "conversation",
  creativityScale: 0.5,
  memoryScale: 0,
  maxIterations: 5,
}

const MEMORY_SCALE_STEPS: Record<Configuration, number> = {
  ChatGPT_3: 0.1,
  ChatGPT_4: 0.1,
  ChatGPT_4_Turbo: 0.003,
}

export function ChatSettingsPage() {
  const chatId = useParams<{ id: string }>().id
  const { updateChatSettingsDebounce } = useChatSettingsUpdate(chatId ?? undefined)
  const { chat, loading } = useChat({ chatId })

  useEffect(() => {
    return () => {
      updateChatSettingsDebounce.flush()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <LoadingSpinner />

  if (!chatId) return <Alert severity="error">Chat not found.</Alert>

  return (
    <Box sx={{ mr: 3, mt: 2 }}>
      <ChatConfiguration defaultChatSettings={chat?.settings} onChange={updateChatSettingsDebounce} />
    </Box>
  )
}

export function ChatConfiguration({
  onChange,
  defaultChatSettings,
  isVoiceAssistant = false,
}: {
  onChange: (configurationState: ChatSettingsInput) => Promise<void>
  defaultChatSettings?: ChatSettings
  isVoiceAssistant?: boolean
}) {
  const { data: contextQueryData } = useQueryWithLoading<Query>(LIST_CONTEXTS, {
    variables: {
      filter: {
        queryParameters: {
          filter: {
            offset: 0,
            limit: 100,
          },
        },
      },
    },
  })

  const availableContexts = contextQueryData?.context.list ?? []

  const [chatSettingsInput, setChatSettingsInput] = useState<ChatSettingsInput>({
    ...(defaultChatSettings ?? DEFAULT_CHAT_SETTINGS),
    contextIds: defaultChatSettings?.contexts?.map((c) => c.id) ?? [],
    tools: defaultChatSettings?.tools.map(getToolId) ?? [],
  })

  const updateChatSettings = async (newSettings: ChatSettingsInput) => {
    setChatSettingsInput((prev) => ({
      ...prev,
      ...newSettings,
    }))
    await onChange(newSettings)
  }

  const { data: { chat } = {} } = useQueryWithLoading<Query>(GET_AVAILABLE_CHAT_CONFIGURATIONS)
  const availableChatConfigurations = chat?.availableConfigurations ?? []

  const availableConfigurationTokenLimits = availableChatConfigurations.reduce((acc, curr) => {
    acc[curr.configuration] = curr.tokenLimit
    return acc
  }, {} as Record<Configuration, number>)

  const { configuration, creativityScale, memoryScale, chatType, name } = chatSettingsInput

  return (
    <Stack spacing={4} sx={{ mb: 1 }}>
      {!isVoiceAssistant && (
        <TextField
          id="chat-name"
          label="Chat Name"
          value={name}
          onChange={(e) => updateChatSettings({ name: e.target.value })}
        />
      )}

      {availableChatConfigurations.length > 1 && (
        <Select
          sx={{ width: "100%" }}
          value={configuration}
          onChange={async (e) => {
            const configuration = e.target.value as Configuration
            updateChatSettings({ configuration })
          }}
        >
          {availableChatConfigurations.map((availableConfiguration) => {
            const configuration = availableConfiguration.configuration
            return (
              <MenuItem id={`model-select-${configuration}`} key={configuration} value={configuration}>
                {getConfigurationDisplayName(configuration)}
              </MenuItem>
            )
          })}
        </Select>
      )}

      <Stack spacing={2} direction="row">
        <Select
          sx={{ width: "100%" }}
          value={chatType}
          onChange={async (e) => {
            const value = e.target.value
            const chatType = value as ChatType
            updateChatSettings({ chatType })
          }}
        >
          {availableChatTypes.map((chatType) => {
            const displayChatType = chatType ?? "general"
            return (
              <MenuItem id={`model-select-${displayChatType}`} key={displayChatType} value={displayChatType}>
                {displayChatType.charAt(0).toUpperCase() + displayChatType.slice(1)}
              </MenuItem>
            )
          })}
        </Select>
        {!isVoiceAssistant && (
          <Stack spacing={1} direction="row" alignItems="center">
            <Switch
              checked={chatSettingsInput.isVoiceEnabled ?? false}
              onChange={async (e) => {
                const isVoiceEnabled = e.target.checked
                updateChatSettings({ isVoiceEnabled })
              }}
            />
            <Tooltip title={chatSettingsInput.isVoiceEnabled ? "Voice Enabled" : "Voice Disabled"}>
              <RecordVoiceOver color={chatSettingsInput.isVoiceEnabled ? "primary" : "disabled"} />
            </Tooltip>
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{
          width: "100%",
          alignItems: "center",
        }}
        direction="row"
        spacing={2}
      >
        <Tooltip title="Creativity">
          <ColorLens color="primary" />
        </Tooltip>
        <StyledSlider
          aria-label="Creativity"
          valueLabelDisplay="auto"
          // defaultValue={0.5}
          value={creativityScale ?? 0.5}
          onChange={async (e, value) => {
            const creativityScale = value as number
            updateChatSettings({ creativityScale })
          }}
          step={0.1}
          min={0}
          max={1}
          marks={[
            {
              value: 0,
              label: "Predictable",
            },
            {
              value: 0.5,
              label: "Balanced",
            },
            {
              value: 1,
              label: "Creative",
            },
          ]}
        />
      </Stack>

      <Stack
        sx={{
          width: "100%",
          alignItems: "center",
        }}
        direction="row"
        spacing={2}
      >
        <Tooltip title="Memory">
          <PsychologyAlt color="primary" />
        </Tooltip>
        <StyledSlider
          aria-label="Memory"
          value={memoryScale ?? 0.5}
          step={configuration ? MEMORY_SCALE_STEPS[configuration] : 0.1}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) =>
            `${(value * 100).toFixed(1)}% / ${
              configuration
                ? (
                    availableConfigurationTokenLimits[configuration] *
                    (memoryScale ?? 0.5) *
                    0.8
                  ).toLocaleString(undefined, { maximumFractionDigits: 0 })
                : 0
            }`
          }
          min={0}
          max={1}
          onChange={async (e, value) => {
            const memoryScale = value as number
            updateChatSettings({ memoryScale })
          }}
          marks={[
            {
              value: 0,
              label: "Off",
            },
            {
              value: 0.1,
              label: "Short",
            },
            {
              value: 0.5,
              label: "Balanced",
            },
            {
              value: 1,
              label: "Long",
            },
          ]}
        />
      </Stack>

      <ContextSelection
        defaultSelected={defaultChatSettings?.contexts ?? []}
        availableContexts={availableContexts}
        onChangeContextIds={(contextIds) => {
          const currentContextIds = chatSettingsInput.contextIds ?? []
          const chatSettingsTheSame =
            currentContextIds?.every((currentId) => contextIds.some((newId) => newId === currentId)) &&
            contextIds.every((newId) => currentContextIds?.some((currentId) => currentId === newId)) &&
            currentContextIds?.length === contextIds.length
          if (chatSettingsTheSame) return

          updateChatSettings({ contextIds })
        }}
      />

      {chatSettingsInput.chatType === "agent" && (
        <>
          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
            }}
            direction="row"
            spacing={2}
          >
            <Tooltip title="Max Iterations">
              <Loop color="primary" />
            </Tooltip>
            <StyledSlider
              aria-label="Max Iterations"
              value={chatSettingsInput.maxIterations ?? 5}
              step={1}
              min={1}
              max={50}
              valueLabelDisplay="auto"
              onChange={async (e, value) => {
                const maxIterations = value as number
                updateChatSettings({ maxIterations })
              }}
              marks={[
                {
                  value: 1,
                  label: "Few Iterations",
                },
                {
                  value: 50,
                  label: "Max",
                },
              ]}
            />
          </Stack>
          <AgentTools
            defaultSelectedTools={chatSettingsInput?.tools ?? []}
            onChange={(newTools) => updateChatSettings({ tools: newTools.map(getToolId) })}
          />
        </>
      )}
    </Stack>
  )
}

function ContextSelection({
  availableContexts,
  onChangeContextIds,
  defaultSelected = [],
}: {
  availableContexts: Context[]
  onChangeContextIds: (contextIds: string[]) => void
  defaultSelected?: Context[]
}) {
  const [selectedContext, setSelectedContextIds] = useState<Context[]>(defaultSelected)

  useEffect(() => {
    onChangeContextIds(selectedContext.map((c) => c.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContext])

  const isOptionEqualValue = (option: Context, value: Context): boolean => option.id === value.id

  const onChange = (_event: any, newValue: Context[]) => {
    setSelectedContextIds([...newValue])
  }

  const getOptionName = (option: Context) => option.name

  return (
    // <Tooltip disableFocusListener title="Give you're bot a little context.">
    <WayfAutoComplete
      availableOptions={availableContexts}
      selectedOptions={selectedContext}
      isOptionEqualValue={isOptionEqualValue}
      onChange={onChange}
      getOptionName={getOptionName}
      label="Context"
    />
    // </Tooltip>
  )
}

const availableChatTypes: ChatType[] = ["conversation", "agent"]
const StyledSlider = styled(Slider)({
  "& .MuiSlider-markLabel": {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
})
