import Editor, { EditorProps, useMonaco } from "@monaco-editor/react"
import { debounce } from "@mui/material"
import { BASE_CONTEXT } from "alfred/messages/send/SendMessageField"
import { editor as EditorNamespace, Range } from "monaco-editor"
import { useEffect, useMemo, useRef, useState } from "react"
import { useCustomTheme } from "user/settings/Theme"
import { useSuggestions } from "./hooks"

export const useMonacoSuggestionEditor = () => {
  const { theme } = useCustomTheme()

  const editorRef = useRef<EditorNamespace.IStandaloneCodeEditor | null>(null)
  const { debounceSuggestions, suggestions } = useSuggestionsWithMonaco({
    editor: editorRef.current,
  })

  const triggerSuggestion = () => {
    debounceSuggestions(editorRef.current?.getValue() ?? "")
  }

  const triggerAcceptSuggestions = () => {
    editorRef.current?.trigger("anyString", "editor.action.inlineSuggest.acceptNextLine", undefined)
  }

  const triggerAcceptWordSuggestion = () => {
    editorRef.current?.trigger("anyString", "editor.action.inlineSuggest.acceptNextWord", undefined)
  }

  const MonacoSuggestionEditor = useMemo(() => {
    return (editorProps: MonacoSuggestionEditorProps) => {
      const options = editorProps.options ?? {}

      return (
        <>
          <Editor
            theme={theme === "dark" ? "vs-dark" : "vs-light"}
            defaultLanguage="markdown"
            {...editorProps}
            options={{
              inlineSuggest: {
                enabled: true,
                showToolbar: "onHover",
                suppressSuggestions: false,
              },
              wordWrap: "on",
              lineNumbers: "off",
              glyphMargin: false,
              folding: false,
              minimap: { enabled: false },
              formatOnType: true,
              formatOnPaste: true,
              trimAutoWhitespace: true,
              ...options,
            }}
            onMount={(editor, monaco) => {
              editorRef.current = editor

              editorRef.current.addAction({
                id: "trigger-suggestions",
                label: "Trigger Suggestions",
                keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
                contextMenuGroupId: "suggestion",
                contextMenuOrder: 1,
                run: triggerSuggestion,
              })

              // Add a context menu item to accept the suggestion
              editorRef.current.addAction({
                id: "accept-suggestion",
                label: "Accept Suggestion",
                // keybindings: [
                contextMenuGroupId: "suggestion",
                contextMenuOrder: 0,
                run: triggerAcceptSuggestions,
              })

              editor.onDidChangeModelContent((e) => {
                editorProps.onChange?.(editor.getValue(), e)
              })
            }}
          />
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme])

  return {
    hasSuggestions: suggestions.length > 0,
    triggerSuggestion,
    triggerAcceptSuggestions,
    triggerAcceptWordSuggestion,
    MonacoSuggestionEditor,
  }
}

type MonacoSuggestionEditorProps = EditorProps
const useSuggestionsWithMonaco = ({ editor }: { editor: EditorNamespace.IStandaloneCodeEditor | null }) => {
  const monaco = useMonaco()
  const { fetchSuggestions } = useSuggestions()
  const [suggestions, setSuggestions] = useState<string[]>([])

  const debounceSuggestions = useMemo(
    () =>
      debounce(async (text: string) => {
        const suggestions = await fetchSuggestions({
          context: BASE_CONTEXT,
          text,
        })

        setSuggestions(suggestions)
      }, 500),
    [fetchSuggestions]
  )

  useEffect(() => {
    if (!monaco || !editor) return
    const provider = monaco.languages.registerInlineCompletionsProvider("markdown", {
      provideInlineCompletions: (model, position) => {
        const text = model.getValue()
        // console.log({ suggestions })
        return {
          items: suggestions.map((suggestion) => {
            // trim the start of the suggestion if it contains the text before the cursor
            const trimmedSuggestion = suggestion.startsWith(text)
              ? suggestion.substring(text.length)
              : suggestion
            const startLineNumber = position.lineNumber
            const startColumn = position.column

            const endLineNumber = trimmedSuggestion.split("\n").length + startLineNumber
            const endColumn =
              trimmedSuggestion.split("\n").length === 1
                ? startColumn + trimmedSuggestion.length
                : (trimmedSuggestion.split("\n").pop()?.length ?? 0) + 1

            // Check if I need to add a space to the start of the suggestion
            // Consider punctuation, space, new line, word. Any condition that would require a space at the start of the suggestion
            const textBeforeCursor = model.getValue().substring(0, model.getOffsetAt(position))
            const lastChar = textBeforeCursor[textBeforeCursor.length - 1]
            const needsSpace = lastChar && ![" ", "\n", ".", ",", "!", "?", ":", ";"].includes(lastChar)
            const insertText = needsSpace ? " " + trimmedSuggestion : trimmedSuggestion

            return {
              sortText: "a",
              range: new Range(startLineNumber, startColumn, endLineNumber, endColumn),
              // text: trimmedSuggestion,
              insertText,
            }
          }),
        }
      },
      freeInlineCompletions: async () => {
        setSuggestions([])
      },
      handlePartialAccept: async () => {
        setSuggestions([])
      },
    })

    // Refresh the monaco so it displays the inline suggestion
    editor.trigger("anyString", "editor.action.inlineSuggest.trigger", undefined)

    return () => {
      provider.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monaco, editor, suggestions.join(",")])

  return { suggestions, debounceSuggestions }
}
