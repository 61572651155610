import { Mutation, Query } from "@ascully24/alfred"
import { Add, FilterList } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Chip,
  Container,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material"
import { ADD_TAG, DELETE_TAG } from "alfred/graphql/mutations"
import { GET_ALL_MESSAGES, GET_CHATS, GET_TAGS } from "alfred/graphql/queries"
import { useQueryWithLoading } from "graphql/apollo-utils"
import { useEffect, useState } from "react"
import { useModal } from "utils/Modal"
import { useScreenSize } from "utils/ScreenSizes"
import { toast } from "utils/Toast"
import { useMutationWithTry } from "../../graphql/apollo-utils"

export const AllTags = () => {
  const isSmallScreen = useScreenSize("sm")
  const { data } = useQueryWithLoading<Query>(GET_TAGS)
  const [deleteTag] = useMutationWithTry<Mutation>(DELETE_TAG)
  const [addTag] = useMutationWithTry<Mutation>(ADD_TAG)
  const { showModal, Modal: ConfirmDeleteModal } = useModal({
    title: "Are you sure?",
    body: "The tag will be removed from all chats and messages.",
  })

  const tags = data?.tag.list ?? []
  const [confirmDelete, setConfirmDelete] = useState(true)
  const [tagsToDisplay, setTagsToDisplay] = useState(tags)

  useEffect(() => {
    setTagsToDisplay(data?.tag.list ?? [])
  }, [data?.tag.list])

  const [tagFilter, setTagFilter] = useState("")
  const [newTag, setNewTag] = useState("")

  const handleAddTag = () => {
    if (!newTag) {
      toast.error("Tag cannot be empty")
      return
    }
    addTag({ variables: { tag: newTag }, refetchQueries: [GET_TAGS] }).then((data) => {
      if (!data?.data?.tag?.add) {
        toast.error("Failed to add tag")
        return
      }
    })

    setNewTag("")
  }

  const handleDeleteTag = async (tag: string) => {
    if (confirmDelete) {
      const confirmed = await showModal()
      if (!confirmed) {
        return
      }
    }

    setTagsToDisplay((prev) => prev.filter((t) => t !== tag))

    deleteTag({
      variables: { tag },
      refetchQueries: [GET_TAGS, GET_CHATS, GET_ALL_MESSAGES],
    }).then((response) => {
      const { data } = response

      if (!data?.tag?.delete) {
        setTagsToDisplay(tags)
        toast.error("Failed to delete tag", { toastId: "tag-deleted-failure" })
      }

      if (!data?.chat?.tag?.removeFromAll) {
        setTagsToDisplay(tags)
        toast.error("Failed to remove tag from chats", { toastId: "tag-deleted-failure-chats" })
      }

      if (!data?.message?.tag?.removeFromAll) {
        setTagsToDisplay(tags)
        toast.error("Failed to remove tag from messages", { toastId: "tag-deleted-failure-messages" })
      }

      if (!data?.note?.tag?.removeFromAll) {
        setTagsToDisplay(tags)
        toast.error("Failed to remove tag from notes", { toastId: "tag-deleted-failure-notes" })
      }

      if (!data?.context?.tag?.removeFromAll) {
        setTagsToDisplay(tags)
        toast.error("Failed to remove tag from context", { toastId: "tag-deleted-failure-context" })
      }
    })
  }

  const filteredTags = tagsToDisplay.filter((tag) => tag.toLowerCase().includes(tagFilter.toLowerCase()))

  return (
    <Container>
      <ConfirmDeleteModal />
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="Filter Tags"
          variant="outlined"
          value={tagFilter}
          onChange={(e) => setTagFilter(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FilterList />
              </InputAdornment>
            ),
          }}
        />
        <Stack
          gap={2}
          direction={{
            sm: "column",
            md: "row",
          }}
        >
          <TextField
            fullWidth
            label="Add Tag"
            variant="outlined"
            value={newTag}
            onKeyUpCapture={(e) => {
              if (e.key === "Enter") {
                handleAddTag()
              }
            }}
            onChange={(e) => setNewTag(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size={isSmallScreen ? "small" : "medium"}
                    edge="end"
                    color="primary"
                    onClick={handleAddTag}
                  >
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip enterDelay={500} title="No confirmation box will appear. Useful for multiple deletes.">
            <FormControlLabel
              sx={{
                flex: "0 0 auto",
              }}
              control={
                <Checkbox
                  checked={confirmDelete}
                  onChange={({ target }) => setConfirmDelete(target.checked)}
                />
              }
              label="Confirmation on delete?"
            />
          </Tooltip>
        </Stack>
        <Stack>
          <Box display="flex" flexWrap="wrap" maxWidth="100%">
            {filteredTags.map((tag) => (
              <Fade key={tag} in={true}>
                <Chip
                  label={tag}
                  variant="outlined"
                  color="primary"
                  sx={{ m: 1 }}
                  onDelete={async () => await handleDeleteTag(tag)}
                />
              </Fade>
            ))}
          </Box>
        </Stack>
      </Stack>
    </Container>
  )
}
