import { FilterAltOutlined, FilterListOutlined } from "@mui/icons-material"
import { Box, Collapse, Fab, Paper, Stack, Tooltip } from "@mui/material"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

export function FilterSettingsToggle({ children }: { children: React.ReactNode }) {
  const [params] = useSearchParams()

  const keyLength = Array.from(params.keys()).length
  const [showSettings, setShowSettings] = useState(keyLength > 0)

  const toggleSettings = () => {
    setShowSettings((prevState) => !prevState)
  }

  return (
    <Box
      id="filter-settings-toggle"
      sx={{
        flexGrow: 1,
      }}
    >
      <Stack direction="column" gap={2}>
        <Tooltip title={showSettings ? "Hide Filters" : "Show Filters"} placement="right">
          <Fab color="primary" onClick={toggleSettings}>
            {showSettings ? <FilterListOutlined /> : <FilterAltOutlined />}
          </Fab>
        </Tooltip>

        <Collapse in={showSettings} timeout="auto" unmountOnExit>
          <Paper elevation={3} className="p-3">
            {children}
          </Paper>
        </Collapse>
      </Stack>
    </Box>
  )
}

export const StickToTopOnScroll = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ position: "sticky", top: "4.5em", zIndex: 2 }}>{children}</Box>
}

export const StickToTopRightOnScroll = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: "4.5em",
        left: { xs: `calc(75%)` },

        zIndex: 2,
      }}
    >
      {children}
    </Box>
  )
}

export const StickToBottomCenter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "1em",
        left: { xs: `calc(50%)` },
        zIndex: 2,
      }}
    >
      <Box>{children}</Box>
    </Box>
  )
}

export const StickToTopRight = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "5em",
        left: { xs: `calc(75%)` },
        zIndex: 2,
      }}
    >
      <Box>{children}</Box>
    </Box>
  )
}
