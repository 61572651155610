import { Divider, Stack, Typography } from "@mui/material"
import { PasswordField, useSettings } from "user/settings/utils"

export const LLMSettings = () => {
  const { error, loading, settings, handleChangeEvent, UpdateSettingsButton } = useSettings()

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! {error.message}</div>

  return (
    <Stack gap={2}>
      <UpdateSettingsButton />
      <Divider>
        <Typography variant="h5">OpenAI</Typography>
      </Divider>
      <Typography variant="body2">
        OpenAI provides an API for accessing its large language model. You can use this for chats and agents.
      </Typography>
      <PasswordField
        label="OpenAI API Key"
        name="openAI"
        value={settings.openAI}
        onChange={handleChangeEvent}
      />
    </Stack>
  )
}
