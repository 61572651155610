import { FlightTakeoff } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useApplicationState } from "application-state/hooks"
import { useNavigate } from "react-router-dom"

export const PropelIcon = ({ text }: { text: string }) => {
  const navigate = useNavigate()
  const { updateApplication } = useApplicationState()

  return (
    <Tooltip title="Propel">
      <IconButton
        id="propel-message-icon"
        onClick={async () => {
          await updateApplication({
            propelledMessage: {
              text,
            },
          })
          navigate("/propel")
        }}
        sx={{ color: "primary.main" }}
        aria-label="propel message"
        size="small"
      >
        <FlightTakeoff fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
