import { useQuery } from "@apollo/client"
import { Chat, Message, Query } from "@ascully24/alfred"
import { Box, CircularProgress, Container, Divider, Link as LinkMUI, Stack, Typography } from "@mui/material"
import { GET_ALL_MESSAGES, GET_RELATED_CHATS } from "alfred/graphql/queries"
import { AllMessagesFilters, Filters } from "alfred/messages/AllMessagesFilters"
import { MessagesWithActions } from "alfred/messages/MessageList"
import { QueryWithLoadingParameters, useQueryWithFetchMore } from "graphql/apollo-utils"
import { useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useNavigate } from "react-router-dom"
import { useScreenSize } from "utils/ScreenSizes"

const limit = 10

export const AllMessages = () => {
  const [filter, setFilter] = useState<Filters>({ source: "All", tags: [], text: "" })
  const { source } = filter

  const queryParams: QueryWithLoadingParameters = [
    GET_ALL_MESSAGES,
    {
      variables: {
        filter: { ...filter, source: source === "All" ? undefined : source },
        offset: 0,
        limit,
      },
    },
  ]

  const {
    data,
    fetchMoreItems: fetchMoreMessages,
    hasMore,
  } = useQueryWithFetchMore(
    {
      limit,
      parseListItems: (data) => data?.message?.list ?? [],
      parseUpdatedItems: (prev, moreItems) => ({
        message: {
          list: [...prev.message.list, ...moreItems],
        },
      }),
    },
    queryParams
  )

  const allMessages = data?.message?.list ?? []
  const chatMap = useRelatedChats(allMessages)
  const toDisplay = useMemo(() => {
    let previousId = ""
    return (data?.message?.list ?? []).map((message) => {
      const chat = chatMap[message.chatId]
      const isSameChat = chat?.id === previousId
      previousId = chat?.id || ""

      const header = chat && !isSameChat ? <ChatHeader chat={chat} /> : <></>

      return (
        <Stack gap={2} key={message.id}>
          {header}
          <MessagesWithActions message={message} />
        </Stack>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.message?.list, chatMap])

  return (
    <Container>
      <Stack gap={2} direction="column">
        <AllMessagesFilters onChange={setFilter} />

        <InfiniteScroll
          key="all-messages-infinite-scroll"
          style={{
            // InfiniteScroll has a bug where it will cut off some of the children. This is a workaround
            overflow: "visible",
          }}
          dataLength={allMessages.length} //This is important field to render the next data
          next={fetchMoreMessages}
          hasMore={hasMore}
          loader={
            <Box
              sx={{
                p: 2,
              }}
              textAlign="center"
            >
              <CircularProgress />
            </Box>
          }
          endMessage={
            <Box
              sx={{
                p: 2,
              }}
              textAlign="center"
            >
              <Typography variant="h6">No more messages</Typography>
            </Box>
          }
        >
          <Stack gap={3}>{toDisplay}</Stack>
        </InfiniteScroll>
      </Stack>
    </Container>
  )
}

function ChatHeader({ chat }: { chat: Chat }) {
  const navigate = useNavigate()
  const isSmallScreen = useScreenSize("sm")

  const chatNameLink = (
    <Typography variant="h6">
      <LinkMUI
        underline="hover"
        color="primary"
        onClick={() => {
          navigate(`/chats/${chat.id}`)
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <Box>{chat?.settings.name}</Box>
      </LinkMUI>
    </Typography>
  )

  if (isSmallScreen) {
    return (
      <>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {chatNameLink}
        </Box>
        <Divider />
      </>
    )
  }

  return (
    <Divider
      style={{
        backgroundColor: "primary",
        height: "1em",
        marginTop: "1em",
        marginBottom: "1em",
      }}
    >
      {chatNameLink}
    </Divider>
  )
}

const useRelatedChats = (allMessages: Message[]) => {
  const { data: { chat } = {} } = useQuery<Query>(GET_RELATED_CHATS, {
    variables: {
      ids: allMessages.map(({ chatId }) => chatId),
    },
  })

  const relatedChats = chat?.list ?? []
  return relatedChats.reduce((acc, chat) => {
    acc[chat.id] = chat
    return acc
  }, {} as Record<string, Chat>)
}
