import { Divider, Stack, Typography } from "@mui/material"
import { SettingsTextField, useSettings } from "user/settings/utils"

export const SpeechToTextSettings = () => {
  const { error, loading, settings, handleChangeEvent, UpdateSettingsButton } = useSettings()

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! {error.message}</div>

  return (
    <Stack
      sx={{
        mb: 5,
        mx: { xs: 3, md: 0 },
      }}
      spacing={5}
    >
      <UpdateSettingsButton />
      <Stack gap={2}>
        <Divider>
          <Typography variant="h5">Google Speech to Text</Typography>
        </Divider>
        <Typography variant="body2">
          Use Googles Speech to Text service when converting your voice.
        </Typography>

        <SettingsTextField
          fullWidth
          label="Client ID"
          name="googleSpeechToTextClientId"
          value={settings.googleSpeechToTextClientId}
          onChange={handleChangeEvent}
        />
        <SettingsTextField
          fullWidth
          label="Client Email"
          name="googleSpeechToTextClientEmail"
          value={settings.googleSpeechToTextClientEmail}
          onChange={handleChangeEvent}
        />

        <SettingsTextField
          fullWidth
          label="Project ID"
          name="googleSpeechToTextProjectId"
          autoComplete="off"
          id="googleSpeechToTextProjectId"
          value={settings.googleSpeechToTextProjectId}
          onChange={handleChangeEvent}
        />

        <form autoComplete="off">
          <SettingsTextField
            fullWidth
            label="Private Key"
            multiline
            rows={7}
            name="googleSpeechToTextPrivateKey"
            value={settings.googleSpeechToTextPrivateKey}
            onChange={handleChangeEvent}
          />
        </form>
      </Stack>
    </Stack>
  )
}
