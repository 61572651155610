import { Query } from "@ascully24/alfred"
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { GET_TAGS } from "alfred/graphql/queries"
import { useQueryWithLoading } from "graphql/apollo-utils"
import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import ClearIcon from "@mui/icons-material/Clear"

export const tagRequestParam = "t"

export function TagFilterField({ onChange }: { onChange: (tags: string[]) => void }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const tagRequestParams = searchParams.getAll(tagRequestParam)
  const selectedTags = useMemo(() => {
    return searchParams.getAll(tagRequestParam) || []

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagRequestParams.length])

  const { data } = useQueryWithLoading<Query>(GET_TAGS)
  const availableTags = data?.tag.list ?? []

  useEffect(() => {
    onChange(selectedTags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags])

  const handleTagChange = (tags: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString())
    newSearchParams.delete(tagRequestParam)
    tags.forEach((tag) => newSearchParams.append(tagRequestParam, tag))
    setSearchParams(newSearchParams)
  }

  const handleClearTags = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString())
    newSearchParams.delete(tagRequestParam)
    setSearchParams(newSearchParams)
    onChange([])
  }

  return (
    <FormControl
      id="tag-filter"
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
    >
      <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
      <Select
        multiple
        label="Tags"
        id="tag-filter-select"
        value={selectedTags}
        onChange={({ target: { value } }: SelectChangeEvent<string[]>) => {
          handleTagChange(typeof value === "string" ? value.split(",") : value)
        }}
        renderValue={(selected) => (
          <Box>
            {selected.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleTagChange(selectedTags.filter((t) => t !== tag))
                }}
              />
            ))}
          </Box>
        )}
        endAdornment={
          selectedTags.length > 0 ? (
            <Box sx={{ pr: 1 }}>
              <IconButton size="small" onClick={handleClearTags} color="error">
                <ClearIcon />
              </IconButton>
            </Box>
          ) : undefined
        }
      >
        {availableTags.map((availableTag) => (
          <MenuItem
            sx={{
              zIndex: 4,
            }}
            key={availableTag}
            value={availableTag}
          >
            <Checkbox checked={selectedTags.indexOf(availableTag) > -1} />
            <ListItemText primary={availableTag} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
