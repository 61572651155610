import { Context, Mutation, Query } from "@ascully24/alfred"
import { ADD_TAG_TO_CONTEXT, EDIT_TAG_ON_CONTEXT, REMOVE_TAG_FROM_CONTEXT } from "alfred/graphql/mutations"
import { GET_CONTEXT, GET_TAGS } from "alfred/graphql/queries"
import { useMutationWithTry, useQueryWithLoading } from "graphql/apollo-utils"
import { AutoCompleteChipDisplay } from "utils/chips/ChipDisplay"

export const ContextTags = ({ context }: { context: Context }) => {
  const contextId = context.id
  const { data: availableTagsData } = useQueryWithLoading<Query>(GET_TAGS)

  const [editTag] = useMutationWithTry<Mutation>(EDIT_TAG_ON_CONTEXT)
  const [removeTag] = useMutationWithTry<Mutation>(REMOVE_TAG_FROM_CONTEXT)
  const [addTag] = useMutationWithTry<Mutation>(ADD_TAG_TO_CONTEXT)

  const addTagCallback = async (tag: string) => {
    const { data } = await addTag({
      variables: { contextId, tag },
      refetchQueries: [GET_CONTEXT, GET_TAGS],
    })

    return !!data?.context?.tag?.add
  }

  return (
    <AutoCompleteChipDisplay
      getOptionLabel={(option) => option}
      values={context.tags}
      editChip={async (oldTag, newTag) => {
        await editTag({
          variables: { contextId, oldTag, newTag },
          refetchQueries: [GET_CONTEXT, GET_TAGS],
        })
      }}
      removeChip={async (tag) => {
        await removeTag({
          variables: { contextId, tag },
          refetchQueries: [GET_CONTEXT, GET_TAGS],
        })
      }}
      addChip={addTagCallback}
      onUnknownOption={addTagCallback}
      autoCompleteValues={availableTagsData?.tag?.list ?? []}
      getChipText={(tag) => tag}
    />
  )
}
