import React, { useEffect, useMemo, useRef } from "react"
import { ApplicationState, ApplicationStateContext } from "./ApplicationStateProvider"

export const useIsScrollingDown = () => {
  const {
    applicationState: { userActions: { scrollingDown } = {} },
  } = useApplicationState()

  return scrollingDown
}

export const useScrollDownRef = () => {
  const { updateApplication } = useApplicationState()
  const [currentScrollDirection, setCurrentScrollDirection] = React.useState<"up" | "down" | undefined>(
    undefined
  )

  const scrollRef = useRef<HTMLDivElement>(null)
  const lastScrollTop = useRef<number>(0)
  const scrollThreshold = 20

  // TODO: FROM HERE - On scroll up and down doesn't work on initial load. It only works after loading more messages
  const handleScroll = () => {
    if (scrollRef.current) {
      const currentScrollTop = scrollRef.current.scrollTop
      const scrollDiff = currentScrollTop - lastScrollTop.current

      if (scrollDiff > scrollThreshold && currentScrollDirection !== "down") {
        // Scrolled down
        setCurrentScrollDirection("down")
        updateApplication({
          userActions: {
            scrollingDown: true,
          },
        })
      } else if (scrollDiff < -scrollThreshold && currentScrollDirection !== "up") {
        setCurrentScrollDirection("up")

        // Scrolled up
        updateApplication({
          userActions: {
            scrollingDown: false,
          },
        })
      }

      // Update the last scroll position if the difference exceeds the threshold
      if (Math.abs(scrollDiff) > scrollThreshold) {
        lastScrollTop.current = currentScrollTop
      }
    }
  }

  useEffect(() => {
    const scrollableDiv = scrollRef.current
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll)
    }

    updateApplication({
      userActions: {
        scrollingDown: false,
      },
    })

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleScroll)
      }

      setCurrentScrollDirection(undefined)
      updateApplication({
        userActions: {
          scrollingDown: undefined,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef.current])

  return scrollRef
}

export const usePropelledText = () => {
  const { applicationState, updateApplication } = useApplicationState()
  const propelledMessage = useMemo(() => {
    return consumePropelledText(applicationState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (propelledMessage) {
      updateApplication({ propelledMessage: null })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return propelledMessage
}

export const useApplicationState = () => {
  const { applicationState, updateApplication } = React.useContext(ApplicationStateContext)
  return { applicationState, updateApplication }
}

function consumePropelledText(applicationState: ApplicationState) {
  const { text, canConsume } = applicationState.propelledMessage ?? {}
  const defaultText = (canConsume ? text : "") ?? ""
  return defaultText
}
