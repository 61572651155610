import { Message as GQLMessage, Mutation, Query } from "@ascully24/alfred"
import { Check, Clear, Edit, Refresh, SupportAgent } from "@mui/icons-material"
import { Avatar, Badge, Box, IconButton, Paper, Skeleton, Stack, TextField, Tooltip } from "@mui/material"
import { MessagePin } from "alfred/MessagePin"
import { ADD_MESSAGE_TAG, EDIT_MESSAGE_TAG, REMOVE_MESSAGE_TAG } from "alfred/graphql/mutations"
import { GET_ALL_MESSAGES, GET_CHAT, GET_CHATS, GET_TAGS } from "alfred/graphql/queries"
import { useMutationWithTry, useQueryWithLoading } from "graphql/apollo-utils"
import { useEffect, useState } from "react"
import { AutoCompleteChipDisplay } from "utils/chips/ChipDisplay"
import { calculateTimeAgo } from "utils/time"
import { MarkdownViewer } from "../../utils/MarkdownViewer"
import { PropelIcon } from "../PropelIcon"
import { useNavigate } from "react-router-dom"

export const Message = ({
  message,
  timestamp = new Date(),
  displayName,
  editMessageCallback,
  regenerateResponseCallback,
  overrideText = message.text,
}: {
  message: GQLMessage
  overrideText?: string
  timestamp?: Date
  displayName?: string
  editMessageCallback?: (newMessage: string) => Promise<void>
  regenerateResponseCallback?: () => Promise<void>
}) => {
  const navigate = useNavigate()
  const { id: messageId, source, tags, chatId } = message

  const { data: availableTagsData } = useQueryWithLoading<Query>(GET_TAGS)

  const refetchQueries = [
    { query: GET_CHAT, variables: { id: chatId } },
    GET_CHATS,
    GET_TAGS,
    GET_ALL_MESSAGES,
  ]
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [editedMessage, setEditedMessage] = useState(overrideText)
  const [editTag] = useMutationWithTry<Mutation>(EDIT_MESSAGE_TAG)
  const [removeTag] = useMutationWithTry<Mutation>(REMOVE_MESSAGE_TAG)
  const [addTag] = useMutationWithTry<Mutation>(ADD_MESSAGE_TAG)

  const addTagCallback = async (tag: string) => {
    const { data } = await addTag({
      variables: { messageId, tag },
      refetchQueries,
    })

    return !!data?.message?.tag?.add?.id
  }

  useEffect(() => {
    setEditedMessage(overrideText)
  }, [overrideText])

  const handleEditClick = async () => {
    if (isSubmitting) return

    setIsEditing(true)
  }

  const handleCancelClick = () => {
    setIsEditing(false)
    setEditedMessage(message?.text || "")
  }

  const handleRegenerateClick = async () => {
    if (isSubmitting) return

    setIsSubmitting(true)
    regenerateResponseCallback && (await regenerateResponseCallback())
    setIsSubmitting(false)
  }

  const handleSaveClick = async () => {
    // Add logic to save the edited message
    setIsSubmitting(true)
    setIsEditing(false)
    editMessageCallback && (await editMessageCallback(editedMessage))
    setIsSubmitting(false)
  }

  return (
    <Box
      sx={{
        position: "relative",
        p: { xs: 0, sm: 2 },
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
    >
      <Badge badgeContent={displayName} color="error">
        <Avatar
          alt={displayName}
          className={`bg-warning rounded-circle`}
          src={source === "user" ? "/user.png" : "/bot.jpeg"}
          sx={{
            position: "absolute",
            bottom: "-20px",
            left: "10px",
            zIndex: 1,
          }}
        />
      </Badge>
      <Box
        sx={{
          position: "relative",
          zIndex: 0,
        }}
      >
        <Paper elevation={5}>
          {isEditing ? (
            <Stack
              gap={1}
              sx={{
                p: { xs: 1, sm: 3 },
                width: "100%",
              }}
              className="break-words"
            >
              <TextField
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "primary.main",
                    },
                    "&:hover fieldset": {
                      borderColor: "primary.main",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "primary.main",
                    },
                  },
                }}
                id="edit-message-textfield"
                multiline
                onKeyUpCapture={(e) => {
                  if (e.ctrlKey && e.key === "Enter") {
                    e.preventDefault()
                    handleSaveClick()
                  }
                }}
                maxRows={10}
                value={editedMessage}
                onChange={(e) => setEditedMessage(e.target.value)}
              />
              <Stack direction="row" spacing={1}>
                <div className="ms-auto">
                  <IconButton
                    id="cancel-edit-message-icon"
                    onClick={handleCancelClick}
                    sx={{ color: "error.main" }}
                    aria-label="cancel edit"
                    size="small"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                  <IconButton
                    id="save-edit-message-icon"
                    onClick={handleSaveClick}
                    sx={{ color: "success.main" }}
                    aria-label="save edit"
                    size="small"
                  >
                    <Check fontSize="small" />
                  </IconButton>
                </div>
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction="column"
              gap={1}
              className="break-words"
              sx={{
                p: { xs: 1, sm: 3 },
              }}
            >
              <Paper variant="outlined" sx={{ p: 2 }}>
                <MarkdownViewer markdownText={editedMessage} />
              </Paper>

              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Box flexGrow={1} maxWidth="75%">
                  <AutoCompleteChipDisplay
                    getOptionLabel={(option) => option}
                    autoCompleteValues={availableTagsData?.tag?.list ?? []}
                    getChipText={(tag) => tag}
                    values={tags}
                    editChip={async (oldTag, newTag) => {
                      await editTag({
                        variables: { messageId, oldTag, newTag },
                        refetchQueries,
                      })
                    }}
                    removeChip={async (tag) => {
                      await removeTag({
                        variables: { messageId, tag },
                        refetchQueries,
                      })
                    }}
                    addChip={addTagCallback}
                    onUnknownOption={addTagCallback}
                  />
                </Box>
                <Box>
                  {calculateTimeAgo(timestamp)}
                  <Box
                    sx={{
                      display: "inline-block",
                      marginX: 1,
                    }}
                  >
                    <PropelIcon text={editedMessage} />
                  </Box>
                  <MessagePin message={message} size="small" />
                  {displayName === "user" && editMessageCallback && (
                    <>
                      <IconButton
                        id="view-message-agent-runs-icon"
                        onClick={() => navigate(`/messages/${messageId}/agent-runs`)}
                        sx={{ color: "primary.main" }}
                        aria-label="agent runs"
                        size="small"
                      >
                        {isSubmitting ? (
                          <Skeleton
                            sx={{
                              bgcolor: "grey.500",
                            }}
                            variant="circular"
                            width={20}
                            height={20}
                          />
                        ) : (
                          <Tooltip title="Agent Runs">
                            <SupportAgent fontSize="small" />
                          </Tooltip>
                        )}
                      </IconButton>
                      <IconButton
                        id="edit-message-icon"
                        onClick={handleEditClick}
                        sx={{ color: "primary.main" }}
                        aria-label="edit message"
                        size="small"
                      >
                        {isSubmitting ? (
                          <Skeleton
                            sx={{
                              bgcolor: "grey.500",
                            }}
                            variant="circular"
                            width={20}
                            height={20}
                          />
                        ) : (
                          <Tooltip title="Edit">
                            <Edit fontSize="small" />
                          </Tooltip>
                        )}
                      </IconButton>
                    </>
                  )}
                  {displayName === "bot" && regenerateResponseCallback && (
                    <IconButton
                      id="regenerate-response-icon"
                      onClick={handleRegenerateClick}
                      sx={{ color: "primary.main" }}
                      aria-label="edit message"
                      size="small"
                    >
                      {isSubmitting ? (
                        <Skeleton
                          sx={{
                            bgcolor: "grey.500",
                          }}
                          variant="circular"
                          width={20}
                          height={20}
                        />
                      ) : (
                        <Refresh fontSize="small" />
                      )}
                    </IconButton>
                  )}
                </Box>
              </Stack>
            </Stack>
          )}
        </Paper>
      </Box>
    </Box>
  )
}
