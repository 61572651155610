import { Typography } from "@mui/material"
import { LoadingSpinner } from "utils/LoadingSpinner"

export const ListDisplay = ({
  called,
  loading,
  list,
  children,
  emptyMessage,
}: {
  called: boolean
  loading: boolean
  list: any[]
  emptyMessage: string
  children: React.ReactNode
}) => {
  if (loading) {
    return <LoadingSpinner />
  }

  if (called && list.length === 0) {
    return (
      <Typography sx={{ p: 2 }} textAlign="center" variant="h6">
        {emptyMessage}
      </Typography>
    )
  }

  return <>{children}</>
}
