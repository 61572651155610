import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material"
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material"

type WayfAutoCompleteProps<T> = {
  availableOptions: T[]
  selectedOptions: T[]
  isOptionEqualValue: (option: T, value: T) => boolean
  onChange: (_event: any, newValue: T[]) => void
  getOptionName: (option: T) => string
  label: string
}

export const WayfAutoComplete = <T extends any>({
  availableOptions,
  selectedOptions,
  isOptionEqualValue,
  onChange,
  getOptionName,
  label,
}: WayfAutoCompleteProps<T>) => {
  return (
    <Autocomplete
      options={availableOptions}
      value={selectedOptions}
      isOptionEqualToValue={isOptionEqualValue}
      multiple
      disableCloseOnSelect
      limitTags={3}
      onChange={onChange}
      getOptionLabel={getOptionName}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{
              marginRight: 8,
            }}
            checked={selected}
          />
          {getOptionName(option)}
        </li>
      )}
      renderTags={(values, getProps) =>
        values.map((option, index) => (
          <Chip
            label={getOptionName(option)}
            {...getProps({
              index,
            })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, startAdornment: <>{params.InputProps.startAdornment}</> }}
          label={label}
          value={selectedOptions}
          placeholder="Add"
        />
      )}
    />
  )
}
