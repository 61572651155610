import { gql } from "@apollo/client"

export const BaseContextFragment = gql`
  fragment BaseContextFragment on Context {
    __typename
    id
    name
    tags
  }
`

export const MetaDataFragment = gql`
  fragment MetaDataFragment on MetaData {
    __typename
    tags
    pinned {
      date
    }
  }
`

export const ToolsFragment = gql`
  fragment ToolsFragment on Tool {
    __typename
    id
    name
    description
  }
`

export const AgentFragment = gql`
  fragment AgentFragment on Agent {
    __typename
    id
    name
    description
    model
    promptSuffix
    tools {
      ...ToolsFragment
    }
    metaData {
      ...MetaDataFragment
    }
  }
  ${MetaDataFragment}
  ${ToolsFragment}
`

export const AgentRunStatus = gql`
  fragment AgentRunStatus on AgentRunStatus {
    __typename
    id
    summary
    agent {
      id
      name
      description
      model
      promptSuffix
      tools {
        ...ToolsFragment
      }
    }
    prompt
    status
    messages {
      id
      message
      createdAt
    }
    createdAt
  }

  ${ToolsFragment}
`

export const SettingsFragment = gql`
  fragment SettingsFragment on Settings {
    __typename
    allowPayAsYouGo
    globalChatSettings {
      systemMessage
    }
    services {
      serpAPI {
        apiKey
      }
      github {
        authToken
      }
      google {
        search {
          apiKey
          engineId
        }
        tts {
          apiKey
          voiceId
          favouriteVoiceIds
          pitch
          speakingRate
        }
        speechToText {
          clientId
          clientEmail
          projectId
          privateKey
        }
      }
    }
    llms {
      openAI {
        apiKey
      }
    }
    voice {
      preferredVoiceService
    }
    voiceAssistant {
      wakeWords
      stopWords
    }
  }
`

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    __typename
    id
    text
    chatId
    source
    createdAt
    tags
    metaData {
      ...MetaDataFragment
    }
  }

  ${MetaDataFragment}
`

export const ChatSettingsFragmentBase = gql`
  fragment ChatSettingsFragmentBase on ChatSettings {
    __typename
    name
    configuration
    tags
    memoryScale
    creativityScale
    chatType
    isVoiceEnabled
    maxIterations
  }
`

export const ChatFragmentBase = gql`
  fragment ChatFragmentBase on Chat {
    __typename
    id
    dateCreated
    settings {
      ...ChatSettingsFragmentBase
    }
    metaData {
      ...MetaDataFragment
    }
  }

  ${ChatSettingsFragmentBase}
  ${MetaDataFragment}
`

export const ChatFragment = gql`
  fragment ChatFragment on Chat {
    __typename
    ...ChatFragmentBase
    settings {
      ...ChatSettingsFragmentBase
      contexts {
        ...BaseContextFragment
      }
      tools {
        ...ToolsFragment
      }
    }
  }

  ${ChatFragmentBase}
  ${ChatSettingsFragmentBase}
  ${BaseContextFragment}
  ${ToolsFragment}
`

export const NoteFragment = gql`
  fragment NoteFragment on Note {
    __typename
    id
    name
    content
    metaData {
      ...MetaDataFragment
    }
  }
  ${MetaDataFragment}
`

export const LinkedEntitiesFragment = gql`
  fragment LinkedEntityFragment on LinkedEntity {
    __typename
    id
    type
    entity {
      __typename
      ... on Note {
        ...NoteFragment
      }
    }
  }
  ${NoteFragment}
`

export const ContextFragment = gql`
  fragment ContextFragment on Context {
    ...BaseContextFragment
    linkedEntities {
      ...LinkedEntityFragment
    }
  }

  ${BaseContextFragment}
  ${LinkedEntitiesFragment}
`

export const AgentRunBaseFragment = gql`
  fragment AgentRunBase on AgentRun {
    __typename
    id
    prompt
    status
    messages {
      id
      message
      createdAt
    }
    createdAt
    summary
    includeMessages
  }
`

export const AgentRunFragment = gql`
  fragment AgentRun on AgentRun {
    ...AgentRunBase
    linkedEntity {
      __typename
      id
      type
      entity {
        __typename
        ... on Message {
          ...MessageFragment
        }
        ... on Agent {
          ...AgentFragment
        }
        ... on AgentRunEmptyEntity {
          _empty
        }
      }
    }
  }
  ${AgentRunBaseFragment}
  ${MessageFragment}
  ${AgentFragment}
`
