import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material"
import { CreateChatField } from "alfred/messages/send/CreateChatField"
import { useEffect, useState } from "react"
import { useSharedData } from "sharing/useSharedData"

function ShareOptionCheckbox({
  name,
  checked,
  label,
  handleCheckboxChange,
}: {
  name: string
  checked: boolean
  label: string
  handleCheckboxChange: (event: any) => void
}) {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleCheckboxChange} name={name} />}
      label={
        <Typography variant="body1" sx={{ maxWidth: "100%", wordWrap: "break-word" }}>
          {label}
        </Typography>
      }
    />
  )
}

const Share = () => {
  const { data } = useSharedData()

  const { title, text, url } = data ?? {
    title: null,
    text: null,
    url: null,
  }

  const [selectedFields, setSelectedFields] = useState({
    title: !!title,
    text: !!text,
    url: !!url,
  })

  useEffect(() => {
    setSelectedFields({
      title: !!title,
      text: !!text,
      url: !!url,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleCheckboxChange = (event: any) => {
    setSelectedFields({ ...selectedFields, [event.target.name]: event.target.checked })
  }

  const fieldArray = []
  if (selectedFields.title && title) fieldArray.push(title)
  if (selectedFields.url && url) fieldArray.push(url)
  if (selectedFields.text && text) fieldArray.push(text)

  const joinedFields = fieldArray.join("\n\n")
  const defaultText = fieldArray.length ? `\n\n${joinedFields}` : ""

  return (
    <Stack
      gap={2}
      sx={{
        flexWrap: "wrap",
        width: {
          xs: "100%",
          md: "80%",
        },
        mx: "auto",
      }}
    >
      <Typography variant="h6">Choose what to send: </Typography>
      {title && (
        <ShareOptionCheckbox
          handleCheckboxChange={handleCheckboxChange}
          name={"title"}
          checked={selectedFields.title}
          label={`Title: ${title}`}
        />
      )}
      {url && (
        <ShareOptionCheckbox
          handleCheckboxChange={handleCheckboxChange}
          name={"url"}
          checked={selectedFields.url}
          label={`URL: ${url}`}
        />
      )}
      {text && (
        <ShareOptionCheckbox
          handleCheckboxChange={handleCheckboxChange}
          name={"text"}
          checked={selectedFields.text}
          label={`Text: ${text}`}
        />
      )}
      <CreateChatField defaultText={defaultText} />
    </Stack>
  )
}

export default Share
