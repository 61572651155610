import { gql } from "@apollo/client"
import {
  SettingsFragment,
  ChatFragment,
  NoteFragment,
  ContextFragment,
  BaseContextFragment,
  AgentRunFragment,
} from "alfred/graphql/fragments"

export const UPDATE_CHAT_SETTINGS = gql`
  mutation ($chatId: ID!, $settings: ChatSettingsInput!) {
    chat {
      updateSettings(chatId: $chatId, settings: $settings) {
        ...ChatFragment
      }
    }
  }
  ${ChatFragment}
`

export const SEND_MESSAGE = gql`
  mutation ($chatId: ID!, $text: String!) {
    chat {
      sendMessage(chatId: $chatId, text: $text)
    }
  }
`

export const CREATE_GET_CONTINUOUS_CHAT = gql`
  mutation {
    chat {
      createOrGetContinuousChatId
    }
  }
`

export const CREATE_VOICE_ASSISTANT_CHAT = gql`
  mutation {
    chat {
      createVoiceAssistant {
        ...ChatFragment
      }
    }
  }
  ${ChatFragment}
`

export const ADD_CHAT_TAG = gql`
  mutation ($chatId: ID!, $tag: String!) {
    chat {
      tag {
        add(chatId: $chatId, tag: $tag) {
          id
        }
      }
    }
  }
`

export const SET_CHAT_PINNED = gql`
  mutation ($chatId: ID!, $pinned: Boolean!) {
    chat {
      pin(chatId: $chatId, pinned: $pinned)
    }
  }
`
export const SET_MESSAGE_PINNED = gql`
  mutation ($messageId: ID!, $pinned: Boolean!) {
    message {
      pin(messageId: $messageId, pinned: $pinned)
    }
  }
`
export const SET_AGENT_PINNED = gql`
  mutation ($id: ID!, $pinned: Boolean!) {
    agent {
      setAgentPinned(id: $id, pinned: $pinned)
    }
  }
`

export const EDIT_CHAT_TAG = gql`
  mutation ($chatId: ID!, $oldTag: String!, $newTag: String!) {
    chat {
      tag {
        edit(chatId: $chatId, oldTag: $oldTag, newTag: $newTag) {
          id
        }
      }
    }
  }
`
export const REMOVE_CHAT_TAG = gql`
  mutation ($chatId: ID!, $tag: String!) {
    chat {
      tag {
        remove(chatId: $chatId, tag: $tag) {
          id
        }
      }
    }
  }
`

export const DELETE_CHAT = gql`
  mutation ($id: ID!) {
    chat {
      delete(id: $id)
    }
  }
`

export const ADD_MESSAGE_TAG = gql`
  mutation ($messageId: ID!, $tag: String!) {
    message {
      tag {
        add(messageId: $messageId, tag: $tag) {
          id
        }
      }
    }
  }
`

export const EDIT_MESSAGE_TAG = gql`
  mutation ($messageId: ID!, $oldTag: String!, $newTag: String!) {
    message {
      tag {
        edit(messageId: $messageId, oldTag: $oldTag, newTag: $newTag) {
          id
        }
      }
    }
  }
`
export const REMOVE_MESSAGE_TAG = gql`
  mutation ($messageId: ID!, $tag: String!) {
    message {
      tag {
        remove(messageId: $messageId, tag: $tag) {
          id
        }
      }
    }
  }
`

export const DELETE_TAG = gql`
  mutation ($tag: String!) {
    tag {
      delete(tag: $tag)
    }
    chat {
      tag {
        removeFromAll(tag: $tag)
      }
    }
    message {
      tag {
        removeFromAll(tag: $tag)
      }
    }
    note {
      tag {
        removeFromAll(tag: $tag)
      }
    }
    context {
      tag {
        removeFromAll(tag: $tag)
      }
    }
  }
`

export const ADD_TAG = gql`
  mutation AddTag($tag: String!) {
    tag {
      add(tag: $tag)
    }
  }
`

export const EDIT_MESSAGE = gql`
  mutation ($messageId: ID!, $text: String!) {
    message {
      edit(messageId: $messageId, text: $text) {
        id
      }
    }
  }
`
export const REGENERATE_RESPONSE = gql`
  mutation ($messageId: ID!) {
    message {
      regenerateResponse(messageId: $messageId) {
        id
      }
    }
  }
`

export const CREATE_CHAT = gql`
  mutation ($settings: ChatSettingsInput!) {
    chat {
      create(settings: $settings) {
        ...ChatFragment
      }
    }
  }
  ${ChatFragment}
`

export const EDIT_AGENT = gql`
  mutation EditAgent($id: ID!, $input: AgentInput!) {
    agent {
      edit(id: $id, input: $input) {
        id
        name
        description
        model
        promptSuffix
        tools {
          id
          name
          description
        }
      }
    }
  }
`

export const DELETE_AGENT_RUN_NAME = "DeleteAgentRun"
export const DELETE_AGENT_RUN = gql`
  mutation ${DELETE_AGENT_RUN_NAME}($id: ID!) {
    agentRun {
      delete(id: $id)
    }
  }
`

export const CANCEL_AGENT_RUN_NAME = "CancelAgentRun"
export const CANCEL_AGENT_RUN = gql`
  mutation ${CANCEL_AGENT_RUN_NAME}($id: ID!) {
    agentRun {
      cancel(id: $id) {
        ...AgentRun
      }
    }
  }
  ${AgentRunFragment}
`

export const RUN_AGENT_NAME = "RunAgent"
export const RUN_AGENT = gql`
  mutation ${RUN_AGENT_NAME}($agentId: ID!, $prompt: String, $includeMessages: [MessageTypeFilter!]) {
    agentRun {
      run(agentId: $agentId, prompt: $prompt, includeMessages: $includeMessages)
    }
  }
`

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateSettings($input: UpdateSettings!) {
    settings {
      update(input: $input) {
        ...SettingsFragment
      }
    }
  }

  ${SettingsFragment}
`

export const SET_LIMIT_MUTATION = gql`
  mutation SetLimit($input: UpdateLimitInput!) {
    usage {
      setLimit(input: $input)
    }
  }
`

export const UPDATE_FAVOURITE_VOICE_NAMES = gql`
  mutation UpdateFavouriteVoiceNames($voiceIds: [String!]!) {
    settings {
      updateFavouriteVoiceIds(voiceIds: $voiceIds)
    }
  }
`

export const PREVIEW_GOOGLE_TTS = gql`
  mutation PreviewGoogleTts($pitch: Float!, $speakingRate: Float!, $voiceId: String!) {
    settings {
      previewGoogleTts(pitch: $pitch, speakingRate: $speakingRate, voiceId: $voiceId)
    }
  }
`

export const CREATE_NOTE = gql`
  mutation CreateNote($content: String!, $name: String) {
    note {
      create(content: $content, name: $name) {
        ...NoteFragment
      }
    }
  }
  ${NoteFragment}
`

export const UPDATE_NOTE = gql`
  mutation UpdateNote($noteId: ID!, $content: String!, $name: String) {
    note {
      update(noteId: $noteId, content: $content, name: $name) {
        ...NoteFragment
      }
    }
  }
  ${NoteFragment}
`

export const DELETE_NOTE = gql`
  mutation DeleteNote($noteId: ID!) {
    note {
      delete(noteId: $noteId)
    }
  }
`

export const ADD_TAG_TO_NOTE = gql`
  mutation AddTagToNote($noteId: ID!, $tag: String!) {
    note {
      tag {
        add(noteId: $noteId, tag: $tag) {
          ...NoteFragment
        }
      }
    }
  }
  ${NoteFragment}
`

export const REMOVE_TAG_FROM_NOTE = gql`
  mutation RemoveTagFromNote($noteId: ID!, $tag: String!) {
    note {
      tag {
        remove(noteId: $noteId, tag: $tag) {
          ...NoteFragment
        }
      }
    }
  }
  ${NoteFragment}
`

export const EDIT_TAG_ON_NOTE = gql`
  mutation EditTagOnNote($noteId: ID!, $oldTag: String!, $newTag: String!) {
    note {
      tag {
        edit(noteId: $noteId, oldTag: $oldTag, newTag: $newTag) {
          ...NoteFragment
        }
      }
    }
  }
  ${NoteFragment}
`

export const PIN_NOTE = gql`
  mutation Pin($noteId: ID!) {
    note {
      pin(noteId: $noteId)
    }
  }
`

export const CREATE_CONTEXT = gql`
  mutation CreateContext($name: String!) {
    context {
      create(name: $name) {
        ...ContextFragment
      }
    }
  }
  ${ContextFragment}
`

export const UPDATE_CONTEXT = gql`
  mutation UpdateContext($contextId: ID!, $name: String!) {
    context {
      update(contextId: $contextId, name: $name) {
        ...ContextFragment
      }
    }
  }
  ${ContextFragment}
`

export const DELETE_CONTEXT = gql`
  mutation DeleteContext($contextId: ID!) {
    context {
      delete(contextId: $contextId)
    }
  }
`

export const LINK_ENTITY_TO_CONTEXT = gql`
  mutation LinkEntityToContext($contextId: ID!, $entityId: ID!, $entityType: EntityType!) {
    context {
      linkEntity(contextId: $contextId, entityId: $entityId, entityType: $entityType) {
        ...BaseContextFragment
      }
    }
  }
  ${BaseContextFragment}
`

export const UNLINK_ENTITY_FROM_CONTEXT = gql`
  mutation UnlinkEntityFromContext($contextId: ID!, $entityId: ID!, $entityType: EntityType!) {
    context {
      unlinkEntity(contextId: $contextId, entityId: $entityId, entityType: $entityType) {
        ...BaseContextFragment
      }
    }
  }
  ${BaseContextFragment}
`

export const ADD_TAG_TO_CONTEXT = gql`
  mutation AddTagToContext($contextId: ID!, $tag: String!) {
    context {
      tag {
        add(contextId: $contextId, tag: $tag) {
          ...BaseContextFragment
        }
      }
    }
  }
  ${BaseContextFragment}
`

export const REMOVE_TAG_FROM_CONTEXT = gql`
  mutation RemoveTagFromContext($contextId: ID!, $tag: String!) {
    context {
      tag {
        remove(contextId: $contextId, tag: $tag) {
          ...BaseContextFragment
        }
      }
    }
  }
  ${BaseContextFragment}
`

export const EDIT_TAG_ON_CONTEXT = gql`
  mutation EditTagOnContext($contextId: ID!, $oldTag: String!, $newTag: String!) {
    context {
      tag {
        edit(contextId: $contextId, oldTag: $oldTag, newTag: $newTag) {
          ...BaseContextFragment
        }
      }
    }
  }
  ${BaseContextFragment}
`
