import { Note, Mutation, Query } from "@ascully24/alfred"
import { ADD_TAG_TO_NOTE, EDIT_TAG_ON_NOTE, REMOVE_TAG_FROM_NOTE } from "alfred/graphql/mutations"
import { GET_ALL_NOTES, GET_TAGS } from "alfred/graphql/queries"
import { useMutationWithTry, useQueryWithLoading } from "graphql/apollo-utils"
import { AutoCompleteChipDisplay } from "utils/chips/ChipDisplay"

export const NoteTags = ({ note, asColumn = true }: { note: Note; asColumn?: boolean }) => {
  const noteId = note.id
  const { data: availableTagsData } = useQueryWithLoading<Query>(GET_TAGS)

  const [editTag] = useMutationWithTry<Mutation>(EDIT_TAG_ON_NOTE)
  const [removeTag] = useMutationWithTry<Mutation>(REMOVE_TAG_FROM_NOTE)
  const [addTag] = useMutationWithTry<Mutation>(ADD_TAG_TO_NOTE)

  const addTagCallback = async (tag: string) => {
    const { data } = await addTag({
      variables: { noteId, tag },
      refetchQueries: [GET_ALL_NOTES, GET_TAGS],
    })

    return !!data?.note?.tag?.add?.id
  }

  return (
    <AutoCompleteChipDisplay
      asColumn={asColumn}
      getOptionLabel={(option) => option}
      values={note.metaData?.tags ?? []}
      editChip={async (oldTag, newTag) => {
        await editTag({
          variables: { noteId, oldTag, newTag },
          refetchQueries: [GET_ALL_NOTES, GET_TAGS],
        })
      }}
      removeChip={async (tag) => {
        await removeTag({
          variables: { noteId, tag },
          refetchQueries: [GET_ALL_NOTES, GET_TAGS],
        })
      }}
      addChip={addTagCallback}
      onUnknownOption={addTagCallback}
      autoCompleteValues={availableTagsData?.tag?.list ?? []}
      getChipText={(tag) => tag}
    />
  )
}
