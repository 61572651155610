import { useSubscription } from "@apollo/client"
import { AgentRunEntityType, Mutation, Query, Subscription } from "@ascully24/alfred"
import { Delete as DeleteIcon } from "@mui/icons-material"
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from "@mui/material"
import { AgentRunStatusChip } from "alfred/agents/AgentRunStatusChip"
import { DELETE_AGENT_RUN } from "alfred/graphql/mutations"
import { LIST_AGENT_RUNS } from "alfred/graphql/queries"
import { ALL_AGENT_RUN_SUBSCRIPTION } from "alfred/graphql/subscription"
import { useMutationWithToast, useQueryWithLoading } from "graphql/apollo-utils"
import { useCallback, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link } from "react-router-dom"
import { calculateTimeAgo } from "utils/time"
import { CancelAgentRun } from "./CancelAgentRun"

const filter = {
  limit: 10,
}

export const AgentRunsList = ({
  entityId,
  entityType,
}: {
  entityId: string
  entityType: AgentRunEntityType
}) => {
  const { loading, error, data, fetchMore, updateQuery } = useQueryWithLoading<Query>(LIST_AGENT_RUNS, {
    variables: { entityId, entityType, filter },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const runs = data?.agentRun?.list ?? []
      if (runs.length < filter.limit) {
        setHasMore(false)
      }
    },
  })
  const [deleteRun] = useMutationWithToast<Mutation>(DELETE_AGENT_RUN)

  useSubscription<Subscription>(ALL_AGENT_RUN_SUBSCRIPTION, {
    shouldResubscribe: true,
    variables: { agentId: entityId },
    onSubscriptionData: ({ subscriptionData }) => {
      if (!subscriptionData.data) return
      const updatedRunStatus = subscriptionData.data.allAgentRuns
      updateQuery((prev) => {
        const prevRuns = prev.agentRun?.list ?? []
        const updatedRuns = prevRuns.map((run) => {
          if (run.id === updatedRunStatus.id) {
            return updatedRunStatus
          }
          return run
        })

        return {
          ...prev,
          agentRun: {
            ...prev.agentRun,
            list: updatedRuns,
          },
        }
      })
    },
  })

  const agentRuns = data?.agentRun?.list ?? []
  const [hasMore, setHasMore] = useState(true)

  const fetchMoreData = useCallback(async () => {
    const response = await fetchMore({
      variables: {
        filter: { ...filter, offset: agentRuns.length },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          setHasMore(false)
          return prev
        }

        const moreAgentRuns = fetchMoreResult?.agentRun?.list ?? []
        if (moreAgentRuns.length < filter.limit) {
          setHasMore(false)
        }

        return {
          ...fetchMoreResult,
          agentRun: {
            ...fetchMoreResult.agentRun,
            list: [...prev.agentRun?.list, ...moreAgentRuns],
          },
        }
      },
    })

    const fetchedRuns = response.data.agentRun?.list

    // If fetched data length is less than the limit, no more data is available
    if (fetchedRuns.length < filter.limit) {
      setHasMore(false)
    }

    return null
  }, [fetchMore, agentRuns.length])

  if (loading) return <LoadingSpinner />
  if (error) return <p>Error: {error.message}</p>

  if (agentRuns.length === 0) {
    return <Alert severity="info">No runs found</Alert>
  }

  return (
    <InfiniteScroll
      dataLength={agentRuns.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<LoadingSpinner />}
    >
      <List>
        {agentRuns.map((agentRun) => (
          <ListItem key={agentRun.id}>
            <ListItemButton component={Link} to={`/agents/${entityId}/runs/${agentRun.id}`}>
              <Stack
                gap={2}
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={{ sm: "center" }}
              >
                <ListItemText
                  sx={{ flexShrink: 0 }}
                  primary={<AgentRunStatusChip status={agentRun.status} />}
                  secondary={calculateTimeAgo(agentRun.createdAt)}
                />
                <ListItemText primary={agentRun.summary} />
              </Stack>
            </ListItemButton>
            <ListItemSecondaryAction>
              {["In Progress"].includes(agentRun.status) && (
                <CancelAgentRun
                  asButton={false}
                  onCompleted={(data) => {
                    const updatedRun = data?.agentRun.cancel

                    if (!updatedRun) {
                      return
                    }

                    updateQuery((prev) => {
                      const newAgentRuns = prev.agentRun.list.map((run) => {
                        if (run.id === updatedRun.id) {
                          return updatedRun
                        }

                        return run
                      })
                      return { ...prev, agentRun: { ...prev.agentRun, list: newAgentRuns } }
                    })
                  }}
                  id={agentRun.id}
                />
              )}
              <IconButton
                onClick={() => {
                  deleteRun({
                    variables: { id: agentRun.id },
                    onCompleted: () => {
                      updateQuery((prev) => {
                        const newAgentRuns = prev.agentRun.list.filter((run) => run.id !== agentRun.id)
                        return {
                          ...prev,
                          agentRun: {
                            ...prev.agentRun,
                            list: newAgentRuns,
                          },
                        }
                      })
                    },
                  })
                }}
                color="error"
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </InfiniteScroll>
  )
}

const LoadingSpinner = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: 2,
    }}
  >
    <CircularProgress />
  </Box>
)
