import { ApolloClient, createHttpLink, from, InMemoryCache, split } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import { graphqlSubscriptionsUri, backendUri } from "environment"
import { createClient } from "graphql-ws"
import { fetchWithSetAuth, webSocketConnectionParams } from "user/Fetch"
import { getBearerToken, getSessionId } from "user/utils"

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${graphqlSubscriptionsUri}/graphql`,
    connectionParams: webSocketConnectionParams,
    keepAlive: 10000,
    connectionAckWaitTimeout: 3000,
    lazy: true,
    retryAttempts: 3,
  })
)

const link = createHttpLink({
  uri: `${backendUri}/graphql`,
  fetch: fetchWithSetAuth,
})

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const authLink = setContext((_, { headers }) => {
  const bearerToken = getBearerToken()
  const sessionId = getSessionId()
  return {
    headers: {
      ...headers,
      authorization: bearerToken,
      "x-session-id": sessionId,
    },
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === "OperationDefinition" && definition.operation === "subscription"
  },
  wsLink,
  link
)

// const typePolicies: TypePolicies = {
//   Query: {
//     fields: {
//       agents: {
//         merge(existing, incoming) {
//           return { ...existing, ...incoming }
//         },
//       },
//       note: {
//         merge(existing, incoming) {
//           // TODO: WIP - Gotta fix the issue with the duplicate notes. Test out the Context form for more deets
//           const existingList = existing.list ?? []
//           const incomingList = incoming.list ?? []

//           console.log({
//             existing,
//             incoming,
//           })

//           const list = mergeLists(existingList, incomingList)

//           return { ...existing, ...incoming, list }
//         },
//       },
//       context: {
//         merge(existing, incoming) {
//           const existingList = existing.list ?? []
//           const incomingList = incoming.list ?? []

//           const list = mergeLists(existingList, incomingList)

//           return { ...existing, ...incoming, list }
//         },
//       },
//     },
//   },
// }

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, splitLink]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          chat: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          agents: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          note: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          context: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
        },
      },
    },
  }),
})

// function mergeLists(existingList: any, incomingList: any) {
//   const merge1 = existingList.map((e: any) => {
//     const find = incomingList.find((i: any) => i.id === e.id)
//     if (find) {
//       return { ...e, ...find }
//     }

//     return e
//   })

//   const merge2 = incomingList.filter((i: any) => !existingList.find((e: any) => e.id === i.id))
//   const list = [...merge1, ...merge2]
//   return list
// }
